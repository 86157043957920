import form from "ezimamoto/Password/ChangePassword/Schema/form";

const {
  formField: {
    oldpassword, newpassword, confirmpassword,
  },
} = form;

const initialValues         = {
  [oldpassword.name]        : "",
  [newpassword.name]        : "",
  [confirmpassword.name]    : "",
};

export default initialValues;
