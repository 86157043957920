import { React, useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import  { Navigate } from 'react-router-dom'
import Grid from "@mui/material/Grid";
import MaterialTable from 'material-table';
import { TablePagination } from '@mui/material';
import { FormattedMessage } from "react-intl";
import { getAllInspectedApplicationsForReporting, downloadPdfbase64String } from "apis";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import { Logger }  from 'logging-library';
import FileViewer from 'react-file-viewer';
// import FilePreviewer from 'react-file-previewer';
import { CustomErrorComponent } from 'custom-error';
// import PreviewModal from "react-media-previewer";
import Iframe from 'react-iframe'
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function OrderList() {
  const [pdfResponse, setPdfResponse]                = useState({displayPdf: false, base64STR: ""});
  const [controller, dispatch]                       = useMaterialUIController();
  const [columns, setColumns]                        = useState([]);
  const [rows, setRows]                              = useState([]);
  const [ loading ]                                  = useState(false);
  const { lang }                                     = controller;
  const type                                         = 'pdf';
  const [visible, setVisible]                        =  useState(false);




  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  useEffect(() => {
    let postData                                      = {};
    let session                                       = sessionStorage.getItem("usn");
    let usrObj                                        = JSON.parse(session);
    postData.userId                                   = usrObj.userId;
    let token                                         = usrObj.sessionToken;
    getAllInspectedApplicationsForReporting(callBack, postData, token);
   // setShowChangeMenuAction(dispatch, false);
    setVisible(false);
  }, [lang]);

  const callBack1 = data => {
    console.log(data);
    if(!data.error && data.status === "Ok" && data.message === "File generated successiful"){
      setPdfResponse({displayPdf: true, base64STR: data.fileContent});
      setVisible(true);
    } else {
      setPdfResponse({displayPdf: false, base64STR: ""});
      setVisible(false);
    }
  }

  const downloadPdfbase64StringFunction = (event, rowData) => {
    console.log(event);
    let session                                       = sessionStorage.getItem("usn");
    let usrObj                                        = JSON.parse(session);
    let postObject                                    = {};
    postObject.applicationId                          = rowData.applicationId;
    let token                                         = usrObj.sessionToken;
    downloadPdfbase64String(callBack1, postObject, token)
  }

  const onError  = e => {
    Logger.logError(e, 'error in file-viewer');
  }

  const callBack = data => {
    if(!data.error && data.status === "OK" && data.message === "Succesfully Retrieved"){
      if((data.result[0] !== null) && (data.result[0] !== undefined)){
        let columnsDef = null;
        if(lang === "sw"){
            columnsDef = [
                { title: "UT UK", field: "applicationId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform:'none', fontSize: 10 }},
                { title: "UT MFM", field: "applicantId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "Namba Malipo", field: "controlNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "JIN / BSHR", field: "businessName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "OMBI Na.", field: "applicationNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10 }},
                { title: "SIM/BSHR", field: "businessPhoneNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "KIPNG/MILIKI", field: "propertyCategory", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "KIAS/TOZWA", field: "billedAmount", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
            ];
        } else {
            columnsDef = [
                { title: "INS ID", field: "applicationId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10 }},
                { title: "APP ID", field: "applicantId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "CTRL No.", field: "controlNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "BUSS NAME", field: "businessName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "APPL No.", field: "applicationNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "BUSS PHONE", field: "businessPhoneNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "PROP CATEGORY", field: "propertyCategory", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                { title: "BILLED AMNT", field: "billedAmount", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
            ];
        }
        setColumns(columnsDef);
        setRows(data.result);
    }
  }
}

const downloadPdf = event => { 
  
  window.open("data:application/pdf;base64," + pdfResponse.base64STR); 
}

  const getUserSession = () => {
      let session = sessionStorage.getItem("usn");
      if((session === null) || (session === "")){
          return <Navigate to='/'  />
      } else {
          let user = JSON.parse(session);
          if((typeof user === 'object') && (user !== null)){
            return (
                <>
                  <DashboardNavbar/>

                    <MDBox py={3} mb={2}>
                      <Grid container justifyContent="center" alignItems="center">
                          
                          {!pdfResponse.displayPdf && (
                            <Grid item xs={12} lg={12}>
                              <MaterialTable
                                  title={<FormattedMessage id="inspected_rpt" />}
                                  columns={columns}
                                  data={rows}
                                  isLoading={loading}
                                  localization={{ 
                                      header: {
                                          actions: <FormattedMessage id="tbl_act" />,
                                      }
                                  }}
                                  style={{ 
                                      textTransform: 'none'
                                  }}
                                  options={{ 
                                      actionsColumnIndex: -1,
                                      doubleHorizontalScroll: true,
                                      loadingType: 'overlay',
                                      headerStyle: {
                                          backgroundColor: 'orange',
                                          whiteSpace: "nowrap",
                                          color: 'white',
                                      },
                                      padding: 'dense',
                                      paginationType: 'stepped',
                                      searchFieldVariant: 'outlined',
                                      rowStyle: (data, index, level) => {
                                          if((index % 2) === 0){
                                              return { backgroundColor: '#EEE',fontSize: 12};
                                          } else {
                                              return { backgroundColor: '#FFF', fontSize: 12};
                                          }
                                      }
                                  }}
                                  actions={[
                                    {
                                      icon: 'save',
                                      tooltip: 'Save User',
                                      onClick: (event, rowData) => downloadPdfbase64StringFunction(event, rowData)
                                    },
                                  ]}
                                  components={{
                                    Action: props => (
                                      <>
                                        <MDButton 
                                          variant="gradient" 
                                          type="button" 
                                          color="info" 
                                          size="small"
                                          onClick={event => props.action.onClick(event, props.data)}
                                        >
                                          <PrintIcon />
                                        </MDButton>
                                      </>
                                      ),
                                      Pagination: (props) => {
                                        const {
                                          ActionsComponent,
                                          onChangePage,
                                          onChangeRowsPerPage,
                                          ...tablePaginationProps
                                        } = props;
                                        return (
                                          <>
                                            <TablePagination
                                              {...tablePaginationProps}
                                              onPageChange={onChangePage}
                                              onRowsPerPageChange={onChangeRowsPerPage}
                                              ActionsComponent={(subprops) => {
                                                const { onPageChange, ...actionsComponentProps } =
                                                  subprops;
                                                return (
                                                  <ActionsComponent
                                                    {...actionsComponentProps}
                                                    onChangePage={onPageChange}
                                                  />
                                                );
                                              }}
                                            />
                                          </>
                                        );
                                      },
                                    }}
                              />
                            </Grid>
                          )}

                          {pdfResponse.displayPdf && (
                            <>
                              <Grid item xs={12} lg={12}> 



                              {/* <Dialog
                                open={open}
                                onClose={handleClose}
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                              >
                              <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
                              <DialogContent dividers={scroll === 'paper'}>
                                <DialogContentText
                                  id="scroll-dialog-description"
                                  ref={descriptionElementRef}
                                  tabIndex={-1}
                                > */}
                                <Iframe
                                  src={`data:application/pdf;base64,${pdfResponse.base64STR}`}
                                  width="100%"
                                  height="780px"
                                  id=""
                                  className=""
                                  display="block"
                                  position="relative"
                                />
                                {/* </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleClose}>Subscribe</Button>
                              </DialogActions>
                            </Dialog> */}
                                {/* <FileViewer
                                  fileType={type}
                                  filePath={`data:application/pdf;base64,${pdfResponse.base64STR}`}
                                  errorComponent={CustomErrorComponent}
                                  onError={onError}
                                /> */}
                              </Grid> 
                            </>
                          )}
                      </Grid>
                    </MDBox>
                </>
              );
          } else {
              return <Navigate to='/'  />
          }
      }
  }
  return (
    <DashboardLayout>
      {getUserSession()}
    </DashboardLayout>
    
  );
}

export default OrderList;
