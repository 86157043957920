import * as Yup from "yup";
import form from "ezimamoto/customer-login/Schema/form";

const { formField: { email, password, }, } = form;

const validations = [
  Yup.object().shape({
    [email.name]                          : Yup.string().email(email.invalidMsg).required(email.errorMsg),
    [password.name]                       : Yup.string().required(password.errorMsg),
  }),
  Yup.object().shape({
    [email.name]                          : Yup.string().email(email.invalidMsgSw).required(email.errorMsgSw),
    [password.name]                       : Yup.string().required(password.errorMsgSw),
  })
];
  
  export default validations;