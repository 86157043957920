export const GET_REGIONS          = "api/Locations/region";
export const GET_REGION_DISTRICTS = "api/Locations/district";
export const GET_ADMIN_AREAS      = "api/Locations/administrativearea";
export const GET_WARD_INFOS       = "api/Locations/ward";
export const GET_STREET_VILLAGE   = "api/Locations/streetvillage";
//2. PROPERT CATEGORY
export const PROPERTY_CATEGORY    = "api/Locations/properycategory";
export const SUB_CATEGORY_API     = "api/Locations/propertysubcategory";
//REGISTRATION
export const REGISTRATION_API     = "api/Account/registrationreq";
// FORGOT PASSWORD
export const FORGOT_PASSWD_API    = "api/Account/forgotpassword";
export const RESET_PASSWD_RQST    = "api/Account/resetpasswordrequest";
// APPLICATIONS
export const EXISTING_APPLIC      = "api/InspectionApplication/getappliedinspections";
export const EXIST_APPL_GET       = "api/InspectionApplication/getinspectionapplicationdetails";
export const APPL_STATUS_GET      = "api/InspectionApplication/getinspectionapplicationstatus";
export const APPL_CREATION_API    = "api/InspectionApplication/createinspectionapplication";
export const SUB_CAT_DETAIL_API   = "api/Locations/propertysubcategorydetail";
//LOGINPAGE
export const LOGIN_PAGE_SIGNIN    = "api/Account/login";
export const CONFRM_APPLCATION    = "api/InspectionApplication/confirminspectionapplication";
export const GET_RENEW_APPLIC     = "api/InspectionApplication/getexpiredinspection";
//NIDA REGISTRATION
export const REGIS_API_ENDPNT     = "fsirms/api/v1/getrqverification";
//NIDA QUESTION AND ANSWER
export const QUESTION_ANSWER_URL  = "fsirms/api/v1/getrqverification";
export const TRA_TIN_VERIFICATION = "api/InspectionApplication/customerverificationreq";
// REPORT API
export const REPORT_API_BY_USR_ID = "api/InspectionApplication/getinspectedapplications";
//RETRIEVE PDF
export const PDF_BASE64_STRING    = "api/pdfcreator/api/structurereport?ApplicatioId=";

export const RNW_APPLICATION_API  = "api/InspectionApplication/renewinspectionapplication";

export const DASHBOARD_APPLICANT  = "api/Account/getdashboard";

export const RESET_PASSWD_URL     = "api/Account/passwordresetpost";