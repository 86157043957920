import { Form, Formik } from "formik";
import form from "ezimamoto/customer-login/Schema/form";
import initialValues from "ezimamoto/customer-login/Schema/initialValues";
import validation from "ezimamoto/customer-login/Schema/validation";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/Flag_of_Tanzania.png";
import Image from "assets/images/nembo.png";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoginForm from "ezimamoto/customer-login/loginForm";
import { useNavigate } from "react-router-dom";
import { loginPasswordRequest } from "apis";
import { useMaterialUIController, changeUserLanguage } from "context";
import { useState, } from "react";
import ReactFlagsSelect from "react-flags-select";

  const Login = () => {
    const { formId, formField }             = form;
    const [controller, dispatch]            = useMaterialUIController();
    const { lang }                          = controller;
    const navigate                          = useNavigate();
    const [successSB, setSuccessSB]         = useState(false);
    const [content, setContent]             = useState("");
    const [header, setHeader]               = useState("");
    const [errorSB, setErrorSB]             = useState(false);
    const OPTION_LABEL                      = (lang === "en") ? "Language" : "Lugha";
    const STATUS_MSG                        = "error";
    const CONCAT_STR                        = ", ";


    const callBack                          = data => {
      console.log(data);
      setContent(data.message);
      setHeader(data.status);
      if(data.error){
          setErrorSB(true);
      } else {
          setSuccessSB(true);
      }
      if((data.result !== null) && (!data.error)){
        sessionStorage.setItem("usn", JSON.stringify(data.result));
        navigate("/ezimamoto/dash-board-page");
      }
    }

    const alertContentSmall = (status, header, message) => (
      <MDTypography variant="body2" fontWeight="medium" color={status}>
        {header}{CONCAT_STR}{message}
      </MDTypography>
    );

    const languageSelected = languageCode => {
      console.log(languageCode);
      if(languageCode === "GB"){
        changeUserLanguage(dispatch, "en");
      } else {
        changeUserLanguage(dispatch, "sw");
      }
    }

    const  handleSubmit                     = values => {
      values["rememberMe"] = true;
      console.log(values);
      loginPasswordRequest(callBack, values);
    }

    const validationLogic = () => {
      if(lang === "en"){
        return validation[0];
      } else {
        return validation[1];
      }
    }
   
    return (
        <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-1}
            p={2}
            mb={2}
            textAlign="center"
          > 
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
              Zimamoto Portal
              <MDBox mx={-2} mt={-10} p={-2} mb={1} position="absolute" width="90%" minHeight="310px" sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } 
              }) =>
              Image && `${linearGradient(rgba(gradients.dark.main, -0.1), rgba(gradients.dark.state, -0.1) )}, url(${Image})`,
              backgroundSize: "150px", backgroundPosition: "center", backgroundRepeat: "no-repeat", }}/> 
            </MDTypography>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 8, mb: 9 }}>
              <Grid item xs={6} />
              <Grid item xs={6} />
            </Grid>
          </MDBox>
         
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={2}  sm={2} md={2} />
            <Grid item xs={8} sm={8} md={8}>
              {(successSB || errorSB) && alertContentSmall(STATUS_MSG, header, content)}
            </Grid>
            <Grid item xs={2}  sm={2} md={2} />
          </Grid>

          <Grid container spacing={2} justifyContent="center" sx={{ mt: 0, mb: 0 }}>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <ReactFlagsSelect
                  selected={(lang === "en") ? "GB" : "TZ"}
                  onSelect={languageCode => languageSelected(languageCode)}
                  searchPlaceholder={OPTION_LABEL}
                  countries={["GB", "TZ"]}
                  customLabels={{ GB: "ENGLISH", TZ: "SWAHILI" }}
                  placeholder={OPTION_LABEL}
                  showSelectedLabel={true}
                  showOptionLabel={true}
                />
              </Grid>
              <Grid item xs={3} />
          </Grid>

        <Formik
            initialValues={initialValues}
            validationSchema={validationLogic()}
            onSubmit={handleSubmit}
        >
        {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
                <LoginForm formData={{values, errors, touched, isSubmitting, formField}} />
            </Form>)}
      </Formik>
      </Card>
      {/* {renderSuccessSB}
      {renderErrorSB} */}
    </BasicLayout>);
}

export default Login;