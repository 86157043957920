const form = {
  formId: "new-appl-form",
  formField: {
    customerName: {
      name: "customerName",
      label: "Customer Name",
      type: "text",
      errorMsg: "Customer name is required.",
      errorMsgSw: "Jina la Mteja Linahitajika.",
    },
    postalAddress: {
      name: "postalAddress",
      label: "postalAddress",
      type: "text",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "PhoneNumber",
      type: "text",
      errorMsg: "PhoneNumber is required.",
      invalidMsg: "Your phoneNumber is invalid",
    },
    bussinessName: {
      name: "bussinessName",
      label: "Bussiness Name/Premise Name",
      type: "text",
      errorMsg: "Bussiness Name is required.",
      errorMsgSw: "Jina la Biashara Linahitajika.",
    },
    bussinessPhone: {
      name: "bussinessPhone",
      label: "Bussiness Phone/Premise Phone#",
      type: "text",
      errorMsg: "Bussiness Phone Number is required.",
    },
    region: {
      name: "region",
      label: "Region",
      type: "text",
      errorMsg: "Region is required.",
      errorMsgSw: "Taarifa za Mkoa Zinahitajika.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "District is required.",
      errorMsgSw: "Taarifa za Wilaya zinahitajika.",
    },
    admin_area: {
      name: "admin_area",
      label: "Administration area",
      type: "text",
      errorMsg: "Administrative Area is required.",
      errorMsgSw: "Taarifa za Eneo La utawala zinahitajika.",
    },
    ward: {
      name: "ward",
      label: "Ward",
      type: "text",
      errorMsg: "Ward is required.",
      errorMsgSw: "Taarifa za Kata zinahitajika.",
    },
    street_village: {
      name: "street_village",
      label: "Street/Village",
      type: "text",
      errorMsg: "Street Village is required.",
      errorMsgSw: "Taarifa za Mtaa au Kijiji Zinahitajika.",
    },
    block: {
      name: "block",
      label: "Block No",
      type: "text",
      placeholder: "Block No",
    },
    plot: {
      name: "plot",
      label: "Plot No",
      type: "text",
      placeholder: "Plot No",
    },
    houseNumber: {
      name: "houseNumber",
      label: "House Number",
      type: "text",
      placeholder: "House No",
    },
    propertyCategory: {
      name: "propertyCategory",
      label: "Property Category",
      type: "text",
      errorMsg: "Property Category is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },
    subCategory: {
      name: "subCategory",
      label: "Sub Category",
      type: "text",
      errorMsg: "Sub Category is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },
    numOfItems: {
      name: "numOfItems",
      label: "Number Of Items",
      type: "text",
      errorMsg: "Number Of Items Required.",
      errorMsgSw: "Taarifa Inahitajika.",
      placeholder: "Number Of Items",
      positivenum: "Require Positive Number",
      positivenumSw: "Tunahitaji Nambari Chanya",
    },
    subCategoryDetail: {
      name: "subCategoryDetail",
      label: "Sub Category Detail",
      type: "text",
      placeholder: "Sub Category Detail",
      errorMsg: "Sub Category Detail is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },
    customType: {
      name: "customType",
      label: "Customer Type",
      type: "text",
      errorMsg: "Customer Type Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },
    identityNumber: {
      name: "identityNumber",
      label: "Identity Number",
      type: "text",
      errorMsg: "Identity Number is required.",
      errorMsgSw: "Namba Utambulisho Inahitajika.",
    },
    identityType1: {
      name: "identityType1",
      label: "Identity Type",
      type: "text",
      errorMsg: "Identity Type is required.",
      errorMsgSw: "Aina ya Utambulisho Inahitajika.",
    },
    customerEmail: {
      name: "customerEmail",
      label: "Customer Email",
      type: "text",
      invalidMsg: "Invalid Customer Email",
      errorMsg: "Customer Email is required.",
      errorMsgSw: "Barua pepe ya Mteja Inahitajika.",
      invalidMsgSw: "Barua pepe ya Mteja sio sahihi",
    },
    bussnEmail: {
      name: "bussnEmail",
      label: "Bussiness Email",
      type: "text",
      invalidMsg: "Invalid Bussiness Email",
      errorMsg: "Bussiness Email is required.",
      errorMsgSw: "Barua pepe ya Biashara Inahitajika.",
      invalidMsgSw: "Barua pepe ya Biashara sio sahihi",
    },
    bussnAddr: {
      name: "bussnAddr",
      label: "Bussiness Postal Address",
      type: "text",
    },
    feedbackCorrespondent: {
      name: "feedbackCorrespondent",
      label: "Taarifa Zitumwe Kwa",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Hii Taarifa Inahitajika.",
    },
    applicantCapacity: {
      name: "applicantCapacity",
      label: "Applicant Capacity",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Hii Taarifa Inahitajika.",
    },
  },
};

export default form;
