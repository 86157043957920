import React from 'react';
import { TextField, MenuItem } from "@mui/material";
import {  useField, useFormikContext  } from 'formik';

const MDSelect = ({
    name,
    options,
    ...otherProps
}) => {
    const { setFieldValue }                      = useFormikContext();
    const [field, meta]                          = useField(name);

    const handleOnChange = evt => {
        const { value } = evt.target;
        setFieldValue(name, value);
    }

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'standard',
        fullWidth: true,
        onChange: handleOnChange
    };

    if(meta && meta.touched && meta.error){
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <TextField {...configSelect}>
            {(name === "region")   && (<MenuItem key={"region0"}   value="">{"-- Select Region --"}</MenuItem>)}
            {(name === "district") && (<MenuItem key={"district0"} value="">{"-- Select District --"}</MenuItem>)}
            {(name === "admin_area") && (<MenuItem key={"admin_area0"} value="">{"-- Select Administration Area --"}</MenuItem>)}
            {(name === "ward")   && (<MenuItem key={"ward0"}   value="">{"-- Select Ward --"}</MenuItem>)}
            {(name === "street_village")   && (<MenuItem key={"street_village0"}   value="">{"-- Select Street/Village --"}</MenuItem>)}
            {(name === "propertyCategory")   && (<MenuItem key={"propertyCategory0"}   value="">{"-- Select Property Category --"}</MenuItem>)}
            {(name === "propertyCategory1")   && (<MenuItem key={"propertyCategory10"}   value="">{"-- Select Property Category --"}</MenuItem>)}
            {(name === "subCategory")   && (<MenuItem key={"subCategory0"}   value="">{"-- Select Sub Category Property --"}</MenuItem>)}
            {(name === "subCategory1")   && (<MenuItem key={"subCategory10"}   value="">{"-- Select Sub Category Property --"}</MenuItem>)}
            {(name === "title")   && (<MenuItem key={"title0"}   value="">{"-- Select Title --"}</MenuItem>)}
            {(name === "applicationtype")   && (<MenuItem key={"appli0"}   value="">{"-- Select Applicant Type --"}</MenuItem>)}
            {(name === "identitytype")   && (<MenuItem key={"identitytype0"}   value="">{"-- Select Identity Type --"}</MenuItem>)}
            {(name === "subCategoryDetail")   && (<MenuItem key={"subCategoryDetail0"}   value="">{"-- Select Sub Category Detail --"}</MenuItem>)}
            {(name === "customType")   && (<MenuItem key={"customerType0"}   value="">{"-- Select Category --"}</MenuItem>)}
            {(name === "identityType1")   && (<MenuItem key={"identityTypee0"}   value="">{"-- Select Identity Type --"}</MenuItem>)}
            {(name === "feedbackCorrespondent")   && (<MenuItem key={"feedbackCorrespondent0"}   value="">{"-- Select --"}</MenuItem>)}
            {(name === "applicantCapacity")   && (<MenuItem key={"applicantCapacity0"}   value="">{"-- Select --"}</MenuItem>)}
            {(name === "region")   && options.map(option => <MenuItem divider={true} key={option.regionId} value={option.regionId}>{option.regionName}</MenuItem>)}
            {(name === "district") && options.map(option => <MenuItem divider={true} key={option.districtId} value={option.districtId}>{option.districtName}</MenuItem>)}
            {(name === "admin_area") && options.map(option => <MenuItem divider={true} key={option.administrativeAreaId} value={option.administrativeAreaId}>{option.administrativeAreaName}</MenuItem>)}
            {(name === "ward") && options.map(option => <MenuItem divider={true} key={option.wardId} value={option.wardId}>{option.wardName}</MenuItem>)}
            {(name === "street_village") && options.map(option => <MenuItem divider={true} key={option.streetVillageId} value={option.streetVillageId}>{option.streetVillageName}</MenuItem>)}
            {(name === "propertyCategory") && options.map(option => <MenuItem divider={true} key={option.propertyCategoryId} value={option.propertyCategoryId+":"+option.hasSubCategory+":"+option.allowMultiply}>{option.category}</MenuItem>)}
            {(name === "subCategory") && options.map(option => <MenuItem divider={true} key={option.subCategoryId} value={option.subCategoryId+":"+option.hasSubCategoryDetail}>{option.subCategoryTitle}</MenuItem>)}
            {(name === "title") && options.map(option => <MenuItem divider={true} key={option.key} value={option.key}>{option.value}</MenuItem>)}
            {(name === "applicationtype") && options.map(option => <MenuItem divider={true} key={option.key} value={option.key}>{option.value}</MenuItem>)}
            {(name === "identitytype") && options.map(option => <MenuItem divider={true} key={option.key} value={option.key}>{option.value}</MenuItem>)}
            {(name === "subCategoryDetail") && options.map(option => <MenuItem divider={true} key={option.subCategoryDetailId} value={option.subCategoryDetailId}>{option.subCategoryDetailTitle}</MenuItem>)}
            {(name === "customType") && options.map(option => <MenuItem divider={true} key={option.value} value={option.value}>{option.label}</MenuItem>)}
            {(name === "identityType1") && options.map(option => <MenuItem divider={true} key={option.value} value={option.value}>{option.label}</MenuItem>)}
            {(name === "feedbackCorrespondent") && options.map(option => <MenuItem divider={true} key={option.value} value={option.value}>{option.label}</MenuItem>)}
            {(name === "applicantCapacity") && options.map(option => <MenuItem divider={true} key={option.value} value={option.value}>{option.label}</MenuItem>)}
            {(name === "propertyCategory1") && options.map(option => <MenuItem divider={true} key={option.propertyCategoryId} value={option.propertyCategoryId}>{option.category}</MenuItem>)}
            {(name === "subCategory1") && options.map(option => <MenuItem divider={true} key={option.subCategoryId} value={option.subCategoryId}>{option.subCategoryTitle}</MenuItem>)}
        </TextField>
    );

}

export default MDSelect;