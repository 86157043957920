import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "ezimamoto/application/new-application/form/components/FormField";
import { useMaterialUIController } from "context";
import { useIntl } from 'react-intl';
import { FormattedMessage } from "react-intl";


function QuestionPage({ formData }) {
  const { formField, values, errors, touched }                                      = formData;
  const { qAnswer }                                                                 = formField;
  const [ controller ]                                                              = useMaterialUIController();
  const { registrationObj }                                                         = controller;
  const { qAnswer: qAnswerV, }                                                      = values;

  const intl                                                                        = useIntl();
  const answer_id                                                                   = intl.formatMessage({id: 'answer_id'});

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h4"  color="dark">
          {<FormattedMessage id="qna_form_pg" />}
        </MDTypography>
        <MDTypography variant="h6" color="dark" >
          {"Question: "}{ registrationObj.questionResult.en }
        </MDTypography>
        <MDTypography variant="h6" color="dark" >
          {"Swali: "}{ registrationObj.questionResult.sw }
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={qAnswer.type}
              label={answer_id}
              name={qAnswer.name}
              value={qAnswerV}
              placeholder={answer_id}
              error={errors.qAnswer && touched.qAnswer}
              success={qAnswerV.length > 0 && !errors.qAnswer}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

QuestionPage.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default QuestionPage;
