import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import MDButton from "components/MDButton";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

function ReportsBarChart({ color, title, description, date, chart }) {
 // const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const navigate                                                                = useNavigate();
const instructions                                                              = () => {
navigate("/instructions");
}
  return (
    <Card sx={{ height: "100%" }} >
      
      <MDBox padding="1rem">
        
        {useMemo(
          () => (
            <MDBox  
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              mb={-6}
              height="14.9rem"
            >
            
            </MDBox>
          ),
          [chart, color]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
            
          </MDTypography>
          <MDTypography component="div" variant="button" color="white" fontWeight="light" mt={-22}>
            
            <Grid container spacing={4} justifyContent="center" sx={{ mt: 3, mb: 2}}>
              
             {description= <FormattedMessage id="Read" />}
            </Grid>
            <br />
            
            <Grid container spacing={4} justifyContent="center" sx={{ mt: 1, mb: 5 }}>
            <MDButton  variant="gradient" color="warning"  onClick={instructions} >
            
                  {<FormattedMessage id="instruct" />}
                  </MDButton>
                  </Grid><br />
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
