const form = {
    formId: "change-password-form",
     formField: {
      newpassword  : {
        name         : "newpassword",
        label        : "New-password",
        type         : "password",
        errorMsg     : "New Password is required.",
        errorMsgSw   : "Nywila Mpya Inahitajika.",
        invalidMsg   : "New Password Must Match With Confirmation Password",
        invalidMsgSw : "Nywila Mpya lazima Ifanane na nywila ya Uthibitisho",
        weakMsg       : "Weak Password",     
        weakMsgSw     : "Nywila Hafifu", 
      },
      confirmpassword  : {
        name          : "confirmpassword",
        label         : "Confirm Password",
        type          : "password",
        errorMsg      : "Confirm-Password is required.",
        errorMsgSw    : "Nywila Uthibitisho Inahitajika.",
        weakMsg       : "Weak Password",     
        weakMsgSw     : "Nywila Hafifu", 
      },
      userId  : {
        label        : "",
        name         : "userId",
        type         : "hidden",
      },
      code  : {
        label        : "",
        name         : "code",
        type         : "hidden",
      },
    },
  };
  
  export default form;
  