import * as Yup from "yup";
import form from "ezimamoto/Password/ResetPassword/Schema/form";

const { formField: { email, }, } = form;

const validations = [
  Yup.object().shape({
    [email.name] : Yup.string().email(email.invalidMsg).required(email.errorMsg),
  }),
  Yup.object().shape({
    [email.name] : Yup.string().email(email.invalidMsgSw).required(email.errorMsgSw),
  })
];
  
export default validations;