import Card from "@mui/material/Card";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ApplicationLayout from "ezimamoto/common/app-layout";
import ChangePasswordformular from "ezimamoto/Password/ChangePassword/change-password-form";
import { Form, Formik } from "formik";
import form from "ezimamoto/Password/ChangePassword/Schema/form";
import initialValues from "ezimamoto/Password/ChangePassword/Schema/initialvalues";
import { useMaterialUIController, setDisplayLoaderRequest, setShowChangeMenuAction } from "context";
import { resetUserPasswordRequest } from "apis";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import  { Navigate } from 'react-router-dom'
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import formContxt from "ezimamoto/Password/ChangePassword/Schema/form";


const ChangePasswordComponent = () =>    {
  const { formId, formField }              = form;
  const [controller, dispatch]             = useMaterialUIController();
  const { dispayLoader, lang }             = controller;
  const [successSB, setSuccessSB]          = useState(false);
  const [content, setContent]              = useState("");
  const [header, setHeader]                = useState("");
  const [errorSB, setErrorSB]              = useState(false);
  const strong_pswd                        = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)+(?=.*[-+_!@#$%^&*., ?]).+$";

  useEffect(() => {
    setShowChangeMenuAction(dispatch, true);
  }, []);
  
  const { formField: { oldpassword, 
    newpassword, confirmpassword, }, }     = formContxt;
  const  handleSubmit                      = async values => {
    let session = sessionStorage.getItem("usn");
    let user = JSON.parse(session);
    let token = user.sessionToken;
    const postData                       = {
      "email": user.email,
      "oldPassword": values.oldpassword,
      "password": values.newpassword,
      "confirmPassword": values.confirmpassword
    };
    console.log(postData)
    resetUserPasswordRequest(callBack, postData, token);
    setDisplayLoaderRequest(dispatch,  !dispayLoader);
    setDisplayLoaderRequest(dispatch, false);
  }


  const validations = Yup.object().shape({
    [oldpassword.name] : Yup.string().required((lang === "en") ? oldpassword.errorMsg : oldpassword.errorMsgSw),
    [newpassword.name] : Yup.string().required((lang === "en") ? newpassword.errorMsg : newpassword.errorMsgSw).matches(strong_pswd, ((lang === "en") ? newpassword.weakMsg : newpassword.weakMsgSw)),
    [confirmpassword.name] : Yup.string().required((lang === "en") ? confirmpassword.errorMsg : confirmpassword.errorMsgSw).matches(strong_pswd,((lang === "en") ? confirmpassword.weakMsg : confirmpassword.weakMsgSw)).oneOf([Yup.ref("newpassword"), null], (lang === "en") ? newpassword.invalidMsg : newpassword.invalidMsgSw),
  });
    

  const callBack = data => {
    setContent(data.message);
    setHeader(data.status);
    if(data.error){
        setSuccessSB(false);
        setErrorSB(true);
    } else {
        setSuccessSB(true);
        setErrorSB(false);
    }
  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={header}
      content={content}
      dateTime={new Date().toDateString()}
      open={successSB}
      onClose={() => setSuccessSB(false)}
      close={() => setSuccessSB(false)}
      bgWhite
    />
);

const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={header}
      content={content}
      dateTime={new Date().toDateString()}
      open={errorSB}
      onClose={() => setErrorSB(false)}
      close={() => setErrorSB(false)}
      bgWhite
    />
);

  const getUserSession = () => {
    let session = sessionStorage.getItem("usn");
    if((session === null) || (session === "")){
        return <Navigate to='/'  />
    } else {
        let user = JSON.parse(session);
        if((typeof user === 'object') && (user !== null)){
          return (        
            <>
              <DashboardNavbar/>
              <MDBox pb={7} />
              <Card>
                <MDBox
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-2}
                  p={3}
                  mb={1}
                  py={1}
                  textAlign="center"
                >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {<FormattedMessage id="change_password" />}
                </MDTypography>
                </MDBox>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form id={formId}>
                        <ChangePasswordformular formData={{ formField, values, errors, touched, isSubmitting }} />
                    </Form>
                  )}
                </Formik>
              </Card>
              {renderSuccessSB}
              {renderErrorSB}
            </>);
        } else {
            return <Navigate to='/'  />
        }
    }
  }

  return (<ApplicationLayout>{getUserSession()}</ApplicationLayout>);
}

export default ChangePasswordComponent;
