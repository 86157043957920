import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";


import UserInfo from "ezimamoto/application/renew-application/renew/form/components/UserInfo";
import Location from "ezimamoto/application/renew-application/renew/form/components/Location";
import PropertyCategory from "ezimamoto/application/renew-application/renew/form/components/PropertyCategory";
import Status from "ezimamoto/application/renew-application/renew/form/components/Status";
import Verification from "ezimamoto/application/renew-application/renew/form/components/Verification";
import validations from "ezimamoto/application/renew-application/renew/form/schemas/validations";
import form from "ezimamoto/application/renew-application/renew/form/schemas/form";
import initialValues from "ezimamoto/application/renew-application/renew/form/schemas/initialValues";
import { renewApplicationVersion1, confirmApplicationRequest } from "apis";
import { useMaterialUIController, submitApplicationRequest, storeObjectRequest, reviewApplication, movingRegistrationStepper, setShowRenewalPage } from "context";
import MDSnackbar from "components/MDSnackbar";
import { FormattedMessage } from "react-intl";
//import "Css/index.css";


const getSteps = (isSwahili) => isSwahili ? ["Hakiki Anachoomba Mteja ", "Taarifa Za Biashara Za Mteja ", "Eneo/Jengo La Biashara Inapopatikana", "Aina ya Jengo/Eneo Biashara ", "Hali ya Ombi La Biashara Lilipofikia"] : ["Customer Business Verification", "User Business Information Importing", "Business Location Where Located", "Property Category For Business", "Status For the Business of the User"];


const getStepContent = (stepIndex, formData) => {
    switch (stepIndex) {
        case 0:
            return <Verification formData = { formData } />;
        case 1:
            return <UserInfo formData = { formData } />;
        case 2:
            return <Location formData = { formData } />;
        case 3:
            return <PropertyCategory formData = { formData } />;
        case 4:
            return <Status /> ;
        default:
            return null;
    }
}

const NewApplicationForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    // const [indexStep, setIndexStep] = useState(0);
    const [controller, dispatch] = useMaterialUIController();
    const { appliLoading, newappObj, review, lang, renewobject, renewregistration, allowMultiply, hasSubCategoryDetail } = controller;
    const { formId, formField } = form;
    const steps = getSteps((lang === "sw"));
    const isLastStep = (activeStep === (steps.length - 1));
    const [successSB, setSuccessSB] = useState(false);
    const [content, setContent] = useState("");
    const [header, setHeader] = useState("");
    const [errorSB, setErrorSB] = useState(false);

    const properBlockPlotName = values => {
        if (((values.block !== "") && (values.block !== null)) && ((values.plot !== "") && (values.plot !== null))) {
            return (values.block + "/" + values.plot);
        } else {
            return "";
        }
    }

    const callBack = data => {
        submitApplicationRequest(dispatch, false);
        storeObjectRequest(dispatch, data);
    }

    const handleBack = () => {
        if (isLastStep) {
            setActiveStep(0);
            reviewApplication(dispatch, !review);
        } else {
            setActiveStep(activeStep - 1);
        }
    }

    const callBack1 = data => {
        setContent(data.message);
        setHeader(data.result.applicationNumber);
        if (data.error) {
            setErrorSB(true);
        } else {
            storeObjectRequest(dispatch, {});
            reviewApplication(dispatch, false);
            setSuccessSB(true);
            setActiveStep(0);
        }
    }

    // useEffect(() => {
    //     if (((propertyCat !== null) || (propertyCat !== ""))) {
    //         const selValue = propertyCat.split(":");
    //         if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'false') && !subcatdetail) {
    //             setIndexStep(1);
    //         } else if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'false') && subcatdetail) {
    //             setIndexStep(4);
    //         } else if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'true') && !subcatdetail) {
    //             setIndexStep(2);
    //         } else if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'true') && subcatdetail) {
    //             setIndexStep(5);
    //         } else if ((selValue.length === 3) && (selValue[1] === 'false') && (selValue[2] === 'true')) {
    //             setIndexStep(3);
    //         }
    //     }
    // }, [propertyCat, subcatdetail]);

    const renderSuccessSB = ( <
        MDSnackbar color = "success"
        icon = "check"
        title = { header }
        content = { content }
        dateTime = { new Date().toDateString() }
        open = { successSB }
        onClose = {
            () => setSuccessSB(false) }
        close = {
            () => setSuccessSB(false) }
        bgWhite /
        >
    );

    const renderErrorSB = ( <
        MDSnackbar color = "error"
        icon = "warning"
        title = { header }
        content = { content }
        dateTime = { new Date().toDateString() }
        open = { errorSB }
        onClose = {
            () => setErrorSB(false) }
        close = {
            () => setErrorSB(false) }
        bgWhite /
        >
    );

    const activateValidationSchema = index => {
        if((activeStep === 0) && (lang === "en")) {
            return validations[activeStep][0];
        } else if((activeStep === 0) && (lang === "sw")){
            return validations[activeStep][1];
        } else if((activeStep === 1) && (lang === "en")){
            return validations[activeStep][0];
        } else if((activeStep === 1) && (lang === "sw")){
            return validations[activeStep][1];
        } else if((activeStep === 2) && (lang === "en")){
            return validations[activeStep][0];
        } else if((activeStep === 2) && (lang === "sw")){
            return validations[activeStep][1];
        } else if ((activeStep === 3) && (lang === "en")) {
            if(!allowMultiply && !hasSubCategoryDetail){
                return validations[activeStep][0][1];
            }
            if(allowMultiply && !hasSubCategoryDetail){
                return validations[activeStep][0][2];
            }
            if(!allowMultiply && hasSubCategoryDetail){
                return validations[activeStep][0][4];
            }
            if(allowMultiply && hasSubCategoryDetail){
                return validations[activeStep][0][5];
            }
            return validations[activeStep][0][index];
        } else if((activeStep === 3) && (lang === "sw")){
            if(!allowMultiply && !hasSubCategoryDetail){
                return validations[activeStep][1][1];
            }
            if(allowMultiply && !hasSubCategoryDetail){
                return validations[activeStep][1][2];
            }
            if(!allowMultiply && hasSubCategoryDetail){
                return validations[activeStep][0][4];
            }
            if(allowMultiply && hasSubCategoryDetail){
                return validations[activeStep][0][5];
            }
            return validations[activeStep][1][index];
        } else {
            return validations[activeStep];
        }
    }


    const submitForm = (values, actions) => {
        console.log(renewregistration);
        let session                                       = sessionStorage.getItem("usn");
        let usrObj                                        = JSON.parse(session);
        let applObject                                    = {};
        let token                                         = usrObj.sessionToken;
        let userLang                                      = lang.toUpperCase();
        applObject.applicationId                          = renewregistration.applicationId;
        applObject.applicantId                            = usrObj.userId;
        applObject.applicantEmail                         = usrObj.email;
        applObject.applicantCapacity                      = values.applicantCapacity;
        applObject.identityType                           = renewregistration.identityType;
        applObject.identityNumber                         = renewregistration.identityNumber;
        applObject.customerType                           = renewregistration.customerType;
        applObject.customerName                           = values.customerName;
        applObject.customerPhoneNumber                    = values.phoneNumber;
        applObject.customerEmail                          = values.customerEmail;
        applObject.customerPostalAddress                  = values.postalAddress;
        applObject.businessEmail                          = values.bussnEmail;
        applObject.businessPostalAddress                  = values.bussnAddr;
        applObject.businessName                           = values.bussinessName;
        applObject.businessPhoneNumber                    = values.bussinessPhone;
        applObject.regionId                               = values.region;
        applObject.districtId                             = values.district;
        applObject.administrativeAreaId                   = values.admin_area;
        applObject.wardId                                 = values.ward;
        applObject.streetId                               = values.street_village;
        applObject.plot                                   = values.plot;
        applObject.block                                  = values.block;
        applObject.plotBlock                              = properBlockPlotName(values);
        applObject.houseNumber                            = values.houseNumber;
        applObject.propertyCategoryId                     = splitStringSelected(values.propertyCategory1);
        applObject.propertySubCategoryId                  = splitStringSelected(values.subCategory1);
        applObject.propertySubCategoryDetailId            = splitStringSelected(values.subCategoryDetail);
        applObject.numberOfItemsIfAny                     = ((values.numOfItems === undefined) || (values.numOfItems === null) || (values.numOfItems === "")) ? 0 : values.numOfItems;
        applObject.sendCorrespondenceTo                   = values.feedbackCorrespondent;
        applObject.language                               = userLang;
        console.log(applObject);
        submitApplicationRequest(dispatch, !appliLoading);
        setActiveStep(4);
        renewApplicationVersion1(callBack, applObject, token);
        actions.setSubmitting(false);
        actions.resetForm();
    };

    // value => ((value !== null) || (value !== "")) ? value.split(":")[0] : null;
    const splitStringSelected    = value => ((value !== null) || (value !== "")) ? value : null;

    const handleSubmit = (values, actions) => {
        if(activeStep === 4){
            submitApplicationRequest(dispatch, false);
            setShowRenewalPage(dispatch, false);
            setActiveStep(0);
        } else {
            if(values.identityType1 === "TIN"){
                let regisStepperWrapper      = {};
                regisStepperWrapper.formInfo = values;
                regisStepperWrapper.loading  = true;
                movingRegistrationStepper(dispatch, regisStepperWrapper);
            }
            if (isLastStep) {
                if (Object.getOwnPropertyNames(newappObj).length > 0) {
                    const confirmData = { "applicationId": newappObj.selfInspectionApplicationId };
                    confirmApplicationRequest(callBack1, confirmData);
                } else {
                    console.log("ERROR OCCUR");
                }
            } else {
                if ((activeStep === (steps.length - 2))) {
                    submitForm(values, actions);
                } else {
                    setActiveStep(activeStep + 1);
                    actions.setTouched({});
                    actions.setSubmitting(false);
                }
            }
        }
    };

    return ( 
    <>
        <MDBox py = {1} mb = {2} height = "60vh">
            <Grid container justifyContent = "center" alignItems = "center" sx = {{ height: "100%", mt: 3 }}>
                <Grid item xs = {12} sg = {12} >
                    <Formik initialValues = {initialValues} validationSchema = {activateValidationSchema()} onSubmit = {handleSubmit} >
                        {
                            ({ values, errors, touched, isSubmitting }) => ( 
                            <Form id = { formId } autoComplete = "off">
                                <Card sx = {{ height: "100%" }}>
                                <MDBox mx = { 2 } mt = {-3 }>
                                    <Stepper activeStep = { activeStep } alternativeLabel  >
                                     {steps.map(label => (<Step key = { label } >
                                        <StepLabel>
                                                    { label }  
                                                    </StepLabel> 
                                            </Step>))} 
                                           </Stepper>
                                        </MDBox>
                                    <MDBox p = {3}>
                                        <MDBox> {getStepContent(activeStep, {values, touched, formField, errors,})} 
                                            <MDBox mt = { 1 } py = { 2 } width = "100%" display = "flex" justifyContent = "space-between" > 
                                                {((activeStep === 4) || (activeStep === 0))  ? (<MDBox />) : (
                                                    <MDButton disabled = { appliLoading } variant = "gradient" color = "warning" onClick = { handleBack }>
                                                        {<FormattedMessage id="bck_btn" />}
                                                        {/* {isLastStep ? <FormattedMessage id="rvw_btn" /> :*/}
                                                    </MDButton>)} 
                                                <MDButton disabled = { isSubmitting } type = "submit" variant = "gradient" color = "warning">
                                                    {isLastStep ? <FormattedMessage id="ok" /> : <FormattedMessage id="nxt_btn" /> } 
                                                </MDButton> 
                                            </MDBox> 
                                        </MDBox> 
                                    </MDBox> 
                                </Card> 
                            </Form>)
                        } 
                    </Formik> 
                    { renderSuccessSB }
                    { renderErrorSB } 
                </Grid> 
            </Grid> 
        </MDBox>
    </>
    );
}

export default NewApplicationForm;