import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "ezimamoto/application/new-application/form/components/FormField";
import { useMaterialUIController, acceptTermsAndCondition } from "context";
import  { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import MDSelect from "ezimamoto/common/MDSelect";
import { useIntl } from 'react-intl';


function Verification({ formData }) {
    const { formField, values, errors, touched }                                      = formData;
    const { customType,identityNumber,identityType1,applicantCapacity }               = formField;
    const [ controller, dispatch ]                                                    = useMaterialUIController();
    const { review, newappObj, lang }                                                 = controller;
    const options                                                                     = [{ value : "1",     label: "Individual / Sole Proprietor "}, { value: "2",      label: "Company" }];
    const options1                                                                    = [{ value : "TIN",   label: "TIN"},        { value: "NIDA",   label: "NIDA" }];
    const options2                                                                    = [{ value : "TIN",   label: "TIN"}];

    const options3                                                                    = [{ value : "1",   label: "Owner"},
                                                                                          { value : "2",   label: "Supervisor"},
                                                                                          { value : "3",   label: "Manager"},
                                                                                          { value : "4",   label: "Director"},
                                                                                          { value : "5",   label: "Managing Director"},
                                                                                          { value : "6",   label: "General Manager"},
                                                                                          { value : "7",   label: "Director General"},
                                                                                          { value : "8",   label: "Representative"},
                                                                                          { value : "9",   label: "Chief Executive Officer"}];

  const options4                                                                      = [{ value : "1",   label: "Mmiliki"},
                                                                                          { value : "2",   label: "Msimamizi"},
                                                                                          { value : "3",   label: "Meneja"},
                                                                                          { value : "4",   label: "Mkurugenzi"},
                                                                                          { value : "5",   label: "Mkurugenzi Mtendaji"},
                                                                                          { value : "6",   label: "Meneja Mkuu"},
                                                                                          { value : "7",   label: "Mkurugenzi Mkuu"},
                                                                                          { value : "8",   label: "Mwakilishi"},
                                                                                          { value : "9",   label: "Afisa Mtendaji Mkuu"}];

    const { customType: customTypeV,
      identityNumber:     identityNumberV, identityType1: identityTypeV,
      applicantCapacity: applicantCapacityV }                                         = values;
    const [csttyp, setCsttyp]                                                         = useState(false);
    const [idtyp, setIdtyp]                                                           = useState(false);
    const [opts, setOpts]                                                             = useState([]);
    const [capty, setCapty]                                                           = useState([]);
    const intl                                                                        = useIntl();
    const cstm_ty                                                                     = intl.formatMessage({id: 'cstm_ty'});
    const idtyp_msg                                                                   = intl.formatMessage({id: 'idtyp_msg'});
    const idty_numbr                                                                  = intl.formatMessage({id: 'id_num'});
    const applicant_cap                                                               = intl.formatMessage({id: 'applicant_cap'});

    useEffect(() => {
      let session = sessionStorage.getItem("usn");
      if(session !== null){
        let usrObj             = JSON.parse(session);
        values.customerName    = (usrObj.companyName === "") ? "NO INFO" : usrObj.companyName;
      }
      if(review){
        setOpts(options1);
        if(lang === "sw"){
          setCapty(options4);
        } else {
          setCapty(options3);
        }

        if(newappObj.customerType === 2){
          setOpts(options2);
        } else if(newappObj.customerType === 1){
          setOpts(options1);
        }

        values.customerName       = newappObj.customerName;
        values.postalAddress      = newappObj.postalAddress;
        values.phoneNumber        = newappObj.phoneNumber;
        values.bussinessName      = newappObj.businessName;
        values.bussinessPhone     = newappObj.businessPhoneNumber;
        values.customType         = newappObj.customerType;
        values.identityType1      = newappObj.identityType;
        values.identityNumber     = newappObj.identityNumber;
        values.applicantCapacity  = newappObj.applicantCapacityId;
        acceptTermsAndCondition(dispatch, false);
      }
    }, []);

    useEffect(() => {
      if(lang === "sw"){
        setCapty(options4);
      } else {
        setCapty(options3);
      }
    }, [lang]);

    useEffect(() => {
      if(customTypeV !== ""){
        setCsttyp(true);
      } else {
        setCsttyp(false);
      }
      if(customTypeV === "2"){
        setOpts(options2);
      } else if(customTypeV === "1"){
        setOpts(options1);
      }
    }, [customTypeV]);

    useEffect(() => {
      if((identityTypeV !== "") && ((identityTypeV === "TIN") || (identityTypeV === "NIDA"))){
        if((identityTypeV === "NIDA")){
          let session = sessionStorage.getItem("usn");
          if((session !== null) || (session !== "")) {
              let user = JSON.parse(session);
              values.identityNumber = user.identityNumber;
          }
        } else {
          if(!review){
            values.identityNumber = "";
          }
        }
        setIdtyp(true);
      } else {
        setIdtyp(false);
      }
    }, [identityTypeV]);

    return (
      <MDBox>
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">{<FormattedMessage id="appli_form" />}</MDTypography>
        </MDBox>
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDSelect
                type={applicantCapacity.type}
                label={applicant_cap}
                name={applicantCapacity.name}
                value={applicantCapacityV}
                options={capty}
                error={errors.applicantCapacity && touched.applicantCapacity}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDSelect
                type={customType.type}
                label={cstm_ty}
                name={customType.name}
                value={customTypeV}
                options={options}
                error={errors.customType && touched.customType}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6} >
            <MDBox pt={3} />
            {(csttyp) && (
                <MDSelect
                  type={identityType1.type}
                  label={idtyp_msg}
                  name={identityType1.name}
                  value={identityTypeV}
                  options={opts}
                  error={errors.identityType1 && touched.identityType1}
                />
              )}
          </Grid>
            <Grid item xs={12} sm={6}>
            <MDBox pt={2} />
            {(csttyp && idtyp) && (
                <FormField
                  type={identityNumber.type}
                  label={idty_numbr}
                  name={identityNumber.name}
                  value={identityNumberV}
                  placeholder={identityNumber.placeholder}
                  InputProps={{
                    maxLength: 20,
                    minLength: 11,
                    readOnly:  (identityTypeV === "NIDA" || review),
                  }}
                  variant={(identityTypeV === "NIDA") ? "filled" : "standard"}
                  error={errors.identityNumber && touched.identityNumber}
                  success={(identityNumberV.length > 0) && !errors.identityNumber}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    );
  }
  
  Verification.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  };
  
  export default Verification;
  