import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "ezimamoto/application/new-application/form/components/FormField";
import { getAllRegions, getDistrictsForRegions, getAdminAreasForDistricts, getWardsForAdminsArea, getStreetOrVillageByWard } from "apis";
import { useState, useEffect } from "react";
import MDSelect from "ezimamoto/common/MDSelect";
import { useMaterialUIController } from "context";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';

function Location({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { block, plot, houseNumber, region, district, admin_area, ward, street_village, } = formField;
  const { region: regionV, district: districtV, admin_area: admin_areaV, ward: wardV, 
    street_village: street_villageV, block: blockV, plot: plotV, houseNumber: houseNumberV, } = values;
    const [ controller]                                                                       = useMaterialUIController();
    const { review, newappObj }                                                               = controller;
    const [regions, setRegions]                                                               = useState([]);
    const [districts, setDistricts]                                                           = useState([]);
    const [adminAreas, setAdminAreas]                                                         = useState([]);
    const [wards, setWards]                                                                   = useState([]);
    const [sttVllg, setSttvllg]                                                               = useState([]);
    const intl                                                                                = useIntl();
    const rgon                                                                                = intl.formatMessage({id: 'rgon'});
    const dstrt                                                                               = intl.formatMessage({id: 'dstrt'});
    const admn_area                                                                           = intl.formatMessage({id: 'admn_area'});
    const wrd                                                                                 = intl.formatMessage({id: 'ward'});
    const strt_vllg                                                                           = intl.formatMessage({id: 'strt_vllg'});
    const blc_msg                                                                             = intl.formatMessage({id: 'blc_msg'});
    const plt_msg                                                                             = intl.formatMessage({id: 'plt_msg'});
    const hs_num                                                                              = intl.formatMessage({id: 'hs_num'});
    

    useEffect(() => {
        let session        = sessionStorage.getItem("usn");
        let user           = JSON.parse(session);
        let token          = user.sessionToken;
        getAllRegions(callBack, token);
        if(review){
          values.block           = newappObj.block;
          values.plot            = newappObj.plot;
          values.houseNumber     = newappObj.houseNumber;
          values.region          = newappObj.regionId;
          values.district        = newappObj.districtId;
          values.admin_area      = newappObj.administrativeAreaId;
          values.ward            = newappObj.wardId;
          values.street_village  = newappObj.streetId;
        }
    }, []);

    useEffect(() => {
      let session        = sessionStorage.getItem("usn");
      let user           = JSON.parse(session);
      let token          = user.sessionToken;
      let regionObject   = { "regionId": values.region };
      if(values.region !== ""){
        getDistrictsForRegions(callBack1, regionObject, token);
      }
    }, [values.region]);

    useEffect(() => {
      let session        = sessionStorage.getItem("usn");
      let user           = JSON.parse(session);
      let token          = user.sessionToken;
      let districtObject = { "districtId": values.district };
      if(values.district !== ""){
        getAdminAreasForDistricts(callBack2, districtObject, token);
      }
    }, [values.region, values.district]);

    useEffect(() => {
      let session        = sessionStorage.getItem("usn");
      let user           = JSON.parse(session);
      let token          = user.sessionToken;
      let adminAreaObject = { "administrativeAreaId": values.admin_area };
      if(values.admin_area !== ""){
        getWardsForAdminsArea(callBack3, adminAreaObject, token);
      }
    }, [values.region, values.district, values.admin_area]);

    useEffect(() => {
      let session        = sessionStorage.getItem("usn");
      let user           = JSON.parse(session);
      let token          = user.sessionToken;
      let wardObject = { "wardId": values.ward };
      if(values.ward !== ""){
        console.log(wardObject);
        getStreetOrVillageByWard(callBack4, wardObject, token);
      }
    }, [values.region, values.district, values.admin_area, values.ward]);

    const callBack  = data => setRegions(data)
    const callBack1 = data => setDistricts(data)
    const callBack2 = data => setAdminAreas(data)
    const callBack3 = data => setWards(data)
    const callBack4 = data => setSttvllg(data)

    // console.log(regions);

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
      {<FormattedMessage id="lct_msg" />}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <MDSelect
              type={region.type}
              label={rgon}
              name={region.name}
              value={regionV}
              options={regions}
              error={errors.region && touched.region}
              success={(regionV.length > 0 && !errors.region).toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MDSelect
              type={district.type}
              label={dstrt}
              name={district.name}
              value={districtV}
              options={districts}
              error={errors.district && touched.district}
              success={(districtV.length > 0 && !errors.district).toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MDSelect
              type={admin_area.type}
              label={admn_area}
              name={admin_area.name}
              value={admin_areaV}
              options={adminAreas}
              error={errors.admin_area && touched.admin_area}
              success={(admin_areaV.length > 0 && !errors.admin_area).toString()}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox mt={-1.625} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <MDSelect
              type={ward.type}
              label={wrd}
              name={ward.name}
              options={wards}
              value={wardV}
              error={errors.ward && touched.ward}
              success={(wardV.length > 0 && !errors.ward).toString()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
          <MDSelect
              type={street_village.type}
              label={strt_vllg}
              name={street_village.name}
              value={street_villageV}
              options={sttVllg}
              error={errors.street_village && touched.street_village}
              success={(street_villageV.length > 0 && !errors.street_village).toString()}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox mt={-1.625} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
                type={plot.type}
                label={plt_msg}
                name={plot.name}
                value={plotV}
              />

          </Grid>
          <Grid item xs={12} sm={4}>
              <FormField
                type={block.type}
                label={blc_msg}
                name={block.name}
                value={blockV}
              />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type={houseNumber.type}
              label={hs_num}
              name={houseNumber.name}
              value={houseNumberV}
            />
          </Grid>
        </Grid>

      </MDBox>
    </MDBox>
  );
}

Location.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Location;