import React from 'react';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import { fetchAllExistingApplications, fetchSpecificExistingApplication } from "apis";
import MaterialTable from 'material-table';
import MDSnackbar from "components/MDSnackbar";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import FormField from "ezimamoto/application/existing-application/ExstAppInfo/FormField";
import MDButton from "components/MDButton";
import { TablePagination } from '@mui/material';
import  { Navigate } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { useMaterialUIController } from "context";
import { useIntl } from 'react-intl';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ExistingApplication = () => {
    const [controller, dispatch]                          = useMaterialUIController();
    const [columns, setColumns]                           = useState([]);
    const [rows, setRows]                                 = useState([]);
    const [loading, setLoading]                           = useState(false);
    const [successSB, setSuccessSB]                       = useState(false);
    const [content, setContent]                           = useState("");
    const [header, setHeader]                             = useState("");
    const [errorSB, setErrorSB]                           = useState(false);
    const [renderCondition, setRenderCondition]           = useState(true);
    // const [previewInfo, setPreviewInfo]                   = useState({});
    const [cstNm, setCstnm]                               = useState("");
    const [cstphn, setCstphn]                             = useState("");
    const [cstpaddr, setCstpaddr]                         = useState("");
    const [bsnnm, setBsnnm]                               = useState("");
    const [bsnph, setBsnph]                               = useState("");
    const [idtyp, setIdtyp]                               = useState("");
    const [idnum, setIdnum]                               = useState("");
    const [regin, setRegin]                               = useState("");
    const [distr, setDistr]                               = useState("");
    const [admns, setAdmns]                               = useState("");
    const [ward, setWard]                                 = useState("");
    const [strvlg, setStrvlg]                             = useState("");
    const [block, setBlock]                               = useState("");
    const [hous, setHous]                                 = useState("");
    const [prpca, setPropc]                               = useState("");
    const [prpsb, setPrpsb]                               = useState("");
    const [prpsd, setPrpsd]                               = useState("");
    const [nmifn, setNmifn]                               = useState(0);
    const [amntb, setAmntb]                               = useState("");
    const { lang }                                        = controller;

    const intl                                            = useIntl();

    const cstm_nm                                         = intl.formatMessage({id: 'cstm_nm'});
    const cstm_phn                                        = intl.formatMessage({id: 'cstm_phn'});
    const pstl_addr                                       = intl.formatMessage({id: 'pstl_addr'});
    const bssn_nm                                         = intl.formatMessage({id: 'bssn_nm'});
    const bssn_phn                                        = intl.formatMessage({id: 'bssn_phn'});
    const id_typ                                          = intl.formatMessage({id: 'id_typ'});
    const id_num                                          = intl.formatMessage({id: 'id_num'});
    const rgon                                            = intl.formatMessage({id: 'rgon'});
    const dstrt                                           = intl.formatMessage({id: 'dstrt'});
    const admn_area                                       = intl.formatMessage({id: 'admn_area'});
    const wrd                                             = intl.formatMessage({id: 'ward'});
    const strt_vllg                                       = intl.formatMessage({id: 'strt_vllg'});
    const blc_plt                                         = intl.formatMessage({id: 'blc_plt'});
    const hs_num                                          = intl.formatMessage({id: 'hs_num'});
    const prp_ctgry                                       = intl.formatMessage({id: 'prp_ctgry'});
    const prp_sbgry                                       = intl.formatMessage({id: 'prp_sbgry'});
    const prp_sbgdt                                       = intl.formatMessage({id: 'prp_sbgdt'});
    const num_itm                                         = intl.formatMessage({id: 'num_itm'});
    const amt_billd                                       = intl.formatMessage({id: 'amt_billd'});

    useEffect(() => {
        let session = sessionStorage.getItem("usn");
        if((session !== null) || (session !== "")) {
            let user = JSON.parse(session);
            let token = user.sessionToken;
            const postData = { "userId": user.userId };
            fetchAllExistingApplications(callBack, postData, token);
        }

       // setShowChangeMenuAction(dispatch, false);
    }, [lang]);

    const callBackApi = data => {
        console.log(data);
        // setPreviewInfo(data);
        setRenderCondition(false);
        setCstnm(checkIfValueIsNullAndReturnEmptyString(data.result.customerName));
        setCstphn(checkIfValueIsNullAndReturnEmptyString(data.result.phoneNumber));
        setCstpaddr(checkIfValueIsNullAndReturnEmptyString(data.result.postalAddress));
        setBsnnm(checkIfValueIsNullAndReturnEmptyString(data.result.businessName));
        setBsnph(checkIfValueIsNullAndReturnEmptyString(data.result.businessPhoneNumber));
        setIdtyp(checkIfValueIsNullAndReturnEmptyString(data.result.identityType));
        setIdnum(checkIfValueIsNullAndReturnEmptyString(data.result.identityNumber));
        setRegin(checkIfValueIsNullAndReturnEmptyString(data.result.region));
        setDistr(checkIfValueIsNullAndReturnEmptyString(data.result.district));
        setAdmns(checkIfValueIsNullAndReturnEmptyString(data.result.administrativeArea));
        setWard(checkIfValueIsNullAndReturnEmptyString(data.result.ward));
        setStrvlg(checkIfValueIsNullAndReturnEmptyString(data.result.street));
        setBlock(checkIfValueIsNullAndReturnEmptyString(data.result.blockPlot));
        setHous(checkIfValueIsNullAndReturnEmptyString(data.result.houseNumber));
        setPropc(checkIfValueIsNullAndReturnEmptyString(data.result.propertyCategory));
        setPrpsb(checkIfValueIsNullAndReturnEmptyString(data.result.propertySubCategory));
        setPrpsd(checkIfValueIsNullAndReturnEmptyString(data.result.propertySubCategoryDetail));
        setNmifn(checkIfValueIsNullAndReturnZeroInt(data.result.numberOfItemsIfAny));
        setAmntb(checkIfValueIsNullAndReturnZeroInt(data.result.billedAmount));
        setContent(checkIfValueIsNullAndReturnEmptyString(data.message));
        setHeader(checkIfValueIsNullAndReturnEmptyString(data.status));
        if(data.error){
            setErrorSB(true);
        } else {
            setSuccessSB(true);
        }
    }

    const checkIfValueIsNullAndReturnEmptyString = value => ((value === null) ? "No Information" : value);
    const checkIfValueIsNullAndReturnZeroInt = value => ((value === null) ? 0 : value);

    const fetchExistingApi = (event, data) => {
        let session        = sessionStorage.getItem("usn");
        let user           = JSON.parse(session);
        let token          = user.sessionToken;
        setLoading(true);
        const postData = { "applicationId": data.applicationId };
        fetchSpecificExistingApplication(callBackApi, postData, token);
        setLoading(false);
    }

    const renderSuccessSB = (
        <MDSnackbar
          color="success"
          icon="check"
          title={header}
          content={content}
          dateTime={new Date().toDateString()}
          open={successSB}
          onClose={() => setSuccessSB(false)}
          close={() => setSuccessSB(false)}
          bgWhite
        />
    );

    const renderErrorSB = (
        <MDSnackbar
          color="error"
          icon="warning"
          title={header}
          content={content}
          dateTime={new Date().toDateString()}
          open={errorSB}
          onClose={() => setErrorSB(false)}
          close={() => setErrorSB(false)}
          bgWhite
        />
    );


    const callBack = data => {
        console.log("-------------------------------");
        console.log(data);
        console.log("-------------------------------");
        if(data[0] !== null || data[0] !== undefined){
            let columnsDef = null;
            if(lang === "sw"){
                columnsDef = [
                    { title: "UT UK", field: "selfInspectionApplicationId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10 }},
                    { title: "UT MFM", field: "applicantId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "OMBI/Na.", field: "applicationNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "JIN / MTJ", field: "customerName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "JIN / BSHR", field: "businessName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "ANwN/P", field: "postalAddress", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "SIM/BSHR", field: "businessPhoneNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "KIPNG/MILIKI", field: "propertyCategory", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "KIAS/TOZWA", field: "billedAmount", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                ];
            } else {
                columnsDef = [
                    { title: "INS ID", field: "selfInspectionApplicationId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10 }},
                    { title: "APP ID", field: "applicantId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "APPL NO.", field: "applicationNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "CUST NAME", field: "customerName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "BUSS NAME", field: "businessName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "P. ADDRESS", field: "postalAddress", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "BUSS PHONE", field: "businessPhoneNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "PROP CATEGORY", field: "propertyCategory", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "BILLED AMNT", field: "billedAmount", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                ];
            }
            setColumns(columnsDef);
            setRows(data);
        }
    };

    const getUserSession = () => {
        let session = sessionStorage.getItem("usn");
        if((session === null) || (session === "")){
            return <Navigate to='/'  />
        } else {
            let user = JSON.parse(session);
            if((typeof user === 'object') && (user !== null)){
               return (        
                <>
                {renderCondition && (
                    <DashboardLayout>
                        <DashboardNavbar />
                        <MDBox py={1} mb={-8} >
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={12} lg={12}>
                                <MaterialTable
                                    title={<FormattedMessage id="exts_appl" />}
                                    columns={columns}
                                    data={rows}
                                    isLoading={loading}
                                    localization={{ 
                                        header: {
                                            actions: <FormattedMessage id="tbl_act" />,
                                        }
                                    }}
                                    style={{ 
                                        textTransform: 'none'
                                    }}
                                    actions={[
                                        {
                                          icon: 'save',
                                          tooltip: 'Save User',
                                          onClick: (event, rowData) => fetchExistingApi(event, rowData)
                                        }
                                    ]}
                                    options={{ 
                                        actionsColumnIndex: -1,
                                        doubleHorizontalScroll: true,
                                        loadingType: 'overlay',
                                        headerStyle: {
                                            backgroundColor: 'orange',
                                            whiteSpace: "nowrap",
                                            color: 'white',
                                        },
                                        padding: 'dense',
                                        paginationType: 'stepped',
                                        searchFieldVariant: 'outlined',
                                        rowStyle: (data, index, level) => {
                                            if((index % 2) === 0){
                                                return { backgroundColor: '#EEE',fontSize: 12};
                                            } else {
                                                return { backgroundColor: '#FFF', fontSize: 12};
                                            }
                                        }
                                    }}
                                    components={{
                                        Action: props => (
                                            <MDButton 
                                              variant="gradient" 
                                              type="button" 
                                              color="info" 
                                              size="small"
                                              onClick={event => props.action.onClick(event, props.data)}
                                            >
                                              <VisibilityIcon />
                                            </MDButton>
                                        ),
                                        Pagination: (props) => {
                                          const {
                                            ActionsComponent,
                                            onChangePage,
                                            onChangeRowsPerPage,
                                            ...tablePaginationProps
                                          } = props;
                                          return (
                                            <>
                                              <TablePagination
                                                {...tablePaginationProps}
                                                onPageChange={onChangePage}
                                                onRowsPerPageChange={onChangeRowsPerPage}
                                                ActionsComponent={(subprops) => {
                                                  const { onPageChange, ...actionsComponentProps } =
                                                    subprops;
                                                  return (
                                                    <ActionsComponent
                                                      {...actionsComponentProps}
                                                      onChangePage={onPageChange}
                                                    />
                                                  );
                                                }}
                                              />
                                            </>
                                          );
                                        },
                                      }}
                                />
                                </Grid>
                            </Grid>
                        </MDBox>
                        {renderSuccessSB}
                        {renderErrorSB}
                    </DashboardLayout>
                )}
                {!renderCondition && (
                    <DashboardLayout>
                        <DashboardNavbar />
                        <MDBox py={3} mb={10}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={12} lg={12}>
                                    <Card id="basic-info" sx={{ overflow: "visible" }}>
                                        <MDBox p={3}>
                                            <MDTypography variant="h5">{<FormattedMessage id="appl_infrm" />}</MDTypography>
                                        </MDBox>
                                        <MDBox pb={3} px={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4} >
                                                    <FormField label={cstm_nm} InputProps={{ readOnly: true, }} value={cstNm} onChange={e => setCstnm(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <FormField label={cstm_phn} InputProps={{ readOnly: true, }} value={cstphn} onChange={e => setCstphn(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <FormField label={pstl_addr} InputProps={{ readOnly: true, }} value={cstpaddr} onChange={e => setCstpaddr(e.target.value)} />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6} mt={2}>
                                                    <FormField label={bssn_nm} InputProps={{ readOnly: true, }} value={bsnnm} onChange={e => setBsnnm(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} mt={2}>
                                                    <FormField label={bssn_phn} InputProps={{ readOnly: true, }} value={bsnph} onChange={e => setBsnph(e.target.value)} />
                                                </Grid>
                                            </Grid>
    
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6} mt={2}>
                                                    <FormField label={id_typ} InputProps={{ readOnly: true, }} value={idtyp} onChange={e => setIdtyp(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} mt={2}>
                                                    <FormField label={id_num} InputProps={{ readOnly: true, }} value={idnum} onChange={e => setIdnum(e.target.value)} />
                                                </Grid>
                                            </Grid>
    
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4} mt={2}>
                                                    <FormField label={rgon} InputProps={{ readOnly: true, }} value={regin} onChange={e => setRegin(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={4} mt={2}>
                                                    <FormField label={dstrt} InputProps={{ readOnly: true, }} value={distr} onChange={e => setDistr(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={4} mt={2}>
                                                    <FormField label={admn_area} InputProps={{ readOnly: true, }} value={admns} onChange={e => setAdmns(e.target.value)} />
                                                </Grid>
                                            </Grid>
    
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={3} mt={2}>
                                                    <FormField label={wrd} InputProps={{ readOnly: true, }} value={ward} onChange={e => setWard(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} mt={2}>
                                                    <FormField label={strt_vllg} InputProps={{ readOnly: true, }} value={strvlg} onChange={e => setStrvlg(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} mt={2}>
                                                    <FormField label={blc_plt} InputProps={{ readOnly: true, }} value={block} onChange={e => setBlock(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} mt={2}>
                                                    <FormField label={hs_num} InputProps={{ readOnly: true, }} value={hous} onChange={e => setHous(e.target.value)} />
                                                </Grid>
                                            </Grid>
    
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4} mt={2}>
                                                    <FormField label={prp_ctgry} InputProps={{ readOnly: true, }} value={prpca} onChange={e => setPropc(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={4} mt={2}>
                                                    <FormField label={prp_sbgry} InputProps={{ readOnly: true, }} value={prpsb} onChange={e => setPrpsb(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={4} mt={2}>
                                                    <FormField label={prp_sbgdt} InputProps={{ readOnly: true, }} value={prpsd} onChange={e => setPrpsd(e.target.value)} />
                                                </Grid>
                                            </Grid>
    
                                            <Grid container spacing={3}>
                                                <Grid item xs={10} sm={6} mt={1.3}>
                                                    <FormField label={num_itm} InputProps={{ readOnly: true, }} value={nmifn} onChange={e => setNmifn(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} mt={1.3}>
                                                    <FormField label={amt_billd} InputProps={{ readOnly: true, }} value={amntb} onChange={e => setAmntb(e.target.value)} />
                                                </Grid>
                                            </Grid>
                                        </MDBox>
    
                                        <MDBox pt={3} pb={2}>
                                            <Grid container spacing={1} pb={3} px={3} mt={-4} py={1}>
                                                <Grid item xs={12} md={3}>
                                                <Grid item xs={12} md={3} />
                                                <Grid item xs={12} md={3} />
                                                <Grid item xs={12} md={3} />
                                                    <MDButton
                                                        variant="gradient"
                                                        color="info"
                                                        fullWidth
                                                        onClick={() => setRenderCondition(true)}
                                                    >
                                                        {<FormattedMessage id="bck_btn" />}
                                                    </MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    </Card>
                                </Grid>
                            </Grid>
                        </MDBox>
                    
                    </DashboardLayout>
                )}
                </>);
            } else {
                return <Navigate to='/'  />
            }
        }
    }

    return (
        <>
            {getUserSession()}
        </>
    );
}

export default ExistingApplication;