const form = {
    formId: "login-form",
    formField: {
      email: {
        name          : "email",
        label         : "E-mail",
        type          : "email",
        errorMsg      : "E-mail is required.",
        errorMsgSw    : "Barua pepe inahitajika.",
        invalidMsg    : "E-mail is not valid.",
        invalidMsgSw  : "Barua pepe haipo sahihi.",
      },
      password: {
        name          : "password",
        label         : "Password",
        type          : "password",
        errorMsg      : "Password is required.",
        errorMsgSw    : "Nywira inahitajika.",
      },
    },
  };
  
  export default form;
  