import * as Yup from "yup";
import checkout from "ezimamoto/customer-registration/new-registration-rule/form/schemas/form";

const { formField: { 
  identityNumber, 
  qAnswer,     
  title,
  phonenumber,
  email,
  password,
  confirmpassword, 
}, } = checkout;

const strong_pswd = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)+(?=.*[-+_!@#$%^&*., ?]).+$";

const validations = [
  [
    Yup.object().shape({
      [identityNumber.name]   : Yup.string().required(identityNumber.errorMsg).min(20, identityNumber.minMsg).max(23, identityNumber.maxMsg),
    }),
    Yup.object().shape({
      [identityNumber.name]   : Yup.string().required(identityNumber.errorMsgSw).min(20, identityNumber.minMsgSw).max(23, identityNumber.maxMsgSw),
    })
  ],
  [
    Yup.object().shape({
      [qAnswer.name]          : Yup.string().required(qAnswer.errorMsg),
    }),
    Yup.object().shape({
      [qAnswer.name]          : Yup.string().required(qAnswer.errorMsgSw),
    }),
  ],
  [
    Yup.object().shape({}).noUnknown(),
    Yup.object().shape({}).noUnknown(),
  ],
  [
    Yup.object().shape({
      [title.name]                             : Yup.string().required(title.errorMsg),
      [phonenumber.name]                       : Yup.string().max(10, phonenumber.maxMsg).min(10, phonenumber.minMsg).required(phonenumber.errorMsg),
      [email.name]                             : Yup.string().email(email.invalidMsg).required(email.errorMsg),
      [password.name]                          : Yup.string().required(password.errorMsg).matches(strong_pswd, password.errorWeakMsg),
      [confirmpassword.name]                   : Yup.string().required(confirmpassword.errorMsg)
                                                  .oneOf([Yup.ref("password"), null], confirmpassword.invalidMsg)
                                                  .matches(strong_pswd, confirmpassword.errorWeakMsg),
    }),
    Yup.object().shape({
      [title.name]                             : Yup.string().required(title.errorMsgSw),
      [phonenumber.name]                       : Yup.string().max(10, phonenumber.maxMsgSw).min(10, phonenumber.minMsgSw).required(phonenumber.errorMsgSw),
      [email.name]                             : Yup.string().email(email.invalidMsgSw).required(email.errorMsgSw),
      [password.name]                          : Yup.string().required(password.errorMsgSw).matches(strong_pswd, password.errorWeakMsgSw),
      [confirmpassword.name]                   : Yup.string().required(confirmpassword.errorMsgSw)
                                                  .oneOf([Yup.ref("password"), null], confirmpassword.invalidMsgSw)
                                                  .matches(strong_pswd, confirmpassword.errorWeakMsgSw)           
    })
  ]
];

export default validations;
