import form from "ezimamoto/Password/ResetPassword/Schema/form";

const {
  formField: {
    email,
  },
} = form;

const initialValues = {
  [email.name]           : "",
};

export default initialValues;
