import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormField from "ezimamoto/common/FormField";
import MDSelect from "ezimamoto/common/MDSelect";
import titleData from "ezimamoto/common/constants/titles";
import { useEffect, useState } from "react";
import { useMaterialUIController, acceptTermsAndConditionReg } from "context";
import ClockLoader from "react-spinners/ClockLoader";
import { useIntl } from 'react-intl';
import Checkbox from "@mui/material/Checkbox";
import MDTypography from "components/MDTypography";
import { FormattedMessage } from "react-intl";
import * as yup from 'yup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { pink } from '@mui/material/colors';



const RegistrationForm = ({ formData })  =>  {
  const [controller, dispatch]                                    = useMaterialUIController();
  const { loadRegistration }                            = controller;
  const { formField, values, errors, touched }          = formData;
  //const intl                                            = useIntl();

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const intl                                                                                = useIntl();
  const terms_and_con                                                                       = intl.formatMessage({id: 'terms_and_con'});

 
  const { 
    title          ,
    phonenumber    ,
    email          ,
    password       ,
    confirmpassword,
   }                                                   = formField;
  const { 
    title          :  titleValue,
    phonenumber    :  phonenumberValue,
    email          :  emailValue,
    password       :  passwordValue,
    confirmpassword: confirmpasswordValue
   }                                                   = values;
 
  const [titles, setTitles]         = useState([]);

  useEffect(()=>{
    setTitles(titleData.titles);
  }, [])

  const ttle_lbl     = intl.formatMessage({id: 'ttle_lbl'});
  const phn_numbr    = intl.formatMessage({id: 'phn_numbr'});
  const eml_lbl      = intl.formatMessage({id: 'eml_lbl'});
  const pssw_lbl     = intl.formatMessage({id: 'pssw_lbl'});
  const pwd_cnflabel = intl.formatMessage({id: 'pwd_cnflabel'});

  const changeTermsAndConditionRdxReg = event => {
    if(event.target.checked){
      acceptTermsAndConditionReg(dispatch, true);
    } else {
      acceptTermsAndConditionReg(dispatch, false);
    }
  }
 


  return (
    <>
      {!loadRegistration && (
        <>
          <Grid container spacing={3}>
            <Grid item sm={12} lg={6} md={6}>
              <MDBox pl={2} pr={2} pb={3}>
                <MDSelect 
                  type={title.type}
                  label={ttle_lbl}
                  name={title.name}
                  value={titleValue}
                  options={titles}
                  placeholder={title.placeholder}
                  error={errors.title && touched.title}
                  success={(titleValue.length > 0 && !errors.title).toString()}
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={12} lg={6} md={6}>
              <MDBox pl={2} pr={2}>
                <FormField
                  type={phonenumber.type}
                  label={phn_numbr}
                  name={phonenumber.name} 
                  value={phonenumberValue}
                  inputProps={{maxLength :10}}
                  placeholder={phonenumber.placeholder}
                  error={errors.phonenumber && touched.phonenumber}
                  success={phonenumberValue.length > 0 && !errors.phonenumber}
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <MDBox pl={2} pr={2}>
                <FormField 
                  type={email.type}
                  label={eml_lbl} 
                  name={email.name}
                  value={emailValue}
                  placeholder={email.placeholder}
                  error={errors.email && touched.email}
                  success={emailValue.length > 0 && !errors.email}
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <MDBox pl={2} pr={2}>
                <FormField 
                  type={password.type}
                  label={pssw_lbl} 
                  name={password.name}
                  value={passwordValue}
                  placeholder={password.placeholder}
                  error={errors.password && touched.password}
                  success={passwordValue.length > 0 && !errors.password}
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <MDBox pl={2} pr={2}>
                <FormField
                  type={confirmpassword.type}
                  label={pwd_cnflabel}
                  name={confirmpassword.name}
                  value={confirmpasswordValue}
                  placeholder={confirmpassword.placeholder}
                  error={errors.confirmpassword && touched.confirmpassword}
                  success={confirmpasswordValue.length > 0 && !errors.confirmpassword}
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
          </Grid>
         
          {!loadRegistration && (
            <>
              <FormGroup>
                  <FormControlLabel control={ <Checkbox
                    {...label}
                    onChange={changeTermsAndConditionRdxReg}
                    sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                        color: pink[600],
                      },
                    }} 
                  />} label={terms_and_con} 
              />
              </FormGroup>
                <Typography variant="caption" display="block" gutterBottom sx={{ fontWeight: 'bold' }}>
                <FormattedMessage id="IAG" />
              </Typography>
            </>)}
        </>
      )}
      {loadRegistration && (
        <Grid container spacing={2}>
          <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
              <ClockLoader loading={loadRegistration} speedMultiplier={3} size={150}  color="#36d7b7"/>
            </Grid>
          <MDBox pb={5} />
          <Grid item xs={4} md={4} />
        </Grid>
      )}
    </>);
}

RegistrationForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RegistrationForm;