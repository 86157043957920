import checkout from "ezimamoto/application/new-application/form/schemas/form";

const {
  formField: {
    customerName,
    postalAddress,
    phoneNumber,
    bussinessName,
    bussinessPhone,
    block,
    plot,
    houseNumber,
    region,
    district,
    admin_area,
    ward,
    street_village,
    propertyCategory,
    subCategory,
    numOfItems,
    subCategoryDetail,
    customType,
    identityNumber,
    identityType1,
    customerEmail,
    bussnEmail,
    bussnAddr,
    feedbackCorrespondent,
    applicantCapacity,
  },
} = checkout;

const initialValues = {
  [customerName.name]           : "",
  [postalAddress.name]          : "",
  [phoneNumber.name]            : "",
  [bussinessName.name]          : "",
  [bussinessPhone.name]         : "",
  [block.name]                  : "",
  [plot.name]                   : "",
  [houseNumber.name]            : "",
  [region.name]                 : "",
  [district.name]               : "",
  [admin_area.name]             : "",
  [ward.name]                   : "",
  [street_village.name]         : "",
  [propertyCategory.name]       : "",
  [subCategory.name]            : "",
  [numOfItems.name]             : "",
  [subCategoryDetail.name]      : "",
  [customType.name]             : "",
  [identityNumber.name]         : "",
  [identityType1.name]          : "",
  [customerEmail.name]          : "",
  [bussnEmail.name]             : "",
  [bussnAddr.name]              : "",
  [feedbackCorrespondent.name]  : "",
  [applicantCapacity.name]      : "",
};

export default initialValues;
