import React, { useEffect, useState } from 'react';
import { Formik, Form } from "formik";
import ApplicationLayout from "ezimamoto/common/app-layout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import initialValues from "ezimamoto/application/application-status/schemas/initialValues";
import form from "ezimamoto/application/application-status/schemas/form";
import ApplicationStatus from "ezimamoto/application/application-status/application-status";
import { fetchApplicationStatus } from "apis";
import ClockLoader from "react-spinners/ClockLoader";
import Grid from "@mui/material/Grid";
import FormField from "ezimamoto/application/existing-application/ExstAppInfo/FormField";
import MDButton from "components/MDButton";
import { Navigate } from 'react-router-dom';
import * as Yup from "yup";
import checkout from "ezimamoto/application/application-status/schemas/form";
import { useMaterialUIController, setShowChangeMenuAction } from "context";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';

const ApplicationtatusComponent = () => {
        const intl                  = useIntl();
        const { formId, formField } = form;
        const [ctn, setCtn] = useState("");
        const [app, setApp] = useState("");
        const [sts, setSts] = useState("");
        const [msg, setMsg] = useState("");
        const [ldr, setLdr] = useState(0);
        const [controller, dispatch]  = useMaterialUIController();
        const { lang }                = controller;
        const frf_y                   = intl.formatMessage({id: 'frf_y'});












        const {
            formField: {
                applicationId,
                controlNumber,
            },
        } = checkout;
        const validations = Yup.object().shape({
            [applicationId.name]: Yup.lazy(() => Yup.string().when(['controlNumber', 'applicationId'], {
                is: (a, c) => !a && !c,
                then: Yup.string().required((lang === "en") ? applicationId.errorMsg : applicationId.errorMsgSw)
            })),
            [controlNumber.name]: Yup.lazy(() => Yup.string().when(['controlNumber', 'applicationId'], {
                is: (a, c) => !a && !c,
                then: Yup.string().required((lang === "en") ? controlNumber.errorMsg : controlNumber.errorMsgSw)
            }))
        });

        const callBack = data => {
            console.log(data);
            if (data !== null) {
                setCtn((data.result !== null) ? ((data.result.controlNumber === null) ? "No Information" : data.result.controlNumber) : "No Information");
                setApp((data.result !== null) ? ((data.result.applicationNumber === null) ? "No Information" : data.result.applicationNumber) : "No Information");
                setSts((data.result !== null) ? ((data.result.status === null) ? "No Information" : data.result.status) : "No Information");
                setMsg(data.message);
            } else {
                setCtn("No Information");
                setApp("No Information");
                setSts("No Information");
                setMsg("Failed");
            }
        }
        const handleSubmit = (values, actions) => {
            submitForm(values, actions);
            actions.setTouched({});
            actions.setSubmitting(false);
            setLdr(2);
        }

        const submitForm = (values, actions) => {
            let session        = sessionStorage.getItem("usn");
            let user           = JSON.parse(session);
            let token          = user.sessionToken;
            setLdr(1);
            const postData = {
                "applicationNumber" : (values.applicationId === "") ? null : values.applicationId,
                "controlNumber"     : (values.controlNumber === "") ? null : values.controlNumber
            };
            fetchApplicationStatus(callBack, postData, token);
            actions.setSubmitting(false);
            actions.resetForm();
        }

        useEffect(() => {
            setShowChangeMenuAction(dispatch, true);
        }, []);

        const getUserSession = () => {
            let session = sessionStorage.getItem("usn");
            if ((session === null) || (session === "")) {
                return <Navigate to = '/' / >
            } else {
                let user = JSON.parse(session);
                if ((typeof user === 'object') && (user !== null)) {
                    return ( <> {
                            (ldr === 0) && ( <ApplicationLayout>
                                <DashboardNavbar />
                                <MDBox pt = { 1 } pb = { 3 } >
                                    <MDBox mt = { 1.625 } >
                                        <Formik initialValues = { initialValues }
                                        validationSchema = { validations }
                                        onSubmit = { handleSubmit } >
                                        {
                                            ({ values, errors, touched, isSubmitting }) => (
                                            <Form id = { formId } autoComplete = "off" >
                                                <Card sx = {{ height: "100%" }} >
                                                    <MDBox p = { 3 } >
                                                        <MDBox >
                                                            <ApplicationStatus formData = {{ values, errors, touched, formField }} /> 
                                                        </MDBox> 
                                                    </MDBox> 
                                                </Card> 
                                            </Form>)
                                        } 
                                        </Formik> 
                                    </MDBox> 
                                </MDBox> 
                                <MDBox /> 
                                <Footer company={{name : frf_y}} />
                            </ApplicationLayout>
                            )
                        } {
                            (ldr === 2) && ( 
                                <ApplicationLayout>
                                <DashboardNavbar / >
                                    <MDBox py = { 3 } mb = { 10 }>
                                        <Grid container justifyContent = "center" alignItems = "center" >
                                            <Grid item xs = { 12 } lg = { 12 } >
                                                <Card id = "basic-info" sx = {{ overflow: "visible" }}>
                                                <MDBox p = { 3 }>
                                                    <MDTypography variant = "h5"> { msg } </MDTypography> 
                                                </MDBox> 
                                                <MDBox pb = { 3 } px = { 3 }>
                                                    <Grid container spacing = { 3 } >
                                                        <Grid item xs = { 12 } sm = { 4 } >
                                                            <FormField label = "Control Number" InputProps={{ readOnly: true, }}  value = { ctn } onChange = { e => setCtn(e.target.value) } /> 
                                                        </Grid> 
                                                        <Grid item xs = { 12 } sm = { 4 } >
                                                            <FormField label = "Application Number" InputProps={{ readOnly: true, }}  value = { app } onChange = { e => setApp(e.target.value) } /> 
                                                        </Grid> 
                                                        <Grid item xs = { 12 } sm = { 4 } >
                                                            <FormField label = "Status" value={ sts } InputProps={{ readOnly: true, }}  onChange = { e => setSts(e.target.value) } /> 
                                                        </Grid> 
                                                    </Grid> 
                                                </MDBox> 
                                                <MDBox>
                                                    <Grid container spacing = { 1 } pb = { 3 } px = { 3 }>
                                                        <Grid item xs = { 12 } md = { 3 } /> 
                                                        <Grid item xs = { 12 } md = { 3 } />
                                                        <Grid item xs = { 12 } md = { 3 } /> 
                                                        <Grid item xs = { 12 } md = { 3 } >
                                                            <MDButton variant = "gradient" color = "info" fullWidth onClick = {() => setLdr(0) } >
                                                                { < FormattedMessage id = "bck_btn" / > } 
                                                            </MDButton> 
                                                        </Grid> 
                                                    </Grid> 
                                                </MDBox> 
                                                </Card> 
                                            </Grid> 
                                        </Grid> 
                                    </MDBox> 
                                </ApplicationLayout>
                            )
                        } {(ldr === 1) && (
                                <ApplicationLayout>
                                    <DashboardNavbar />
                                    <MDBox py = { 3 } mb = { 10 } >
                                        <Grid container justifyContent = "center" alignItems = "center" >
                                            <Grid item xs = { 12 } lg = { 12 } >
                                                <Card id = "basic-info" sx = {{ overflow: "visible" }} >
                                                <MDBox p = { 3 } >
                                                    <MDTypography variant = "h5" > Searching... </MDTypography> 
                                                </MDBox> 
                                                <MDBox pb = { 3 } px = { 3 } >
                                                    <Grid container spacing = { 3 } >
                                                        <Grid item xs = { 12 } sm = { 4 } /> 
                                                        <Grid item xs = { 12 } sm = { 4 } >
                                                            <ClockLoader loading = {(ldr == 1) } speedMultiplier = { 3 } size = { 150 } color = "#36d7b7" / >
                                                        </Grid> 
                                                        <Grid item xs = { 12 } sm = { 4 } /> 
                                                    </Grid> 
                                                </MDBox> 
                                                </Card> 
                                            </Grid> 
                                        </Grid> 
                                    </MDBox> 
                                </ApplicationLayout>)} 
                    </>);
                } else {
                    return <Navigate to = '/' / >
                }
            }
        }
            return ( <> { getUserSession() } </>);
        }

        export default ApplicationtatusComponent;