import  URL_ADDRESS from "apis-url/root-api-url";
import { GET_REGIONS, LOGIN_PAGE_SIGNIN,  GET_REGION_DISTRICTS, GET_ADMIN_AREAS,GET_WARD_INFOS, GET_STREET_VILLAGE, PROPERTY_CATEGORY, SUB_CATEGORY_API, 
    REGISTRATION_API, EXISTING_APPLIC, RESET_PASSWD_RQST, EXIST_APPL_GET, APPL_STATUS_GET, APPL_CREATION_API, CONFRM_APPLCATION,
    GET_RENEW_APPLIC, SUB_CAT_DETAIL_API, FORGOT_PASSWD_API,REGIS_API_ENDPNT,QUESTION_ANSWER_URL,TRA_TIN_VERIFICATION,REPORT_API_BY_USR_ID,
    PDF_BASE64_STRING, RNW_APPLICATION_API,DASHBOARD_APPLICANT, RESET_PASSWD_URL,
}  from "apis-url/constant-url";


export const GET_REGIONS_API         = URL_ADDRESS + GET_REGIONS;
export const FETCH_REGION_DISTRICTS  = URL_ADDRESS + GET_REGION_DISTRICTS;
export const FETCH_ADMINS_AREA       = URL_ADDRESS + GET_ADMIN_AREAS;
export const FETCH_WARDS             = URL_ADDRESS + GET_WARD_INFOS;
export const FETCH_VILLAGE_STREET    = URL_ADDRESS + GET_STREET_VILLAGE;
//3. PROPERTY CATEGORY
export const PROPERTY_CATEGORY_API   = URL_ADDRESS + PROPERTY_CATEGORY;
export const FETCH_SUB_CATEGORY_API  = URL_ADDRESS + SUB_CATEGORY_API;
// PASSWORD RESET
export const FORGOT_PASSWORD_API     = URL_ADDRESS + FORGOT_PASSWD_API;
//AUTHENTICATION APIS
export const RESET_USER_PASSWD       = URL_ADDRESS + RESET_PASSWD_RQST;
//REGISTRATION API
export const REGISTRATION_API_POST   = URL_ADDRESS + REGISTRATION_API;
//APPLICATIONS
export const EXISTING_APPLIC_API     = URL_ADDRESS + EXISTING_APPLIC;
export const EXIST_APPL_GET_API      = URL_ADDRESS + EXIST_APPL_GET;
export const APPL_STATUS_API_FETCH   = URL_ADDRESS + APPL_STATUS_GET;
export const APPL_CREATION_POST      = URL_ADDRESS + APPL_CREATION_API;
export const CONFRM_APPLCATION_API   = URL_ADDRESS + CONFRM_APPLCATION;
export const GET_RENEW_APPLIC_API    = URL_ADDRESS + GET_RENEW_APPLIC;
export const GET_SUB_CAT_DETAIL_API  = URL_ADDRESS + SUB_CAT_DETAIL_API;
//LOGIN
export const  LOGIN_PAGE_SIGNIN_API  = URL_ADDRESS + LOGIN_PAGE_SIGNIN;
//NIDA REGISTRATION
export const NIDA_REGIS_API          = URL_ADDRESS + REGIS_API_ENDPNT;
//NIDA QUESTION AND ANSWER API
export const NIDA_QNA                = URL_ADDRESS + QUESTION_ANSWER_URL;
//TRA TIN VERIFICATION API
export const TRA_TIN_API             = URL_ADDRESS + TRA_TIN_VERIFICATION;
//REPORT API TO GET INSPECTED STUCTURED REPORT
export const REQUEST_PDF_DOCMENT     = URL_ADDRESS + REPORT_API_BY_USR_ID;
//REPORT BASE64 STRING
export const GET_BASE64_STRING       = URL_ADDRESS + PDF_BASE64_STRING;

export const RNW_APPLN_API_POSTING   = URL_ADDRESS + RNW_APPLICATION_API;

// DASHBOARD API
export const DASHBOARD_API           = URL_ADDRESS + DASHBOARD_APPLICANT;

//TEST API
export const RESET_PASSWD_URL_API    = URL_ADDRESS + RESET_PASSWD_URL;


