import checkout from "ezimamoto/application/application-status/schemas/form";

const {
  formField: {
    applicationId,
    controlNumber,
  },
} = checkout;

const initialValues = {
  [applicationId.name]        : "",
  [controlNumber.name]        : "",
};

export default initialValues;
