
import { 
    GET_REGIONS_API, FETCH_REGION_DISTRICTS, FETCH_ADMINS_AREA, FETCH_WARDS, 
    FETCH_VILLAGE_STREET, PROPERTY_CATEGORY_API,FETCH_SUB_CATEGORY_API,
    EXIST_APPL_GET_API, APPL_STATUS_API_FETCH,
    EXISTING_APPLIC_API, APPL_CREATION_POST, CONFRM_APPLCATION_API,
    GET_RENEW_APPLIC_API, GET_SUB_CAT_DETAIL_API,TRA_TIN_API,RNW_APPLN_API_POSTING,DASHBOARD_API,
} from "apis-url";
import myAxios from "apis/jwt-axios";
import axios from "axios";


export const getAllRegions = (callBack, token) => {
    console.log(token);
    myAxios(GET_REGIONS_API, token).get(GET_REGIONS_API)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR REGIONS ***");
    });
} 

export const renewApplicationVersion1 = (callBack, postData, token) => {
    console.log(postData);
    myAxios(RNW_APPLN_API_POSTING, token).post(RNW_APPLN_API_POSTING, postData)
    .then(r => {
        console.log(r);
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR DISTRICTS ***");
    });
} 

export const getDistrictsForRegions = (callBack, postData, token) => {
    console.log(postData);
    myAxios(FETCH_REGION_DISTRICTS, token).post(FETCH_REGION_DISTRICTS, postData)
    .then(r => {
        console.log(r);
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR DISTRICTS ***");
    });
} 

export const getAdminAreasForDistricts = (callBack, postData, token) => {
    console.log(postData);
    myAxios(FETCH_ADMINS_AREA, token).post(FETCH_ADMINS_AREA, postData)
    .then(r => {
        console.log(r);
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR ADMIN AREAS ***");
    });
} 

export const getWardsForAdminsArea = (callBack, postData, token) => {
    myAxios(FETCH_WARDS, token).post(FETCH_WARDS, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR WARDS ***");
    });
} 

export const getStreetOrVillageByWard = (callBack, postData, token) => {
    myAxios(FETCH_VILLAGE_STREET, token).post(FETCH_VILLAGE_STREET, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR STREET / VILLAGE ***");
    });
} 

//4 PROPERTY CATEGORY
export const getPropertyCategory = (callback, postData, token) => {
    console.log(postData, token)
    myAxios(PROPERTY_CATEGORY_API, token)
    .post(PROPERTY_CATEGORY_API, postData)
    .then(r => {
        const response = r.data;
        console.log(response);
        if(!response.error && response.status === "OK"){
            callback(response.result)
        } else {
            callback([])
        }
    })
    .catch(error => {
        console.log(error.message)
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR PROPERTY CATEGORY ***")
    });
}


//
export const getSubcategoryProperty = (callBack, postData, token) => {
    console.log(postData, token)
    myAxios(FETCH_SUB_CATEGORY_API, token)
    .post(FETCH_SUB_CATEGORY_API, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR SUB CATEGORY ***");
    });
} 


export const fetchAllExistingApplications = (callBack, postData, token) => {
    myAxios(EXISTING_APPLIC_API, token)
    .post(EXISTING_APPLIC_API, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR EXISTING APPLICATION ***");
    });
} 

export const fetchSpecificExistingApplication = (callBack, postData, token) => {
    myAxios(EXIST_APPL_GET_API, token)
    .post(EXIST_APPL_GET_API, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR EXISTING SPECIFIC APPLICATION DETAILS ***");
    });
} 

export const fetchApplicationStatus = (callBack, postData, token) => {
    myAxios(APPL_STATUS_API_FETCH, token)
    .post(APPL_STATUS_API_FETCH, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response);
        } else {
            callBack(null);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR APPLICATION STATUS ***");
    });
} 

export const createApplicationApi = (callBack, postData, token) => {
    myAxios(APPL_CREATION_POST, token).post(APPL_CREATION_POST, postData)
    .then(r => {
        let response = r.data;
        console.log(response);
        if(!response.error && response.status === "OK"){
            callBack(response);
        } else {
            callBack(null);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR APPLICATION CREATION ***");
    });
} 

export const confirmApplicationRequest = (callBack, postData, token) => {
    myAxios(CONFRM_APPLCATION_API, token).post(CONFRM_APPLCATION_API, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response);
        } else {
            callBack(null);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR APPLICATION CONFIRMATION ***");
    });
} 

export const retrieveApplicationRenewal = (callBack, postData, token) => {
    myAxios(GET_RENEW_APPLIC_API, token)
    .post(GET_RENEW_APPLIC_API, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response);
        } else {
            callBack(null);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR APPLICATION RENEWAL ***");
    });
} 

export const retrieveSubCategoryApplicationDetail = (callBack, postData, token) => {
    myAxios(GET_SUB_CAT_DETAIL_API, token)
    .post(GET_SUB_CAT_DETAIL_API, postData)
    .then(r => {
        let response = r.data;
        console.log("============================================================");
        console.log(postData, GET_SUB_CAT_DETAIL_API, GET_SUB_CAT_DETAIL_API);
        console.log(r);
        console.log("============================================================");
        if(!response.error && response.status === "OK"){
            console.table(response.result);
            callBack(response.result);
        } else {
            callBack(null);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR SUB CATEGORY DETAIL ***");
    });
}

export const traTinVerificationApi = (callBack, postData, token) => {
    console.log(["Object Sent", postData]);
    myAxios(TRA_TIN_API, token).post(TRA_TIN_API, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            console.table(response.result);
            callBack(response.result);
        } else {
            callBack(null);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST TRA TIN VERIFICATION ***");
    });
}
export const getDashboard_API = (callBack, postData, token) => {
    myAxios(DASHBOARD_API, token)
    .post(DASHBOARD_API, postData)
    .then(r => {
        let response = r.data;
        if(!response.error && response.status === "OK"){
            callBack(response.result);
        } else {
            callBack([]);
        }
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("*** CLEARING REQUEST FOR DASHBOARD ***");
    });
} 