import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ClockLoader from "react-spinners/ClockLoader";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
import FormField from "ezimamoto/application/existing-application/ExstAppInfo/FormField";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';

const Status = () => {
  const [ controller ]                                  = useMaterialUIController();
  const { appliLoading, newappObj }                     = controller;
  const [cstNm, setCstnm]                               = useState("");
  const [cstphn, setCstphn]                             = useState("");
  const [cstpaddr, setCstpaddr]                         = useState("");
  const [bsnnm, setBsnnm]                               = useState("");
  const [bsnph, setBsnph]                               = useState("");
  const [idtyp, setIdtyp]                               = useState("");
  const [idnum, setIdnum]                               = useState("");
  const [regin, setRegin]                               = useState("");
  const [distr, setDistr]                               = useState("");
  const [admns, setAdmns]                               = useState("");
  const [ward, setWard]                                 = useState("");
  const [strvlg, setStrvlg]                             = useState("");
  const [block, setBlock]                               = useState("");
  const [hous, setHous]                                 = useState("");
  const [prpca, setPropc]                               = useState("");
  const [prpsb, setPrpsb]                               = useState("");
  const [prpsd, setPrpsd]                               = useState("");
  const [nmifn, setNmifn]                               = useState("");
  const [amntb, setAmntb]                               = useState("");
  const intl                                            = useIntl();

  const cstm_nm                                         = intl.formatMessage({id: 'cstm_nm'});
  const cstm_phn                                        = intl.formatMessage({id: 'cstm_phn'});
  const pstl_addr                                       = intl.formatMessage({id: 'pstl_addr'});
  const bssn_nm                                         = intl.formatMessage({id: 'bssn_nm'});
  const bssn_phn                                        = intl.formatMessage({id: 'bssn_phn'});
  const id_typ                                          = intl.formatMessage({id: 'id_typ'});
  const id_num                                          = intl.formatMessage({id: 'id_num'});
  const rgon                                            = intl.formatMessage({id: 'rgon'});
  const dstrt                                           = intl.formatMessage({id: 'dstrt'});
  const admn_area                                       = intl.formatMessage({id: 'admn_area'});
  const wrd                                             = intl.formatMessage({id: 'ward'});
  const strt_vllg                                       = intl.formatMessage({id: 'strt_vllg'});
  const blc_plt                                         = intl.formatMessage({id: 'blc_plt'});
  const hs_num                                          = intl.formatMessage({id: 'hs_num'});
  const prp_ctgry                                       = intl.formatMessage({id: 'prp_ctgry'});
  const prp_sbgry                                       = intl.formatMessage({id: 'prp_sbgry'});
  const prp_sbgdt                                       = intl.formatMessage({id: 'prp_sbgdt'});
  const num_itm                                         = intl.formatMessage({id: 'num_itm'});
  const amt_billd                                       = intl.formatMessage({id: 'amt_billd'});

  useEffect(() => {
    if(Object.getOwnPropertyNames(newappObj).length > 0){
      setCstnm((newappObj.customerName === null) ? "" : newappObj.customerName);
      setCstphn((newappObj.businessPhoneNumber === null) ? "" : newappObj.businessPhoneNumber);
      setCstpaddr((newappObj.postalAddress === null) ? "" : newappObj.postalAddress);
      setBsnnm((newappObj.businessName === null) ? "" : newappObj.businessName);
      setBsnph((newappObj.businessPhoneNumber === null) ? "" : newappObj.businessPhoneNumber);
      setIdtyp((newappObj.identityType === null) ? "" : newappObj.identityType);
      setIdnum((newappObj.identityNumber === null) ? "" : newappObj.identityNumber);
      setRegin((newappObj.region === null) ? "" : newappObj.region);
      setDistr((newappObj.district === null) ? "" : newappObj.district);
      setAdmns((newappObj.administrativeArea === null) ? "" : newappObj.administrativeArea);
      setWard((newappObj.ward === null) ? "" : newappObj.ward);
      setStrvlg((newappObj.street === null) ? "" : newappObj.street);
      setBlock((newappObj.blockPlot === null) ? "" : newappObj.blockPlot);
      setHous((newappObj.houseNumber === null) ? "" : newappObj.houseNumber);
      setPropc((newappObj.propertyCategory === null) ? "" : newappObj.propertyCategory);
      setPrpsb((newappObj.propertySubCategory === null) ? "" : newappObj.propertySubCategory);
      setPrpsd((newappObj.propertySubCategoryDetail === null) ? "" : newappObj.propertySubCategoryDetail);
      setNmifn((newappObj.numberOfItemsIfAny === null) ? "" : newappObj.numberOfItemsIfAny);
      setAmntb((newappObj.billedAmount === null) ? "" : newappObj.billedAmount);
    }
  }, [newappObj]);

  return (
    <>
    {appliLoading && (
      <MDBox>
        <MDTypography variant="h5" fontWeight="bold">
          {<FormattedMessage id="snd_rqst" />}
        </MDTypography>
        <MDBox mt={1.625}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4} >
              <ClockLoader loading={appliLoading} speedMultiplier={3} size={150}  color="#36d7b7" />
            </Grid>
            <Grid item xs={12} sm={4} />
          </Grid>
        </MDBox>
      </MDBox>
    )}
    {!appliLoading && (
        <MDBox py={1} mb={2}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={12}>
                        <MDBox p={3}>
                            <MDTypography variant="h5">{<FormattedMessage id="appl_infrm" />}</MDTypography>
                        </MDBox>
                        <MDBox pb={3} px={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} >
                                    <FormField label={cstm_nm} InputProps={{ readOnly: true, }} value={cstNm} onChange={e => setCstnm(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormField label={cstm_phn} InputProps={{ readOnly: true, }} value={cstphn} onChange={e => setCstphn(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormField label={pstl_addr} InputProps={{ readOnly: true, }} value={cstpaddr} onChange={e => setCstpaddr(e.target.value)} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} mt={2}>
                                    <FormField label={bssn_nm} value={bsnnm} InputProps={{ readOnly: true, }} onChange={e => setBsnnm(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={6} mt={2}>
                                    <FormField label={bssn_phn} value={bsnph} 
                                    
                                    InputProps={{
                                        readOnly: true,
                                    }}onChange={e => setBsnph(e.target.value)} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} mt={2}>
                                    <FormField label={id_typ} value={idtyp} 
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                    onChange={e => setIdtyp(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={6} mt={2}>
                                    <FormField label={id_num} value={idnum}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                    onChange={e => setIdnum(e.target.value)} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} mt={2}>
                                    <FormField label={rgon} value={regin} 
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                    onChange={e => setRegin(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={4} mt={2}>
                                    <FormField label={dstrt} value={distr} 
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                    onChange={e => setDistr(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={4} mt={2}>
                                    <FormField label={admn_area} 
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                    value={admns} onChange={e => setAdmns(e.target.value)} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3} mt={2}>
                                    <FormField label={wrd} 
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                    
                                    value={ward} onChange={e => setWard(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={3} mt={2}>
                                    <FormField label={strt_vllg} 
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    value={strvlg} onChange={e => setStrvlg(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={3} mt={2}>
                                    <FormField label={blc_plt} 
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    value={block} onChange={e => setBlock(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={3} mt={2}>
                                    <FormField label={hs_num}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={hous} onChange={e => setHous(e.target.value)} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} mt={2}>
                                    <FormField label={prp_ctgry} 
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    value={prpca} onChange={e => setPropc(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={4} mt={2}>
                                    <FormField label={prp_sbgry} 
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={prpsb} onChange={e => setPrpsb(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={4} mt={2}>
                                    <FormField label={prp_sbgdt} 
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={prpsd} onChange={e => setPrpsd(e.target.value)} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={10} sm={6} mt={1.3}>
                                    <FormField label={num_itm} 
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    value={nmifn} onChange={e => setNmifn(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={6} mt={1.3}>
                                    <FormField label={amt_billd} 
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                     value={amntb} onChange={e => setAmntb(e.target.value)} />
                                </Grid>
                            </Grid>
                        </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    )}
    </>
  );
}

export default Status;
