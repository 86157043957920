import PropTypes from "prop-types";
import { useState, useEffect,useRef } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSelect from "ezimamoto/common/MDSelect";
import { getPropertyCategory , getSubcategoryProperty, retrieveSubCategoryApplicationDetail } from "apis";
import { useMaterialUIController, selectedPropcatChange, setAllowMultiple, subCategoryDetailData,acceptTermsAndCondition } from "context";
import FormField from "ezimamoto/application/new-application/form/components/FormField";
import { FormattedMessage } from "react-intl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { pink } from '@mui/material/colors';
import { useIntl } from 'react-intl';



function PropertyCategory({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { propertyCategory, subCategory, numOfItems,subCategoryDetail, }     = formField;
  const { propertyCategory: propertyCategoryV, 
    subCategory: subCategoryV, numOfItems: numOfItemsV,
    subCategoryDetail: subCategoryDetailV } = values;

  //6. DECLARE PROPERTY CATEGORY STATE/// convert html static into dynamic use array
  const [propertycats, setPropertcats]         = useState([]);
  const [propertySub, setPropertysub]          = useState([]);
  const [subcatdetail, setSubcatdetail]        = useState([]);
  const [hassubdetl, setHassubdetl]            = useState(false);
  const [rendermult, setRendermult]            = useState(false);
  const [rendersub, setRendersub]              = useState(false);
  const [ controller, dispatch ]               = useMaterialUIController();
  const { review, newappObj, lang }            = controller;
  //const [controller]                                    = useMaterialUIController();
  const { loadRegistration }                            = controller;

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const intl                                                                                = useIntl();
  const terms_and_con                                                                       = intl.formatMessage({id: 'terms_and_con'});


const [message, setMessage] = useState('');
  //const checkbox = useRef();



  //7. TRIGGER EVENT FUNCTION (trigger data from server), callback to call data
  useEffect(() => {

    console.log(values);
    let postData = {};
    postData = {
      "propertyCategoryId": 0,
      "identityType": values.identityType1,
      "language": lang.toUpperCase()
    };
    let session        = sessionStorage.getItem("usn");
    let user           = JSON.parse(session);
    let token          = user.sessionToken;
    getPropertyCategory(callback, postData, token);
    // if(review){
    //   values.propertyCategory       = newappObj.propertyCategoryId;
    //   values.subCategory            = newappObj.propertySubCategoryId;
    //   values.numOfItems             = newappObj.numeberOfItemsIfAny;
    // }
  }, []);

  useEffect(() => {
    let propCategoryObject = "";
    if(((values.propertyCategory !== null) || (values.propertyCategory !== ""))){
      const selValue = values.propertyCategory.split(":");
      if(selValue.length === 3){
        propCategoryObject = selValue[0];
        selectedPropcatChange(dispatch, values.propertyCategory);
        setAllowMultiple(dispatch, (selValue[2] === 'true'))
        setRendermult((selValue[2] === 'true'));
        setRendersub((selValue[1] === 'true'));
      }
    }
    if(propCategoryObject !== ""){
      let session        = sessionStorage.getItem("usn");
      let user           = JSON.parse(session);
      let token          = user.sessionToken;
      const postData = { "propertyCategoryId": parseInt(propCategoryObject), "language": lang.toUpperCase() };
      getSubcategoryProperty(callback1, postData, token);
    }
  }, [values.propertyCategory]);

  useEffect(() => {
    let subPropCategoryObject = "";
    if(((values.subCategory !== null) || (values.subCategory !== ""))){
      const selValue = values.subCategory.split(":");
      if(selValue.length === 2){
        subPropCategoryObject = selValue[0];
        subCategoryDetailData(dispatch, (selValue[1] === 'true'));
        setHassubdetl((selValue[1] === 'true'));
      }
    }
    if(values.subCategory !== ""){
      let session        = sessionStorage.getItem("usn");
      let user           = JSON.parse(session);
      let token          = user.sessionToken;
      const postData = { "subCategoryId": parseInt(subPropCategoryObject), "language": lang.toUpperCase()
    };
      retrieveSubCategoryApplicationDetail(callback2, postData, token);
    }
  }, [values.subCategory]);

  const callback  = data => setPropertcats(data);
  const callback1 = data => setPropertysub(data);
  const callback2 = data => setSubcatdetail(data);

  const changeTermsAndConditionRdx = event => {
    if(event.target.checked){
      acceptTermsAndCondition(dispatch, true);
    } else {
      acceptTermsAndCondition(dispatch, false);
    }
  }

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {<FormattedMessage id="prp_ctgry" />}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <MDSelect
              type={propertyCategory.type}
              label={<FormattedMessage id="prp_ctgry" />}
              name={propertyCategory.name}
              value={propertyCategoryV}
              options={propertycats}
              error={errors.propertyCategory && touched.propertyCategory}
              success={(propertyCategoryV.length > 0 && !errors.propertyCategory).toString()}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {rendersub && (<MDSelect
              type={subCategory.type}
              label={<FormattedMessage id="prp_sbgry" />}
              name={subCategory.name}
              value={subCategoryV}
              options={propertySub}
              placeholder={subCategory.placeholder}
              error={errors.subCategory && touched.subCategory}
              success={(subCategoryV.length > 0 && !errors.subCategory).toString()}
            />)}
          </Grid>
        </Grid>
        <MDBox pt={3} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
            {rendermult && (
              <FormField
                type={numOfItems.type}
                label={<FormattedMessage id="num_itm" />}
                name={numOfItems.name}
                value={numOfItemsV}
                inputProps={{maxLength :10}}
                placeholder={numOfItems.placeholder}
                error={errors.numOfItems && touched.numOfItems}
                success={(numOfItemsV.length > 0 && !errors.numOfItems)}
              />)}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {hassubdetl && (
                <MDSelect
                  type={subCategoryDetail.type}
                  label={<FormattedMessage id="prp_sbgdt" />}
                  name={subCategoryDetail.name}
                  value={subCategoryDetailV}
                  options={subcatdetail}
                  error={errors.subCategoryDetail && touched.subCategoryDetail}
                  success={(subCategoryDetailV.length > 0 && !errors.subCategoryDetail).toString()}
                />
              )}
            </Grid>
          </Grid>
         
          {!loadRegistration && (
            <>
              <FormGroup>
                  <FormControlLabel control={ <Checkbox
                    {...label}
                    onChange={changeTermsAndConditionRdx}
                    sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                        color: pink[600],
                      },
                    }} 
                  />} label={terms_and_con} />
              </FormGroup>
                <Typography variant="caption" display="block" gutterBottom sx={{ fontWeight: 'bold', fontSize: 12 }}>
                <FormattedMessage id="IAG" />
              </Typography>
            </>)}
      </MDBox>
    </MDBox>
  );
}
PropertyCategory.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default PropertyCategory;


