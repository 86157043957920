import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";

const RegistrationLayout = ({ image, children }) => {
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
       
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto"  >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%" >
          <Grid item xs={11} sm={10} md={10} lg={9} xl={8}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

RegistrationLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default RegistrationLayout;
