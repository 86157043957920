import { Form, Formik } from "formik";
import form from "ezimamoto/Password/ResetPassword/Schema/form";
import initialValues from "ezimamoto/Password/ResetPassword/Schema/initialValues";
import validation from "ezimamoto/Password/ResetPassword/Schema/validation";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/Flag_of_Tanzania.png";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import PasswordResetCover from "ezimamoto/Password/ResetPassword/password-reset-form";
import { useMaterialUIController, setSendPasswordResetRequest } from "context";
import { forgotPassword } from "apis";


const ResetForm = () => {
    const [controller, dispatch]          = useMaterialUIController();
    const { loadingClock, lang }          = controller;
    const { formId, formField }           = form;
    const  navigate                       = useNavigate();
    const sleep                           = ms => new Promise(resolve => setTimeout(resolve, ms));
    const  handleSubmit                   = async values => {
        console.log(values)
        setSendPasswordResetRequest(dispatch, !loadingClock);
        forgotPassword(callBack, values);
        await sleep(3000);
        navigate("/");
        setSendPasswordResetRequest(dispatch, false);
    }

    const validationLogic = () => {
      if(lang === "en"){
        return validation[0];
      } else {
        return validation[1];
      }
    }


    const callBack = data => {
      // alert(data.status + " " + data.message);
    }

    return (
        <BasicLayout coverHeight="50vh" image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-1}
            py={-1}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant={loadingClock ? "h5" : "h3"} fontWeight="medium" color="white" mt={1}>
              {(loadingClock  && (lang === "en")) && ("Sending Password Into Your Email...")}
              {(loadingClock  && (lang === "sw")) && ("Inatuma Nywila Mpya kwenye Barua pepe yako...")}
              {(!loadingClock && (lang === "en")) && ("Reset Password")}
              {(!loadingClock && (lang === "sw")) && ("Tengeneza Upya Nywila")}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
        <Formik
            initialValues={initialValues}
            validationSchema={validationLogic()}
            onSubmit={handleSubmit}
        >
        {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
                <PasswordResetCover formData={{values, errors, touched, isSubmitting, formField}} />
            </Form>)}
      </Formik>
      </MDBox>
      </Card>
    </BasicLayout>
    );

}

export default ResetForm;