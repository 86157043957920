import form from "ezimamoto/customer-login/Schema/form";

const {
  formField: {
    email,
    password,
  },
} = form;

const initialValues = {
  [email.name]           : "",
  [password.name]        : "",
};

export default initialValues;
