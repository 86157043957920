import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import FormField from "ezimamoto/common/FormField";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';
import samplePDF1 from "swahili.pdf";
import samplePDF2 from "usermanual.pdf";
import { useMaterialUIController} from "context";

  const LoginForm   = ({ formData }) => { 
  const [controller, dispatch]            = useMaterialUIController();
  const { lang }                          = controller;
  const { formField, values, errors, touched}            = formData;
  const { email, password }                              = formField;
  const { email: emailValue, password: passwordValue,}   = values;
  const  navigate                                        = useNavigate();
  const  registration                                    = () => navigate ("/ezimamoto/customer-registration")
  //const  instruction                                    = () => navigate ("/ezimamoto/instructionlogin")
  const  [rememberMe, setRememberMe]                     = useState(false);
  const  handleSetRememberMe                             = () => setRememberMe(!rememberMe);
 
  
  // const sleep                                         = ms => new Promise(resolve => setTimeout(resolve, ms));
  
  const intl                                             = useIntl();

  useEffect(() => {
    if(rememberMe){
      navigate ("/ezimamoto/password/reset-password");
    }
  }, [rememberMe, navigate]);
  
  const emallbl                                          = intl.formatMessage({id: 'eml_lbl'});
  const pswdlbl                                          = intl.formatMessage({id: 'pssw_lbl'});
 
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(samplePDF2).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = samplePDF2;
        alink.click();
      })
    })
  }
  const onButtonClick2 = () => {
    // using Java Script method to get PDF file
    fetch(samplePDF1).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = samplePDF1;
        alink.click();
      })
    })
  }
  if(lang === "en"){
 return (
          <MDBox pt={-1} pb={6} px={3} mb={-4}>
            <MDBox mb={1}>
              <FormField
                type={email.type}
                label={emallbl} 
                name={email.name}
                value={emailValue}
                error={errors.email && touched.email}
                inputProps={{ autoComplete: "" }}
                success={emailValue.length > 0 && !errors.email}
              />
            </MDBox>
            <MDBox mb={1}>
              <FormField 
                type={password.type}
                label={pswdlbl}
                name={password.name}
                inputProps={{ autoComplete: "" }}
                value={passwordValue}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordValue.length > 0 && !errors.password}
                
                />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={11}>
              {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} /> */}
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                {<FormattedMessage id="fgt_pwd" />}
              </MDTypography>

               
            </MDBox>
           
               <MDBox display="flex" alignItems="center" ml={8} mt={1}>
              
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={onButtonClick}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                {<FormattedMessage id="instr_login" />}
              </MDTypography>
                </MDBox> 
                  
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 0, mb: 0 }}>
                <Grid item xs={5}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={registration}>
                  {<FormattedMessage id="rgst_lbl" />}
                  </MDButton>
                  
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  <MDButton variant="gradient" type="submit" color="info" fullWidth>
                  {<FormattedMessage  id="lgn_lbl" />}
                  </MDButton>
                </Grid>
            </Grid> 
        </MDBox>    
  )}
  else if(lang === "sw"){
    return(
      <><MDBox pt={-1} pb={6} px={3} mb={-4}>
        <MDBox mb={1}>
          <FormField
            type={email.type}
            label={emallbl}
            name={email.name}
            value={emailValue}
            error={errors.email && touched.email}
            inputProps={{ autoComplete: "" }}
            success={emailValue.length > 0 && !errors.email} />
        </MDBox>
        <MDBox mb={1}>
          <FormField
            type={password.type}
            label={pswdlbl}
            name={password.name}
            inputProps={{ autoComplete: "" }}
            value={passwordValue}
            placeholder={password.placeholder}
            error={errors.password && touched.password}
            success={passwordValue.length > 0 && !errors.password} />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={11}>
          {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} /> */}
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            {<FormattedMessage id="fgt_pwd" />}
          </MDTypography>


        </MDBox>

        <MDBox display="flex" alignItems="center" ml={8} mt={1}>

          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={onButtonClick2}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            {<FormattedMessage id="instr_login" />}
          </MDTypography>
        </MDBox>

        <Grid container spacing={2} justifyContent="center" sx={{ mt: 0, mb: 0 }}>
          <Grid item xs={5}>
            <MDButton variant="gradient" color="info" fullWidth onClick={registration}>
              {<FormattedMessage id="rgst_lbl" />}
            </MDButton>

          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <MDButton variant="gradient" type="submit" color="info" fullWidth>
              {<FormattedMessage id="lgn_lbl" />}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox></>
 ); 
 

  }
LoginForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}
 
  }; 
 
export default LoginForm;

