import form from "ezimamoto/GlobalChangePassword/Schema/form";

const {
  formField: {
    newpassword, confirmpassword, userId, code,
  },
} = form;

const initialValues         = {
  [newpassword.name]        : "",
  [confirmpassword.name]    : "",
  [userId.name]             : "",
  [code.name]               : "",
};

export default initialValues;
