import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import UserInfo from "ezimamoto/application/new-application/form/components/UserInfo";
import Location from "ezimamoto/application/new-application/form/components/Location";
import PropertyCategory from "ezimamoto/application/new-application/form/components/PropertyCategory";
import Status from "ezimamoto/application/new-application/form/components/Status";
import Verification from "ezimamoto/application/new-application/form/components/Verification";
import validations from "ezimamoto/application/new-application/form/schemas/validations";
import form from "ezimamoto/application/new-application/form/schemas/form";
import initialValues from "ezimamoto/application/new-application/form/schemas/initialValues";
import { createApplicationApi, confirmApplicationRequest } from "apis";
import { useMaterialUIController, acceptTermsAndConditionReg, submitApplicationRequest, storeObjectRequest, reviewApplication, movingRegistrationStepper, setShowChangeMenuAction } from "context";
import MDSnackbar from "components/MDSnackbar";
import { FormattedMessage } from "react-intl";
//import "Css/index.css";



const getSteps = (isSwahili) => isSwahili ? ["Hakiki Chochote Anachoomba Mteja ", "Taarifa Za Biashara Za Mteja Alizonazo ", "Eneo/Jengo La Biashara Inapopatikana", "Aina ya Jengo/Eneo La Biashara ", "Hali ya Ombi La Biashara Lilipofikia"] : ["Customer Business Verification", "User Business Information Importing", "Business Location Where Located", "Property Category For Business", "Status For the Business of the User"];


const getStepContent = (stepIndex, formData) => {
    switch (stepIndex) {
        case 0:
            return <Verification formData = { formData } />;
        case 1:
            return <UserInfo formData = { formData } />;
        case 2:
            return <Location formData = { formData } />;
        case 3:
            return <PropertyCategory formData = { formData } />;
        case 4:
            return <Status /> ;
        default:
            return null;
    }
}

const NewApplicationForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [indexStep, setIndexStep] = useState(0);
    const [controller, dispatch] = useMaterialUIController();
    const { appliLoading, newappObj, propertyCat, review, subcatdetail, lang, termsAndCondition } = controller;
    const { formId, formField } = form;
    const steps = getSteps((lang === "sw"));
    const isLastStep = (activeStep === (steps.length - 1));
    const [successSB, setSuccessSB] = useState(false);
    const [content, setContent] = useState("");
    const [header, setHeader] = useState("");
    const [errorSB, setErrorSB] = useState(false);

    const properBlockPlotName = values => {
        if (((values.block !== "") && (values.block !== null)) && ((values.plot !== "") && (values.plot !== null))) {
            return (values.block + "/" + values.plot);
        } else {
            return "";
        }
    }

    const callBack = data => {
        console.log("DATA AFTER BEING SAVED", data);
        submitApplicationRequest(dispatch, false);
        storeObjectRequest(dispatch, data.result);
    }

    const handleBack = () => {
        if (isLastStep) {
            setActiveStep(0);
            reviewApplication(dispatch, !review);
        } else {
            setActiveStep(activeStep - 1);
        }
    }

    const callBack1 = data => {
        console.log(data);
        setContent(data.message);
        setHeader(data.result.applicationNumber);
        if (data.error) {
            setErrorSB(true);
        } else {
            storeObjectRequest(dispatch, {});
            reviewApplication(dispatch, false);
            setSuccessSB(true);
            setActiveStep(0);
        }
    }

    useEffect(() => {
        if (((propertyCat !== null) || (propertyCat !== ""))) {
            const selValue = propertyCat.split(":");
            if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'false') && !subcatdetail) {
                setIndexStep(1);
            } else if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'false') && subcatdetail) {
                setIndexStep(4);
            } else if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'true') && !subcatdetail) {
                setIndexStep(2);
            } else if ((selValue.length === 3) && (selValue[1] === 'true') && (selValue[2] === 'true') && subcatdetail) {
                setIndexStep(5);
            } else if ((selValue.length === 3) && (selValue[1] === 'false') && (selValue[2] === 'true')) {
                setIndexStep(3);
            }
        }

        setShowChangeMenuAction(dispatch, true);
    }, [propertyCat, subcatdetail]);

    const renderSuccessSB = ( <
        MDSnackbar color = "success"
        icon = "check"
        title = { header }
        content = { content }
        dateTime = { new Date().toDateString() }
        open = { successSB }
        onClose = {
            () => setSuccessSB(false) }
        close = {
            () => setSuccessSB(false) }
        bgWhite /
        >
    );

    const renderErrorSB = ( <
        MDSnackbar color = "error"
        icon = "warning"
        title = { header }
        content = { content }
        dateTime = { new Date().toDateString() }
        open = { errorSB }
        onClose = {
            () => setErrorSB(false) }
        close = {
            () => setErrorSB(false) }
        bgWhite /
        >
    );

    const activateValidationSchema = index => {
        if((activeStep === 0) && (lang === "en")) {
            return validations[activeStep][0];
        } else if((activeStep === 0) && (lang === "sw")){
            return validations[activeStep][1];
        } else if((activeStep === 1) && (lang === "en")){
            return validations[activeStep][0];
        } else if((activeStep === 1) && (lang === "sw")){
            return validations[activeStep][1];
        } else if((activeStep === 2) && (lang === "en")){
            return validations[activeStep][0];
        } else if((activeStep === 2) && (lang === "sw")){
            return validations[activeStep][1];
        } else if ((activeStep === 3) && (lang === "en")) {
            return validations[activeStep][0][index];
        } else if((activeStep === 3) && (lang === "sw")){
            return validations[activeStep][1][index];
        } else {
            return validations[activeStep];
        }
    }


    const submitForm = (values, actions) => {
        let session                                       = sessionStorage.getItem("usn");
        let usrObj                                        = JSON.parse(session);
        let token                                         = usrObj.sessionToken;
        let applObject                                    = {};
        applObject.applicantId                            = usrObj.userId;
        applObject.applicantEmail                         = usrObj.email;
        applObject.applicantCapacity                      = values.applicantCapacity;
        applObject.identityType                           = values.identityType1;
        applObject.identityNumber                         = values.identityNumber;
        applObject.customerType                           = values.customType;
        applObject.customerName                           = values.customerName;
        applObject.customerPhoneNumber                    = values.phoneNumber;
        applObject.customerEmail                          = values.customerEmail;
        applObject.customerPostalAddress                  = values.postalAddress;
        applObject.businessEmail                          = values.bussnEmail;
        applObject.businessPostalAddress                  = values.bussnAddr;
        applObject.businessName                           = values.bussinessName;
        applObject.businessPhoneNumber                    = values.bussinessPhone;
        applObject.regionId                               = values.region;
        applObject.districtId                             = values.district;
        applObject.administrativeAreaId                   = values.admin_area;
        applObject.wardId                                 = values.ward;
        applObject.streetId                               = values.street_village;
        applObject.plot                                   = values.plot;
        applObject.block                                  = values.block;
        applObject.plotBlock                              = properBlockPlotName(values);
        applObject.houseNumber                            = values.houseNumber;
        applObject.propertyCategoryId                     = splitStringSelected(values.propertyCategory);
        applObject.propertySubCategoryId                  = splitStringSelected(values.subCategory);
        applObject.propertySubCategoryDetailId            = splitStringSelected(values.subCategoryDetail);
        applObject.numberOfItemsIfAny                     = values.numOfItems;
        applObject.sendCorrespondenceTo                   = values.feedbackCorrespondent;
        applObject.language                               = lang.toUpperCase();
        console.log(JSON.stringify(applObject, null, 2));
        submitApplicationRequest(dispatch, !appliLoading);
        setActiveStep(4);
        createApplicationApi(callBack, applObject, token);
        actions.setSubmitting(false);
        actions.resetForm();
    };

    const isNotNullOrBlank = str => {
        let trimmed = '';
        if (typeof str === 'string' || str instanceof String) {
            trimmed = str.trim();
        } else {
            trimmed = str;
        }
        return ((str !== null) && (trimmed !== ''));
    }
      
    const splitStringSelected = value => {
        let splittedString = '';
        if (typeof value === 'string' || value instanceof String) {
            splittedString = value.split(":")[0];
        } else {
            splittedString = value;
        }
        return isNotNullOrBlank(value) ? splittedString : null;
    }

    const handleSubmit = (values, actions) => {
        console.log(JSON.stringify(values, null, 2));
        if(values.identityType1 === "TIN"){
            let regisStepperWrapper      = {};
            regisStepperWrapper.formInfo = values;
            regisStepperWrapper.loading  = true;
            movingRegistrationStepper(dispatch, regisStepperWrapper);
        }
        if (isLastStep) {
            if (Object.getOwnPropertyNames(newappObj).length > 0) {
                let session                                       = sessionStorage.getItem("usn");
                let usrObj                                        = JSON.parse(session);
                let token                                         = usrObj.sessionToken;
                const confirmData = { "applicationId": newappObj.selfInspectionApplicationId };
                confirmApplicationRequest(callBack1, confirmData,token);
            } else {
                console.log("ERROR OCCUR");
            }
        } else {
            if ((activeStep === (steps.length - 2))) {
                submitForm(values, actions);
            } else {
                setActiveStep(activeStep + 1);
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        }
    };

    return ( 
    <>
        <MDBox py = {1} mb = {2} height = "60vh">
            <Grid container justifyContent = "center" alignItems = "center" sx = {{ height: "100%", mt: 3 }}>
                <Grid item xs = {12} sg = {12} >
                    <Formik initialValues = {initialValues} validationSchema = {activateValidationSchema(indexStep)} onSubmit = {handleSubmit} >
                        {
                            ({ values, errors, touched, isSubmitting }) => ( 
                            <Form id = { formId } autoComplete = "off">
                                  <Card sx = {{ height: "100%" }}>
                                      
                                  <MDBox mx = { 2 } mt = {-3 } >
                                    <Stepper activeStep = { activeStep } alternativeLabel>
                                     {steps.map(label => (<Step key = { label } >
                                        <StepLabel>
                                                    { label }  
                                                    </StepLabel >
                                            </Step>))} 
                                           </Stepper>
                                        </MDBox>
                                    <MDBox p = {3}>
                                        <MDBox> {getStepContent(activeStep, {values, touched, formField, errors,})} 
                                            <MDBox mt = { 1 } py = { 2 } width = "100%" display = "flex" justifyContent = "space-between" > 
                                                {(activeStep === 0) ? (<MDBox />) : (
                                                    <MDButton disabled = { appliLoading } variant = "gradient" color = "info" onClick = { handleBack }>
                                                        {isLastStep ? <FormattedMessage id="rvw_btn" /> : <FormattedMessage id="bck_btn" /> }
                                                    </MDButton>)} 
                                                    <MDButton disabled = { (isSubmitting || ((activeStep === (steps.length - 2)) && !termsAndCondition))  } type = "submit" variant = "gradient" color = "info" >
                                                    {isLastStep ? <FormattedMessage id="cfm_btn" /> : <FormattedMessage id="nxt_btn" /> } 
                                                </MDButton> 
                                            </MDBox> 
                                        </MDBox> 
                                    </MDBox> 
                                </Card> 
                            </Form>)
                        } 
                    </Formik> 
                    { renderSuccessSB }
                    { renderErrorSB } 
                </Grid> 
            </Grid> 
        </MDBox>
    </>
    );
}

export default NewApplicationForm;