import * as Yup from "yup";
import checkout from "ezimamoto/application/renew-application/renew/form/schemas/form";

const { formField: { customerName, phoneNumber, bussinessName, bussinessPhone, 
  region, district, admin_area, ward, street_village, propertyCategory1, subCategory1, numOfItems, subCategoryDetail, 
  customerEmail, bussnEmail,feedbackCorrespondent,applicantCapacity, }, } = checkout;
const validations = [
  [
    Yup.object().shape({
      [applicantCapacity.name]            : Yup.string().required(applicantCapacity.errorMsg),
    }),
    Yup.object().shape({
      [applicantCapacity.name]            : Yup.string().required(applicantCapacity.errorMsgSw),
    })
  ],
  [
    Yup.object().shape({
      [customerName.name]                   : Yup.string().required(customerName.errorMsg),
      [phoneNumber.name]                    : Yup.string()
                                                .required('Phone Number is Required')
                                                .test('len', 'Must be exactly 10 numbers', val => {
                                                      if(val){
                                                        return val.length === 10;
                                                      } else {
                                                        return false;
                                                      }
                                                })
                                                .test('startsWithZero', 'Zero Required At Beginning', val => {
                                                  if(val){
                                                    return /^0[0-9].*$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                })
                                                .test('digitOnly', 'Only Digits Are Required', val => {
                                                  if(val){
                                                    return /^\d+$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                }),
      [bussinessName.name]                  : Yup.string().required(bussinessName.errorMsg),
      [bussinessPhone.name]                 : Yup.string().required('Phone Number is Required')
                                                .test('len', 'Must be exactly 10 numbers', val => {
                                                      if(val){
                                                        return val.length === 10;
                                                      } else {
                                                        return false;
                                                      }
                                                })
                                                .test('startsWithZero', 'Zero Required At Beginning', val => {
                                                  if(val){
                                                    return /^0[0-9].*$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                })
                                                .test('digitOnly', 'Only Digits Are Required', val => {
                                                  if(val){
                                                    return /^\d+$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                }),
      [customerEmail.name]                    : Yup.string().email(customerEmail.invalidMsg).required(customerEmail.errorMsg), 
      [bussnEmail.name]                       : Yup.string().email(bussnEmail.invalidMsg).required(bussnEmail.errorMsg),
      [feedbackCorrespondent.name]            : Yup.string().required(feedbackCorrespondent.errorMsg),
      
                                              }),
    Yup.object().shape({
        [customerName.name]                   : Yup.string().required(customerName.errorMsgSw),
        [phoneNumber.name]                    : Yup.string()
                                                .required('Nambari ya simu Inahitajika')
                                                .test('len', 'Tunahitaji tarakimu 10 tu', val => {
                                                      if(val){
                                                        return (val.length === 10);
                                                      } else {
                                                        return false;
                                                      }
                                                })
                                                .test('startsWithZero', 'Namba ianze na sifuri', val => {
                                                  if(val){
                                                    return /^0[0-9].*$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                })
                                                .test('digitOnly', 'Hakikisha ni Tarakimu tu', val => {
                                                  if(val){
                                                    return /^\d+$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                }),
      [bussinessName.name]                  : Yup.string().required(bussinessName.errorMsgSw),
      [bussinessPhone.name]                 : Yup.string().required('Namba ya simu ya Biashara inahitajika')
                                                .test('len', 'Tunahitaji tarakimu 10 tu', val => {
                                                      if(val){
                                                        return val.length === 10;
                                                      } else {
                                                        return false;
                                                      }
                                                })
                                                .test('startsWithZero', 'Namba ianze na sifuri', val => {
                                                  if(val){
                                                    return /^0[0-9].*$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                })
                                                .test('digitOnly', 'Hakikisha ni Tarakimu tu', val => {
                                                  if(val){
                                                    return /^\d+$/.test(val);
                                                  } else {
                                                    return false;
                                                  }
                                                }),
      [customerEmail.name]                    : Yup.string().email(customerEmail.invalidMsgSw).required(customerEmail.errorMsgSw),  
      [bussnEmail.name]                       : Yup.string().email(bussnEmail.invalidMsgSw).required(bussnEmail.errorMsgSw),  
      [feedbackCorrespondent.name]            : Yup.string().required(feedbackCorrespondent.errorMsgSw),
                                              }),                                  
  ],
  [
    Yup.object().shape({
      [region.name]                         : Yup.string().required(region.errorMsg),
      [district.name]                       : Yup.string().required(district.errorMsg),
      [admin_area.name]                     : Yup.string().required(admin_area.errorMsg),
      [ward.name]                           : Yup.string().required(ward.errorMsg),
      [street_village.name]                 : Yup.string().required(street_village.errorMsg),
    }),
    Yup.object().shape({
      [region.name]                         : Yup.string().required(region.errorMsgSw),
      [district.name]                       : Yup.string().required(district.errorMsgSw),
      [admin_area.name]                     : Yup.string().required(admin_area.errorMsgSw),
      [ward.name]                           : Yup.string().required(ward.errorMsgSw),
      [street_village.name]                 : Yup.string().required(street_village.errorMsgSw),
    })
  ],
  [
    [
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsg),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsg),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsg),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsg),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsg),
        [numOfItems.name]                     : Yup.number().required(numOfItems.errorMsg).positive(numOfItems.positivenum).integer(),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsg),
        [numOfItems.name]                     : Yup.number().required(numOfItems.errorMsg).positive(numOfItems.positivenum).integer(),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsg),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsg),
        [subCategoryDetail.name]              : Yup.string().required(subCategoryDetail.errorMsg),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsg),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsg),
        [subCategoryDetail.name]              : Yup.string().required(subCategoryDetail.errorMsg),
        [numOfItems.name]                     : Yup.number().required(numOfItems.errorMsg).positive(numOfItems.positivenum).integer(),
      }),
    ],
    [
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsgSw),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsgSw),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsgSw),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsgSw),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsgSw),
        [numOfItems.name]                     : Yup.number().required(numOfItems.errorMsgSw).positive(numOfItems.positivenumSw).integer(),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsgSw),
        [numOfItems.name]                     : Yup.number().required(numOfItems.errorMsgSw).positive(numOfItems.positivenumSw).integer(),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsgSw),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsgSw),
        [subCategoryDetail.name]              : Yup.string().required(subCategoryDetail.errorMsgSw),
      }),
      Yup.object().shape({
        [propertyCategory1.name]               : Yup.string().required(propertyCategory1.errorMsgSw),
        [subCategory1.name]                    : Yup.string().required(subCategory1.errorMsgSw),
        [subCategoryDetail.name]              : Yup.string().required(subCategoryDetail.errorMsgSw),
        [numOfItems.name]                     : Yup.number().required(numOfItems.errorMsgSw).positive(numOfItems.positivenumSw).integer(),
      }),
    ]
  ],
];

export default validations;
