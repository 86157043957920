import ClockLoader from "react-spinners/ClockLoader";
import PropTypes from "prop-types";
import FormField from "ezimamoto/common/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { useMaterialUIController } from "context";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';


const ChangePasswordformular                                   = ({ formData }) => {
    const [controller]                                         = useMaterialUIController();
    const { dispayLoader }                                     = controller;   
    const { formField, values, errors, touched, isSubmitting } = formData;
    const { oldpassword, newpassword, confirmpassword}         = formField;
    const { oldpassword: oldpasswordValue, }                   = values;
    const { newpassword: newpasswordValue, }                   = values;
    const { confirmpassword: confirmpasswordValue, }           = values;
    const intl                                                 = useIntl();

    const pwd_oldlabel                                         = intl.formatMessage({id: 'pwd_oldlabel'});
    const pwd_nwlabel                                          = intl.formatMessage({id: 'pwd_nwlabel'});
    const pwd_cnflabel                                         = intl.formatMessage({id: 'pwd_cnflabel'});

    

    return (
      <>
      {!dispayLoader && (
        <>
          <MDBox pl={4} pt={3} pr={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormField
                  type={oldpassword.type}
                  label={pwd_oldlabel}
                  name={oldpassword.name}
                  value={oldpasswordValue}
                  error={errors.oldpassword && touched.oldpassword}
                  success={oldpasswordValue.length > 0 && !errors.oldpassword}
                  inputProps={{ autoComplete: "" }}
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormField
                  type={newpassword.type}
                  label={pwd_nwlabel}
                  name={newpassword.name}
                  value={newpasswordValue}
                  error={errors.newpassword && touched.newpassword}
                  success={newpasswordValue.length > 0 && !errors.newpassword}
                  inputProps={{ autoComplete: "" }}
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormField
                  type={confirmpassword.type}
                  label={pwd_cnflabel}
                  name={confirmpassword.name}
                  value={confirmpasswordValue}
                  error={errors.confirmpassword && touched.confirmpassword}
                  success={confirmpasswordValue.length > 0 && !errors.confirmpassword}
                  inputProps={{ autoComplete: "" }}
                  fullWidth 
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={4} justifyContent="right">
                  <MDButton
                    disabled={isSubmitting}
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    {<FormattedMessage id="btn_pwdchd" />}
                  </MDButton>
                  <MDBox pb={4} />
              </Grid>  
            </Grid>
            
          </MDBox>
          </>)}
            
            {dispayLoader && (
             <Grid container spacing={2}>
             <Grid item xs={4} md={4}>
             </Grid>
             <Grid item xs={4} md={4}>
               <ClockLoader loading={dispayLoader} speedMultiplier={3} size={90}  color="#36d7b7"/>
             </Grid>
             <Grid item xs={4} md={4}>
             </Grid>
             </Grid>
         )}
       </>
    )
}

                   
ChangePasswordformular.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChangePasswordformular;
