/* eslint-disable react/jsx-no-undef */
import PropTypes from "prop-types";
// import { useIntl } from 'react-intl';
// @mui material components


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, }) {

  // const intl                                                                                 = useIntl();
  // const frf_y                                                                                = intl.formatMessage({id: 'frf_y'});

  const {  name } = company;
  const { size } = typography;
  const footerStyle = {
      position:  'absolute',
      left:      0,
      bottom:    0,
      right:     0
  };

  return (
    <div style={footerStyle}>
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="right"
      alignItems="right"
      px={1.5}
      
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="right"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
        
       
      >
        <MDTypography variant="button" fontWeight="medium" color="secondary">
        &copy; {new Date().getFullYear()}                         
        </MDTypography>
      
          <MDTypography variant="button" fontWeight="medium" color="secondary" >
            &nbsp;{name}&nbsp;
          </MDTypography>
       
      </MDBox>
    </MDBox>
    </div>
  );
}

// Setting default values for the props of Footer
// Footer.defaultProps = {
//   company: { name: useIntl().formatMessage({id: 'frf_y'}) }
// };

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string)
};

export default Footer;
