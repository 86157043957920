import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ChangePasswordformular from "ezimamoto/GlobalChangePassword/change-password-form";
import { Form, Formik } from "formik";
import form from "ezimamoto/GlobalChangePassword/Schema/form";
import initialValues from "ezimamoto/GlobalChangePassword/Schema/initialvalues";
import { useMaterialUIController, setDisplayLoaderRequest, setShowChangeMenuAction } from "context";
import { resetGlobalPasswordRequest } from "apis";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import formContxt from "ezimamoto/GlobalChangePassword/Schema/form";
import BasicLayout from "layouts/authentication/components/BasicLayout/BasicLayout";
import bgImage from "assets/images/Flag_of_Tanzania.png";



const ChangePasswordComponent = () =>    {
  const { formId, formField }              = form;
  const [controller, dispatch]             = useMaterialUIController();
  const { dispayLoader, lang }             = controller;
  const [successSB, setSuccessSB]          = useState(false);
  const [content, setContent]              = useState("");
  const [header, setHeader]                = useState("");
  const [errorSB, setErrorSB]              = useState(false);
  const strong_pswd                        = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)+(?=.*[-+_!@#$%^&*., ?]).+$";
  const rawData                            = window.location.search;
  const encodedBase64Data                  = encodeURIComponent(rawData);
  const searchParams                       = new URLSearchParams(window.location.search);

  useEffect(() => {
    setShowChangeMenuAction(dispatch, true);
    console.log("-------------------------------------------");
    console.log(encodedBase64Data);
    console.log(searchParams);
    initialValues.userId = searchParams.get("userId");
    initialValues.code   = searchParams.get("code").split(' ').join('+');
    console.log(initialValues);
    console.log("-------------------------------------------");
  }, []);
  
  const { formField: { newpassword, confirmpassword, }, }     = formContxt;
  const  handleSubmit                      = async values => {
    if(checkIfValuesAreNullOrEmpty(values.userId)){
      setErrorSB(true);
      setSuccessSB(false);
      setContent("Missing Required Infos");
      setHeader("Invalid Operation");
    } else if(checkIfValuesAreNullOrEmpty(values.code)) {
      setErrorSB(true);
      setSuccessSB(false);
      setContent("Missing Required Infos");
      setHeader("Invalid Operation");
    } else {
      resetGlobalPasswordRequest(callBack, values);
      console.log("-------------------------------------------");
      console.log(values);
      console.log("-------------------------------------------");
    }
  }


  const checkIfValuesAreNullOrEmpty = value => ((value === "") || (value === undefined) || (value === null))
  

  const validations = Yup.object().shape({
    [newpassword.name] : Yup.string().required((lang === "en") ? newpassword.errorMsg : newpassword.errorMsgSw).matches(strong_pswd, ((lang === "en") ? newpassword.weakMsg : newpassword.weakMsgSw)),
    [confirmpassword.name] : Yup.string().required((lang === "en") ? confirmpassword.errorMsg : confirmpassword.errorMsgSw).matches(strong_pswd,((lang === "en") ? confirmpassword.weakMsg : confirmpassword.weakMsgSw)).oneOf([Yup.ref("newpassword"), null], (lang === "en") ? newpassword.invalidMsg : newpassword.invalidMsgSw),
  });
    

  const callBack = data => {
    console.log("-------------------------------------------");
    console.log(data);
    console.log("-------------------------------------------");
    setContent(data.message);
    setHeader(data.status);
    if(data.error){
        setSuccessSB(false);
        setErrorSB(true);
    } else {
        setSuccessSB(true);
        setErrorSB(false);
    }
  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={header}
      content={content}
      dateTime={new Date().toDateString()}
      open={successSB}
      onClose={() => setSuccessSB(false)}
      close={() => setSuccessSB(false)}
      bgWhite
    />
);

const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={header}
      content={content}
      dateTime={new Date().toDateString()}
      open={errorSB}
      onClose={() => setErrorSB(false)}
      close={() => setErrorSB(false)}
      bgWhite
    />
);

  const ContentSession = () => {
          return (        
            <>
              <Card>
                <MDBox
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-2}
                  p={3}
                  mb={1}
                  py={1}
                  textAlign="center"
                >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {<FormattedMessage id="reset_password" />}
                </MDTypography>
                </MDBox>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form id={formId}>
                        <ChangePasswordformular formData={{ formField, values, errors, touched, isSubmitting }} />
                    </Form>
                  )}
                </Formik>
              </Card>
              {renderSuccessSB}
              {renderErrorSB}
            </>);
  }

  return (<BasicLayout image={bgImage}>{ContentSession()}</BasicLayout>);
}

export default ChangePasswordComponent;
