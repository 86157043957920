/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-undef */
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import samplePDF from "./usermanual.pdf";
import swahiliPDF from "./swahili.pdf";
import { FormattedMessage } from "react-intl";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import MDButton from "components/MDButton";
import { useMaterialUIController} from "context";
import Grid from "@mui/material/Grid";

import LinearProgress from '@mui/material/LinearProgress';

import "Css/index.css";
// eslint-disable-next-line no-whitespace-before-property
pdfjs .GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs .version}/pdf.worker.min.js`;

const App = () => {
 
  const [numPages, setNumPages]           = useState();
  const [pageNumber, setPageNumber]       = useState(1);
  const [controller, dispatch]            = useMaterialUIController();
  const { lang }                          = controller;

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(samplePDF).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = samplePDF;
        alink.click();
      })
    })
  }
  const onButtonClick2 = () => {
    // using Java Script method to get PDF file
    fetch(swahiliPDF).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = swahiliPDF;
        alink.click();
      })
    })
  }


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );
   
    if (lang === "en") {
  return (
    <><DashboardLayout>
      <DashboardNavbar />
     
      <div className='h2'>
      
        <Document  
          className="col-md-12 pdfViewer canvasWrapper"
          file= {samplePDF}
          onLoadSuccess={onDocumentLoadSuccess}
          
          loading={(
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
              <Grid item md={2}>
                <LinearProgress color="success" />
              </Grid>
            </Grid>
          )}
        >
        
        <nav >
           <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item md={2}>
                  <MDButton variant="gradient" color="white" fullWidth onClick={goToPrevPage}>
                        {<FormattedMessage id="pre"/>}
                  </MDButton>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={2} />
                <Grid item xs={2} />
                <Grid item xs={2} />
                <Grid item xs={2}>
                <MDButton variant="gradient" color="white" fullWidth onClick={onButtonClick}>
                        {<FormattedMessage id="down"/>}
                  </MDButton>
                  
                </Grid>
            
            <Grid container spacing={2} justifyContent="center" sx={{ mt: -7, mb: -1, mx:-3 }}>
                <Grid item md={2}>
                <MDButton variant="gradient" color="white" fullWidth onClick={goToNextPage}>
                    {<FormattedMessage id="nex"/>}
                  </MDButton>
                </Grid>
                </Grid>
            </Grid>
        </nav>

       
        
          <Page pageNumber={pageNumber} />
        </Document>
        </div>
      
    </DashboardLayout></>
  );
  }else{
    return (
      <><DashboardLayout>
        <DashboardNavbar />
       
        <div className='h2'>
        
          <Document  
            className="col-md-12"
            file= {swahiliPDF}
            onLoadSuccess={onDocumentLoadSuccess}
            
            loading={(
              <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item md={2}>
                  <LinearProgress color="success" />
                </Grid>
              </Grid>
            )}
           
          >
           <nav >
           <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item md={2}>
                  <MDButton variant="gradient" color="white" fullWidth onClick={goToPrevPage}>
                        {<FormattedMessage id="pre"/>}
                  </MDButton>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={2} />
                <Grid item xs={2} />
                <Grid item xs={2} />
                <Grid item xs={2}>
                <MDButton variant="gradient" color="white" fullWidth onClick={onButtonClick2}>
                        {<FormattedMessage id="down"/>}
                  </MDButton>
                  
                </Grid>
            
            <Grid container spacing={2} justifyContent="center" sx={{ mt: -7, mb: -1 }}>
                <Grid item md={2}>
                <MDButton variant="gradient" color="white" fullWidth onClick={goToNextPage}>
                    {<FormattedMessage id="nex"/>}
                  </MDButton>
                </Grid>
                </Grid>
            </Grid>
        </nav>
          
            <Page pageNumber={pageNumber} />
            
          </Document>
         
          </div>
        
      </DashboardLayout>
      
      </>
    );
  }
};

export default App;
