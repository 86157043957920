import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "ezimamoto/application/renew-application/renew/form/components/FormField";
import { useMaterialUIController } from "context";
import  { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import MDSelect from "ezimamoto/common/MDSelect";


function UserInfo({ formData }) {
  const { formField, values, errors, touched }                             = formData;
  const { customerName, postalAddress, phoneNumber, bussinessName, bussinessPhone, customerEmail, bussnEmail, bussnAddr,feedbackCorrespondent , } = formField;
  const [ controller ]                                                     = useMaterialUIController();
  const { review, lang, newappObj, renewobject }                           = controller;
  const [opts, setOpts]                                                    = useState([]);
  const { customerName: customerNameV, postalAddress: postalAddressV, 
    phoneNumber: phoneNumberV, bussinessName: bussinessNameV, 
    bussinessPhone: bussinessPhoneV, customerEmail : customerEmailV, bussnEmail: bussnEmailV, bussnAddr: bussnAddrV, feedbackCorrespondent: feedbackCorrespondentV } = values;
    const intl                                                                      = useIntl();
    const options1                                                                  = [{ value : "1",   label: "Applicant"},        { value: "2",   label: "Customer" }, { value: "3",   label: "Bussiness" }];
    const options2                                                                  = [{ value : "1",   label: "Muombaji"},        { value: "2",   label: "Mteja" }, { value: "3",   label: "Biashara/Eneo La Biashara" }];
    const cstm_nm                                                                   = intl.formatMessage({id: 'cstm_nm'});
    const pstl_addr                                                                 = intl.formatMessage({id: 'pstl_addr'});
    const phn_numbr                                                                 = intl.formatMessage({id: 'phn_numbr'});
    const bssn_nm                                                                   = intl.formatMessage({id: 'bssn_nm'});
    const bssn_phn                                                                  = intl.formatMessage({id: 'bssn_phn'});
    const cust_eml_addr                                                             = intl.formatMessage({id: 'cust_eml_addr'});
    const bssn_eml_addr                                                             = intl.formatMessage({id: 'bssn_eml_addr'});
    const bssn_pst_addr                                                             = intl.formatMessage({id: 'bssn_pst_addr'});
    const corrsp_prsnnl                                                             = intl.formatMessage({id: 'corrsp_prsnnl'});
    const re_appli_form                                                             = intl.formatMessage({id: 're_appli_form'});
    

    useEffect(() => {
      if(lang === "en"){
        setOpts(options1);
      } else {
        setOpts(options2);
      }
    }, [lang]);

    useEffect(() => {
      let session = sessionStorage.getItem("usn");
      console.log(session);
      if(session !== null){
        let usrObj             = JSON.parse(session);
        values.customerName    = (usrObj.companyName === "") ? "NO INFO" : usrObj.companyName;
      }
      if(Object.keys(renewobject).length > 0){
        values.customerName    = renewobject.customerName;
        values.postalAddress   = renewobject.postalAddress;
        values.phoneNumber     = renewobject.phoneNumber;
        values.bussinessName   = renewobject.businessName;
        values.bussinessPhone  = renewobject.businessPhoneNumber;
        values.bussnAddr       = renewobject.postalAddress;
        values.customerEmail   = "NO INFO";
        values.bussnEmail      = "NO INFO";
       }
       console.log("session");
       console.log(session);
       if(session !== null){
         let usrObj             = JSON.parse(session);
         values.customerName    = (usrObj.fullName === "" || usrObj.fullName === null) ? "" : usrObj.fullName;
       }
    }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">{re_appli_form}</MDTypography>
      </MDBox>
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={customerName.type}
                label={cstm_nm}
                name={customerName.name}
                value={customerNameV}
                InputProps={{
                  readOnly:  true,
                }}
                placeholder={customerName.placeholder}
                error={errors.customerName && touched.customerName}
                success={customerNameV.length > 0 && !errors.customerName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={bussnAddr.type}
                label={bssn_pst_addr}
                name={bussnAddr.name}
                value={bussnAddrV}
                success={bussnAddrV.length > 0 && !errors.bussnAddr}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                type={postalAddress.type}
                label={pstl_addr}
                name={postalAddress.name}
                value={postalAddressV}
                placeholder={postalAddress.placeholder}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={phoneNumber.type}
                label={phn_numbr}
                name={phoneNumber.name}
                inputProps={{maxLength :10}}
                value={phoneNumberV}
                placeholder={phoneNumber.placeholder}
                error={errors.phoneNumber && touched.phoneNumber}
                success={phoneNumberV.length > 0 && !errors.phoneNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={customerEmail.type}
                label={cust_eml_addr}
                name={customerEmail.name}
                value={customerEmailV}
                placeholder={customerEmail.placeholder}
                error={errors.customerEmail && touched.customerEmail}
                success={customerEmailV.length > 0 && !errors.customerEmail}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                type={bussinessName.type}
                label={bssn_nm}
                name={bussinessName.name}
                value={bussinessNameV}
                placeholder={bussinessName.placeholder}
                error={errors.bussinessName && touched.bussinessName}
                success={bussinessNameV.length > 0 && !errors.bussinessName}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={bussinessPhone.type}
                label={bssn_phn}
                name={bussinessPhone.name}
                inputProps={{maxLength :10}}
                value={!review ? bussinessPhoneV : newappObj.businessPhoneNumber }
                placeholder={bussinessPhone.placeholder}
                error={errors.bussinessPhone && touched.bussinessPhone}
                success={bussinessPhoneV.length > 0 && !errors.bussinessPhone}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                  type={bussnEmail.type}
                  label={bssn_eml_addr}
                  name={bussnEmail.name}
                  error={errors.bussnEmail && touched.bussnEmail}
                  success={bussnEmailV.length > 0 && !errors.bussnEmail}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <MDSelect
                  type={feedbackCorrespondent.type}
                  label={corrsp_prsnnl}
                  name={feedbackCorrespondent.name}
                  value={feedbackCorrespondentV}
                  options={opts}
                  error={errors.feedbackCorrespondent && touched.feedbackCorrespondent}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
            </Grid>
          </Grid>
        </MDBox>
    </MDBox>
  );
}

UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
