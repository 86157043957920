import CustomerLogin from "ezimamoto/customer-login";
import CustomerRegistration from "ezimamoto/customer-registration";
import ResetPassword from "ezimamoto/Password/ResetPassword";
import ChangePassword from "ezimamoto/Password/ChangePassword";
import InspectionReports from "ezimamoto/Reports/InspectionReports";
import DashBoardPage from "ezimamoto/DashBoardPage";
import NewApplication from "ezimamoto/application/new-application";
import ApplicationStatus from "ezimamoto/application/application-status";
import ExistingApplication from "ezimamoto/application/existing-application";
import RenewApplication from "ezimamoto/application/renew-application";
import Icon from "@mui/material/Icon";
import Instructions from "ezimamoto/instructions";
import GlobalChangePassword from "ezimamoto/GlobalChangePassword";
//import Instruct from "ezimamoto/instructionlogin";
// import CertificateReports from "ezimamoto/Reports/CertificateReports";

const routes = [
  { type: "title", title: "Menu", key: "title-pages" },
  {
    type: "collapse",
    name: "DashBoard",
    key: "dash-board-page",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        icon: <Icon fontSize="medium">dashboard</Icon>,
        name: "DashBoardPage",
        key: "dash-board-page",
        route: "/dash-board-page",
        component: <DashBoardPage/>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "New Application",
        key: "new-application",
        route: "/applications/new-application",
        component: <NewApplication />,
      },
      {
        name: "Renew Application",
        key: "renew-application",
        route: "/applications/renew-application",
        component: <RenewApplication />,
      },
      {
        name: "Application Status",
        key: "application-status",
        route: "/applications/application-status",
        component: <ApplicationStatus />,
      },
      {
        name: "Existing Application",
        key: "existing-application",
        route: "/applications/existing-application",
        component: <ExistingApplication />,
      },
      
    ],
  },
  {
    type: "collapse",
    name: "Downloads",
    key: "inspection-reports",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
                  
      {
        name: "Downloads",
        key: "inspection-reports",
        route: "/inspection-reports",
        component: <InspectionReports/>,
      },
      ],
        },
        
         {
          type: "collapse",
          name: "Instructions",
          key: "instructions",
          icon: <Icon fontSize="medium">view_in_ar</Icon>,
          collapse: [
            {
              name: "Instructions",
              key: "instructions",
              route: "/instructions",
              component: <Instructions />,
            },
           
          ],
        },
        {
          type: "collapse",
          name: "Change Password",
          key: "password",
          icon: <Icon fontSize="medium">image</Icon>,
          collapse: [
            {
              name: "Change Password",
              key: "change-password",
              route: "/password/change-password",
              component: <ChangePassword/>,
            },
          ],
        },
        {
          name: "Registrations",
          key: "Registration-Page",
          route: "/ezimamoto/customer-registration",
          component: <CustomerRegistration/>,
        },
        {
          name: "Reset Password",
          key: "reset-password",
          route: "/ezimamoto/password/reset-password",
          component: <ResetPassword/>,
        },
        {
          //name: "instruct",
          //key: "instructionlogin",
          //route: "/ezimamoto/instructionlogin",
          //component: <Instruct/>,
        },
        {
          name: "Login",
          key: "customer-login",
          route: "/ezimamoto/customer-login",
          component: <CustomerLogin/>,
        },
        {
          name: "Login",
          key: "login-page",
          route: "/",
          component: <CustomerLogin/>,
        },
        {
          name: "DashBoard",
          key: "dash-board-page",
          route: "/ezimamoto/dash-board-page",
          component: <DashBoardPage/>,
        },
        {
          name: "Change Password 1",
          key: "global-change-password",
          route: "/ezimamoto/global-change-password/reset-password",
          component: <GlobalChangePassword/>,
          customKey: null,
        },
        { type: "divider", key: "divider-1" },
];

export default routes;
