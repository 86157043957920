import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import { useMaterialUIController } from "context";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import Avatar from '@mui/material/Avatar';
import { orange } from '@mui/material/colors';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';


const VerifiedUser = ({ formData }) => {
    const [controller]                                                  = useMaterialUIController();
    const { formField, values, errors, touched }                        = formData;
    const { sex1 }                                                      = formField;
    const { sex1: sexV }                                                = values;
    const intl                                                          = useIntl();

    const [fname,setFname]                                              = useState("");                                                        
    const [mname,setMname]                                              = useState("");                                                         
    const [lname,setLname]                                              = useState("");                                                         
                                                           
    const { registrationObj }                                           = controller;

    useEffect(() => {
      setFname(registrationObj.userInfo.firstname);                                                        
      setMname(registrationObj.userInfo.middlename);                                                         
      setLname(registrationObj.userInfo.surname);                                                         
   
    }, [values, registrationObj]);
  
    return (
        <MDBox>
            <Grid container justifyContent = "center" alignItems = "center">
                <Grid item xs = { 12 } g = { 12 } >
                    <MDBox p = { 3 } >
                        <MDBox>
                            <Grid item>
                                <MDBox mb={1}>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <MDBox p={1}>
                                                <Grid container spacing={3} alignItems="center">
                                                   
                                                    <Grid item>
                                                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                                                            <MDTypography variant="h5" fontWeight="medium">
                                                                {fname + " " + mname + " " + lname}
                                                            </MDTypography>
                                                            <MDTypography variant="button" color="text" fontWeight="medium">
                                                                {<FormattedMessage id="verified" />}
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item sx={{ ml: "auto" }}>
                                                        <MDBox
                                                            display="flex"
                                                            justifyContent={{ md: "flex-end" }}
                                                            alignItems="center"
                                                            lineHeight={1}
                                                        >
                                                            <Avatar sx={{ bgcolor: "blue" }}>
                                                                <VerifiedIcon />
                                                            </Avatar>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Grid>
                                        <Grid item>
                                            <MDBox p={1}>
                                               
                                            </MDBox>
                                            <MDBox component="div" pb={1} px={1}>
                                                <Grid container spacing={1}>
                                                   
                                                   
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container spacing={2}>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container spacing={3}>
                                                                   
                                                                   
                                                                  
                                                                   
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Grid>
                        </MDBox>
                    </MDBox> 
                </Grid> 
            </Grid>
        </MDBox>
    );
}

export default VerifiedUser;