import React from 'react';
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import FormField from "ezimamoto/application/application-status/FormField";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';

const ApplicationStatus =  ({ formData }) => {

    const { formField, values, errors, touched, isSubmitting }              = formData;
    const { applicationId, controlNumber, }                                 = formField;
    const { applicationId: applicationIdV, controlNumber: controlNumberV, } = values;
    const intl                                                              = useIntl();
    const appl_id                                                           = intl.formatMessage({id: 'appl_id'});
    const ctn_number                                                        = intl.formatMessage({id: 'ctn_number'});
    

    return (
        <MDBox pt={6} pb={3}>
            <MDTypography variant="h5" fontWeight="bold">
                {<FormattedMessage id="app_status" />}
            </MDTypography>
            <MDBox mt={1.625}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={applicationId.type}
                            label={appl_id}
                            name={applicationId.name}
                            value={applicationIdV}
                            placeholder={applicationId.placeholder}
                            error={errors.applicationId && touched.applicationId}
                            success={applicationIdV.length > 0 && !errors.applicationId}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            type={controlNumber.type}
                            label={ctn_number}
                            name={controlNumber.name}
                            value={controlNumberV}
                            placeholder={controlNumber.placeholder}
                            error={errors.controlNumber && touched.controlNumber}
                            success={controlNumberV.length > 0 && !errors.controlNumber}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={3}>
                        <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                        >
                            {<FormattedMessage id="app_sts_btn" />}
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

ApplicationStatus.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ApplicationStatus;