import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSelect from "ezimamoto/common/MDSelect";
import { getPropertyCategory , getSubcategoryProperty } from "apis";
import { useMaterialUIController,   setAllowMultipleRenew, setHasSubCategoryDetail, } from "context";
import FormField from "ezimamoto/application/renew-application/renew/form/components/FormField";
import { useIntl } from 'react-intl';

function PropertyCategory({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { propertyCategory1, subCategory1, numOfItems,subCategoryDetail, }     = formField;
  const { propertyCategory1: propertyCategoryV, 
    subCategory1: subCategoryV, numOfItems: numOfItemsV,
    subCategoryDetail: subCategoryDetailV } = values;

  //6. DECLARE PROPERTY CATEGORY STATE/// convert html static into dynamic use array
  const [propertycats, setPropertcats]                        = useState([]);
  const [propertySub, setPropertysub]                         = useState([]);
  const [subcatdetail, setSubcatdetail]                       = useState([]);
  const [ controller, dispatch ]                              = useMaterialUIController();
  const intl                                                  = useIntl();
  const { renewobject, allowMultiply, hasSubCategoryDetail, lang }  = controller;
  //7. TRIGGER EVENT FUNCTION (trigger data from server), callback to call data
  useEffect(() => {
    let session                  = sessionStorage.getItem("usn");
    let user                     = JSON.parse(session);
    let token                    = user.sessionToken;
    let postData                 = {};
    postData.propertyCategoryId  = 0;
    postData.identityType        = values.identityType1;
    postData.language            = lang.toUpperCase();
    getPropertyCategory(callback, postData, token);
  }, []);


  useEffect(() => {
    if(renewobject.propertyCategoryId !== ""){
      let session        = sessionStorage.getItem("usn");
      let user           = JSON.parse(session);
      let token          = user.sessionToken;
      const postData = { "propertyCategoryId": parseInt(renewobject.propertyCategoryId), "language": lang.toUpperCase() };
      getSubcategoryProperty(callback1, postData, token);
    }
  }, [values.subCategory1]);

  // useEffect(() => {

  // }, [renewobject.propertyCategoryId]);

  // useEffect(() => {
  //   let subPropCategoryObject = "";
  //   if(((values.subCategory !== null) || (values.subCategory !== ""))){
  //     const selValue = values.subCategory.split(":");
  //     if(selValue.length === 2){
  //       subPropCategoryObject = selValue[0];
  //       subCategoryDetailData(dispatch, (selValue[1] === 'true'));
  //     }
  //   }
  //   if(values.subCategory !== ""){
  //     const postData = { "subCategoryId": parseInt(subPropCategoryObject) };
  //     retrieveSubCategoryApplicationDetail(callback2, postData);
  //   }
  // }, [values.subCategory]);
  
  const callback  = data => setPropertcats(data);

  const callback1 = data => {
    setPropertysub(data);
    data.forEach((value, index, array) => {
      if((value.subCategoryId === renewobject.propertySubCategoryId)){
        setAllowMultipleRenew(dispatch, value.allowMultiply);
        setHasSubCategoryDetail(dispatch, value.hasSubCategoryDetail);
      }
    });
    if(Object.keys(renewobject).length > 0){
      values.propertyCategory1       = renewobject.propertyCategoryId;
      values.subCategory1            = renewobject.propertySubCategoryId;
      values.numOfItems              = ((renewobject.numeberOfItemsIfAny === null) ? 0 : renewobject.numeberOfItemsIfAny);
    }
  }
  // const callback2 = data => setSubcatdetail(data);

  const prp_ctgry                                       = intl.formatMessage({id: 'prp_ctgry'});
  const prp_sbgry                                       = intl.formatMessage({id: 'prp_sbgry'});
  const num_itm                                         = intl.formatMessage({id: 'num_itm'});
  const prp_sbgdt                                       = intl.formatMessage({id: 'prp_sbgdt'});

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        {prp_ctgry}
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <MDSelect
              type={propertyCategory1.type}
              label={prp_ctgry}
              name={propertyCategory1.name}
              value={propertyCategoryV}
              options={propertycats}
              error={errors.propertyCategory && touched.propertyCategory}
              success={(propertyCategoryV.length > 0 && !errors.propertyCategory).toString()}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDSelect
              type={subCategory1.type}
              label={prp_sbgry}
              name={subCategory1.name}
              value={subCategoryV}
              options={propertySub}
              placeholder={subCategory1.placeholder}
              error={errors.subCategory1 && touched.subCategory1}
              success={(subCategoryV.length > 0 && !errors.subCategory1).toString()}
              disabled={true}
            />
          </Grid>
        </Grid>
        <MDBox pt={3} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              {allowMultiply && (
                <FormField
                  type={numOfItems.type}
                  label={num_itm}
                  name={numOfItems.name}
                  value={numOfItemsV}
                  inputProps={{maxLength :10}}
                  placeholder={numOfItems.placeholder}
                  error={errors.numOfItems && touched.numOfItems}
                  success={(numOfItemsV.length > 0 && !errors.numOfItems)}
                  disabled={true}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {hasSubCategoryDetail && (
                <MDSelect
                  type={subCategoryDetail.type}
                  label={prp_sbgdt}
                  name={subCategoryDetail.name}
                  value={subCategoryDetailV}
                  options={subcatdetail}
                  error={errors.subCategoryDetail && touched.subCategoryDetail}
                  success={(subCategoryDetailV.length > 0 && !errors.subCategoryDetail).toString()}
                />
              )}
            </Grid>
          </Grid>
      </MDBox>
    </MDBox>
  );
}

PropertyCategory.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PropertyCategory;
