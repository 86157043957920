import React from 'react';
import  { Navigate } from 'react-router-dom'
import RenewNewApplicationForm from "ezimamoto/application/renew-application/renew/form";

function RenewApplication() {
    const getUserSession = () => {
        let session = sessionStorage.getItem("usn");
        if((session === null) || (session === "")){
            return <Navigate to='/'  />
        } else {
            let user = JSON.parse(session);
            if((typeof user === 'object') && (user !== null)) {
               return (        
                <>
                    <RenewNewApplicationForm />
                </>);
            } else {
                return <Navigate to='/'  />
            }
        }
    }
    return (<>{getUserSession()}</>);
}

export default RenewApplication;