import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "ezimamoto/application/new-application/form/components/FormField";
import { useMaterialUIController, movingRegistrationStepper } from "context";
import  { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ClockLoader from "react-spinners/ClockLoader";
import { traTinVerificationApi }  from "apis";
import { useIntl } from 'react-intl';
import MDSelect from "ezimamoto/common/MDSelect";


function UserInfo({ formData }) {
  const { formField, values, errors, touched }                                      = formData;
  const { customerName, postalAddress, phoneNumber, bussinessName, bussinessPhone, customerEmail, bussnEmail, bussnAddr,feedbackCorrespondent , } = formField;
  const [ controller , dispatch]                                                    = useMaterialUIController();
  const { review, lang, newappObj, newregistration }                                = controller;
  const [opts, setOpts]                                                             = useState([]);
  const { customerName: customerNameV, postalAddress: postalAddressV, 
    phoneNumber: phoneNumberV, bussinessName: bussinessNameV, 
    bussinessPhone: bussinessPhoneV, customerEmail : customerEmailV, bussnEmail: bussnEmailV, bussnAddr: bussnAddrV, feedbackCorrespondent: feedbackCorrespondentV } = values;
    const intl                                                                      = useIntl();
    const options1                                                                  = [{ value : "1",   label: "Applicant"},        { value: "2",   label: "Customer" }, { value: "3",   label: "Bussiness" }];
    const options2                                                                  = [{ value : "1",   label: "Muombaji"},        { value: "2",   label: "Mteja" }, { value: "3",   label: "Biashara/Eneo La Biashara" }];

    const cstm_nm                                                                   = intl.formatMessage({id: 'cstm_nm'});
    const pstl_addr                                                                 = intl.formatMessage({id: 'pstl_addr'});
    const phn_numbr                                                                 = intl.formatMessage({id: 'phn_numbr'});
    const bssn_nm                                                                   = intl.formatMessage({id: 'bssn_nm'});
    const bssn_phn                                                                  = intl.formatMessage({id: 'bssn_phn'});
    const cust_eml_addr                                                             = intl.formatMessage({id: 'cust_eml_addr'});
    const bssn_eml_addr                                                             = intl.formatMessage({id: 'bssn_eml_addr'});
    const bssn_pst_addr                                                             = intl.formatMessage({id: 'bssn_pst_addr'});
    const corrsp_prsnnl                                                             = intl.formatMessage({id: 'corrsp_prsnnl'});
    
    

    useEffect(() => {
      if(lang === "en"){
        setOpts(options1);
      } else {
        setOpts(options2);
      }
    }, [lang]);

    useEffect(() => {
      let session = sessionStorage.getItem("usn");
      if(session !== null){
        let usrObj             = JSON.parse(session);
        values.customerName    = (usrObj.companyName === "") ? "NO INFO" : usrObj.companyName;
      }
      if(review){
        values.customerName          = newappObj.customerName;
        values.postalAddress         = newappObj.postalAddress;
        values.phoneNumber           = newappObj.phoneNumber;
        values.bussinessName         = newappObj.businessName;
        values.bussinessPhone        = newappObj.businessPhoneNumber;
        values.bussnEmail            = newappObj.businessEmail;
        values.bussnAddr             = newappObj.businessPostalAddress;
        values.customerEmail         = newappObj.customerEmail;
        values.feedbackCorrespondent = newappObj.correspondent;
      }

      if((JSON.stringify(newregistration) !== '{}') && (newregistration.formInfo.identityType1 === "TIN")){
        let postData = {
          "customerType":   newregistration.formInfo.customType,
          "identityType":   newregistration.formInfo.identityType1,
          "identityNumber": newregistration.formInfo.identityNumber
        };
        let user           = JSON.parse(session);
        let token          = user.sessionToken;
        traTinVerificationApi(callBack, postData, token);
      } else {
        console.log("session");
        console.log(session);
        if(session !== null){
          let usrObj             = JSON.parse(session);
          values.customerName    = (usrObj.fullName === "" || usrObj.fullName === null) ? "" : usrObj.fullName;
        }
      }
    }, []);

    const callBack = response => {
      let regisStepperWrapper      = {};
      regisStepperWrapper.loading  = false;
      values.customerName          = response.customerName;
      movingRegistrationStepper(dispatch, regisStepperWrapper);
    }

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">{<FormattedMessage id="appl_form" />}</MDTypography>
      </MDBox>
      {(!newregistration.loading) && (
        <MDBox mt={1.625}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <FormField
                type={customerName.type}
                label={cstm_nm}
                name={customerName.name}
                value={customerNameV}
                InputProps={{
                  readOnly:  true,
                }}
                placeholder={customerName.placeholder}
                error={errors.customerName && touched.customerName}
                success={customerNameV.length > 0 && !errors.customerName}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                type={postalAddress.type}
                label={pstl_addr}
                name={postalAddress.name}
                value={postalAddressV}
                placeholder={postalAddress.placeholder}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={phoneNumber.type}
                label={phn_numbr}
                name={phoneNumber.name}
                inputProps={{maxLength :10}}
                value={phoneNumberV}
                placeholder={phoneNumber.placeholder}
                error={errors.phoneNumber && touched.phoneNumber}
                success={phoneNumberV.length > 0 && !errors.phoneNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={customerEmail.type}
                label={cust_eml_addr}
                name={customerEmail.name}
                value={customerEmailV}
                placeholder={customerEmail.placeholder}
                error={errors.customerEmail && touched.customerEmail}
                success={customerEmailV.length > 0 && !errors.customerEmail}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
            <FormField
                type={bussnAddr.type}
                label={bssn_pst_addr}
                name={bussnAddr.name}
                value={bussnAddrV}
                success={bussnAddrV.length > 0 && !errors.bussnAddr}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={bussinessPhone.type}
                label={bssn_phn}
                name={bussinessPhone.name}
                inputProps={{maxLength :10}}
                value={!review ? bussinessPhoneV : newappObj.businessPhoneNumber }
                placeholder={bussinessPhone.placeholder}
                error={errors.bussinessPhone && touched.bussinessPhone}
                success={bussinessPhoneV.length > 0 && !errors.bussinessPhone}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={bussnEmail.type}
                label={bssn_eml_addr}
                name={bussnEmail.name}
                error={errors.bussnEmail && touched.bussnEmail}
                success={bussnEmailV.length > 0 && !errors.bussnEmail}
              />
            </Grid>
          </Grid>


          <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
              <FormField
                type={bussinessName.type}
                label={bssn_nm}
                name={bussinessName.name}
                value={bussinessNameV}
                placeholder={bussinessName.placeholder}
                error={errors.bussinessName && touched.bussinessName}
                success={bussinessNameV.length > 0 && !errors.bussinessName}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
                <MDSelect
                  type={feedbackCorrespondent.type}
                  label={corrsp_prsnnl}
                  name={feedbackCorrespondent.name}
                  value={feedbackCorrespondentV}
                  options={opts}
                  error={errors.feedbackCorrespondent && touched.feedbackCorrespondent}
                />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>

        </MDBox>
      )} 
      {(newregistration.loading) && (
        <Grid container spacing={2}>
            <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
              <ClockLoader loading={newregistration.loading} speedMultiplier={3} size={150}  color="#36d7b7"/>
            </Grid>
            <Grid item xs={4} md={4} />
        </Grid>
      )}
    </MDBox>
  );
}

UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
