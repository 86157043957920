import * as APIS_URL from "apis-url";
import myAxios from "apis/jwt-axios";

export const getAllReports = (callBack, token) => {

    myAxios(APIS_URL.GET_REGIONS_API, token).get(APIS_URL.GET_REGIONS_API)
    .then(function (response) {
        callBack(response);
    })
    .catch(function (error) {
        console.log(error.message);
    })
    .then(function () {
        // always executed
    });
} 

export const getAllInspectedApplicationsForReporting = (callBack, postData, token) => {

    myAxios(APIS_URL.REQUEST_PDF_DOCMENT, token).post(APIS_URL.REQUEST_PDF_DOCMENT, postData)
    .then(function (response) {
        if((response.data !== null) && (response.data !== undefined)){
            callBack(response.data);
        } else {
            callBack(null);
        }
    })
    .catch(function (error) {
        console.log(error.message);
    })
    .then(function () {
        // always executed
    });
} 

export const downloadPdfbase64String = (callBack, postData, token) => {
    myAxios(APIS_URL.GET_BASE64_STRING + postData.applicationId, token)
    .post(APIS_URL.GET_BASE64_STRING + postData.applicationId)
    .then(function (response) {
        if((response.data !== null) && (response.data !== undefined)){
            callBack(response.data);
        } else {
            callBack(null);
        }
    })
    .catch(function (error) {
        console.log(error.message);
    })
    .then(function () {
        // always executed
    });
}
