import RegistrationLayout from "ezimamoto/common/components/new-registration-layout";
import bgImage from "assets/images/Flag_of_Tanzania.png";
import RegistrationForm from "ezimamoto/customer-registration/new-registration-rule";

const ParentRegistrationComponent = () => {
    return (
        <RegistrationLayout image={bgImage}>
         <RegistrationForm />
        </RegistrationLayout>
    )
}

export default ParentRegistrationComponent;