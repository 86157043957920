import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Verification from "ezimamoto/customer-registration/new-registration-rule/form/components/Verification";
import QuestionPage from "ezimamoto/customer-registration/new-registration-rule/form/components/QuestionPage";
import validations from "ezimamoto/customer-registration/new-registration-rule/form/schemas/validations";
import form from "ezimamoto/customer-registration/new-registration-rule/form/schemas/form";
import initialValues from "ezimamoto/customer-registration/new-registration-rule/form/schemas/initialValues";
import { nidaRegistrationApi, nidaQuestionAndAnswerApi, registrationRequest } from "apis";
import { useMaterialUIController, updateRegistrationObject, acceptTermsAndConditionReg} from "context";
import { FormattedMessage } from "react-intl";
import ClockLoader from "react-spinners/ClockLoader";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import VerifiedUser from "ezimamoto/customer-registration/new-registration-rule/form/components/VerifiedUser";
import RegistrationForm from "ezimamoto/customer-registration/new-registration-rule/form/components/Register";
import { useNavigate } from "react-router-dom";
//import "Css/index.css";



const getSteps = (isSwahili) => isSwahili ?   [
    "Nambari Ya Kitambulisho Cha Taifa", 
    "NIDA Kufanya Maswali Na Majibu", 
    "Taarifa Za Mtumiaji Zilizothibitishwa", 
    "Usajili Wa Taarifa Sahihi Za Mtumiaji"
] : 
[
   "National Identification Number", 
    "NIDA Conducting Question & Answer", 
    "Verification For User Particular ", 
    "Registration for the User Details"
];

const getStepContent = (stepIndex, formData) => {
    
    switch (stepIndex) {
        case 0:
            return <Verification formData = { formData } />;
        case 1:
            return <QuestionPage formData = { formData } />;
        case 2:
            return <VerifiedUser formData = { formData } />;
        case 3:
            return <RegistrationForm formData = { formData } />;
        default:
            return null;
    }
}

const NewApplicationForm = () => {
    const [activeStep, setActiveStep]                                             = useState(0);
    const [controller, dispatch]                                                  = useMaterialUIController();
    const navigate                                                                = useNavigate();
    const { appliLoading, lang, registrationObj,termsAndConditionReg }            = controller;
    const { formId, formField }                                                   = form;
    const steps                                                                   = getSteps((lang === "sw"));
    const isLastStep                                                              = (activeStep === (steps.length - 1));

    const alertContent = message => (
        <MDTypography variant="body2" color="white">
            <>
                {message}
            </>
        </MDTypography>
    );

    useEffect(() => {
        let regisObject              = {};
        regisObject.loadRegistration = false;
        regisObject.nidaErrorOccur   = false;
        regisObject.nidaErrorMsg     = null;
        regisObject.nidaSuccess      = false;
        regisObject.nidaSuccessMsg   = null;
        regisObject.userInfo         = {};
        regisObject.questionResult   = {};
        updateRegistrationObject(dispatch, regisObject);
    }, [dispatch]);

    const callBack1   = (data, actions) => {
        console.log(data);
        let regisObject                  = {};
        actions.setSubmitting(false);
        actions.resetForm();
        if((data.error_msg_desc === null) && (data.message === "Accepted sucessful")){
            console.log("STAGE 1");
            setActiveStep(1);
            let errmsg                   = null;
            errmsg                       = "";
            regisObject.loadRegistration = false;
            regisObject.nidaErrorOccur   = false;
            regisObject.nidaErrorMsg     = errmsg;
            regisObject.nidaSuccess      = false;
            regisObject.nidaSuccessMsg   = null;
            regisObject.userInfo         = {};
            regisObject.questionResult   = data.result;
            updateRegistrationObject(dispatch, regisObject);
        } else if((data.error_msg_desc === null) && (data.message === "Verified sucessful")){
            console.log("STAGE 2");
            console.log(data);
            let errmsg                   = null;
            errmsg                       = "";
            regisObject.loadRegistration = false;
            regisObject.nidaErrorOccur   = false;
            regisObject.nidaErrorMsg     = errmsg;
            regisObject.nidaSuccess      = false;
            regisObject.nidaSuccessMsg   = null;
            regisObject.userInfo         = {};
            regisObject.questionResult   = data.result;
            regisObject.userInfo         = data.result2;
            setActiveStep(2);
            updateRegistrationObject(dispatch, regisObject);
        } else {
            console.log("STAGE 0");
            setActiveStep(0);
            let errmsg                   = null;
            errmsg                       = "Unknown Error";
            regisObject.loadRegistration = false;
            regisObject.nidaErrorOccur   = false;
            regisObject.nidaSuccess      = false;
            regisObject.nidaSuccessMsg   = null;
            regisObject.nidaErrorMsg     = errmsg;
            regisObject.userInfo         = {};
            regisObject.questionResult   = data.result;
            updateRegistrationObject(dispatch, regisObject);
        }
    }

    const handleBack = () => {
        console.log("clicked back");
        acceptTermsAndConditionReg(dispatch, false);
        if (isLastStep) {
            if((activeStep - 1) === 2){
                setActiveStep(0);
                let regisObject              = {};
                let errmsg                   = null;
                let succmsg                  = null;
                regisObject.loadRegistration = false;
                regisObject.nidaErrorOccur   = false;
                regisObject.nidaErrorMsg     = errmsg;
                regisObject.nidaSuccess      = false;
                regisObject.nidaSuccessMsg   = succmsg;
                regisObject.userInfo         = registrationObj.userInfo;
                regisObject.questionResult   = registrationObj.questionResult;
                updateRegistrationObject(dispatch, regisObject);
            } else {
                setActiveStep(0);
            }
        } else if(activeStep === 0) {
            navigate("/");
        } else {
            console.log("not last step");
            if(((activeStep - 1) === 1)){
                setActiveStep(0);
            } else {
                setActiveStep(activeStep - 1);
            }
        }
    }

    const callBack                   = data => {
        console.log(data);
        let regisObject                  = {};
        if(!data.error){
            if((data.error_msg_desc === null) && (data.message === "Verified sucessful")){
                let errmsg                   = null;
                regisObject.loadRegistration = false;
                regisObject.nidaErrorOccur   = false;
                regisObject.nidaErrorMsg     = errmsg;
                regisObject.nidaSuccess      = false;
                regisObject.nidaSuccessMsg   = null;
                regisObject.userInfo         = data.result2;
                regisObject.questionResult   = {};
                setActiveStep(2);
                updateRegistrationObject(dispatch, regisObject);
            } else if((data.error_msg_desc === null) && (data.message === "Accepted sucessful")){
                setActiveStep(1);
                let errmsg                   = null;
                errmsg                       = "Accepted sucessful";
                regisObject.loadRegistration = false;
                regisObject.nidaErrorOccur   = false;
                regisObject.nidaErrorMsg     = errmsg;
                regisObject.nidaSuccess      = false;
                regisObject.nidaSuccessMsg   = null;
                regisObject.userInfo         = {};
                regisObject.questionResult   = data.result;
                updateRegistrationObject(dispatch, regisObject);
            }
        } else {
            let errmsg                   = null;
            errmsg                       = data.status + " , " +data.error_msg_desc + " " + data.message;
            regisObject.loadRegistration = false;
            regisObject.nidaErrorOccur   = true;
            regisObject.nidaErrorMsg     = errmsg;
            regisObject.nidaSuccess      = false;
            regisObject.nidaSuccessMsg   = null;
            regisObject.userInfo         = {};
            regisObject.questionResult   = {};
            updateRegistrationObject(dispatch, regisObject);
        }
    }

    const activateValidationSchema = () => {
        if((activeStep === 0) && (lang === "en")) {
            return validations[activeStep][0];
        } else if((activeStep === 0) && (lang === "sw")) {
            return validations[activeStep][1];
        } else if((activeStep === 1) && (lang === "en")){
            return validations[activeStep][0];
        } else if((activeStep === 1) && (lang === "sw")){
            return validations[activeStep][1];
        } else if((activeStep === 2) && (lang === "en")){
            return validations[activeStep][0];
        } else if((activeStep === 2) && (lang === "sw")){
            return validations[activeStep][1];
        } else if((activeStep === 3) && (lang === "en")){
            return validations[activeStep][0];
        } else {
            return validations[activeStep][1];
        }
    }

    const callBack2                  = response => {
        console.log(response);
        if(response.error){
            let regisObject              = {};
            let succmsg                  = null;
            let errmsg                   = response.message;
            regisObject.loadRegistration = false;
            regisObject.nidaErrorOccur   = true;

            regisObject.nidaSuccess      = false;
            regisObject.nidaSuccessMsg   = succmsg;

            regisObject.nidaErrorMsg     = errmsg;
            regisObject.userInfo         = registrationObj.userInfo;
            regisObject.questionResult   = registrationObj.questionResult;
            updateRegistrationObject(dispatch, regisObject);
        } else {
            let regisObject              = {};
            let succmsg                  = response.message;
            let errmsg                   = null;
            regisObject.loadRegistration = false;
            regisObject.nidaErrorOccur   = false;
            regisObject.nidaSuccess      = true;
            regisObject.nidaSuccessMsg   = succmsg;
            regisObject.nidaErrorMsg     = errmsg;
            regisObject.userInfo         = registrationObj.userInfo;
            regisObject.questionResult   = registrationObj.questionResult;
            updateRegistrationObject(dispatch, regisObject);
        }

    }

    const handleSubmit               = (values, actions) => {
        if(activeStep === 0){
            let regisObject              = {};
            regisObject.loadRegistration = true;
            regisObject.nidaErrorOccur   = false;
            regisObject.nidaErrorMsg     = null;
            regisObject.nidaSuccess      = false;
            regisObject.nidaSuccessMsg   = null;
            regisObject.userInfo         = {};
            regisObject.questionResult   = {};
            updateRegistrationObject(dispatch, regisObject);
            let val                      = values.identityNumber.replaceAll('-', '');
            values.identityNumber        = val;
            nidaRegistrationApi(callBack, values);
            actions.setSubmitting(false);
            actions.resetForm();
        } else if((activeStep === 2)){
            setActiveStep(3);
            actions.setSubmitting(false);
            actions.resetForm();
        } else if(activeStep === 3) {
            let payload                  = {};
            payload                      = {
                "applicantType"        : "Individual",
                "identityType"         : "NIDA",
                "identityNumber"       : registrationObj.userInfo.nin,
                "email"                : values.email,
                "phoneNumber"          : values.phonenumber,
                "password"             : values.password,
                "passwordConfirm"      : values.confirmpassword,
                "deviceId"             : "WEB_PORTAL",
                "title"                : values.title
            };
            let regisObject              = {};
            let errmsg                   = null;
            let succmsg                  = null;
            regisObject.loadRegistration = true;
            regisObject.nidaErrorOccur   = false;
            regisObject.nidaErrorMsg     = errmsg;
            regisObject.nidaSuccess      = false;
            regisObject.nidaSuccessMsg   = succmsg;
            regisObject.userInfo         = registrationObj.userInfo;
            regisObject.questionResult   = registrationObj.questionResult;
            updateRegistrationObject(dispatch, regisObject);
            registrationRequest(callBack2, payload);
            actions.setSubmitting(false);
            actions.resetForm();
        } else {
            let postingObject            = {};
            postingObject.identityNumber = registrationObj.questionResult.nin;
            postingObject.rqCode         = registrationObj.questionResult.rqCode;
            postingObject.qnAnsw         = values.qAnswer;
            nidaQuestionAndAnswerApi(callBack1, postingObject, actions);
            actions.setSubmitting(false);
            actions.resetForm();
        }
    };

    const handleLoginPage = () => {
        navigate("/");
    }

    return (
        <MDBox >
            <Grid container justifyContent = "center" alignItems = "center" >
                <Grid item xs = { 12 } g = { 12 } >
                    <Formik initialValues = {initialValues} validationSchema = {activateValidationSchema()} onSubmit = {handleSubmit}>
                        {
                            ({ values, errors, touched, isSubmitting }) => (
                            <Form id = { formId } autoComplete = "off" >
                                
                                <Card sx={{ color : "white" }}>
                                <MDBox mx = { 2 } mt = {-3 }>
                                    <Stepper activeStep = { activeStep } alternativeLabel>
                                     {steps.map(label => (<Step key = { label }>
                                        <StepLabel>
                                                    { label }  
                                                    </StepLabel>
                                            </Step>))} 
                                           </Stepper>
                                        </MDBox> 
                                    <MDBox p = { 3 } >
                                        {(registrationObj.nidaErrorOccur)  && (<MDAlert color="primary" dismissible={false}>
                                            {alertContent(registrationObj.nidaErrorMsg)}
                                        </MDAlert>)}
                                        {(registrationObj.nidaSuccess)  && (<MDAlert color="white" dismissible={false}>
                                            {alertContent(registrationObj.nidaSuccessMsg)}
                                        </MDAlert>)}
                                        {(!registrationObj.loadRegistration) && (
                                            <MDBox > 
                                                {(!registrationObj.nidaErrorOccur && !registrationObj.nidaSuccess) &&   getStepContent(activeStep, {values, touched, formField, errors,})} 
                                                <MDBox mt = {1} py = {2} display = "flex" justifyContent = "space-between" >
                                                    {(activeStep === 0) ? (
                                                        <MDButton disabled = { appliLoading } variant = "gradient" color = "info" onClick = { handleBack }>
                                                            <FormattedMessage id="bck_to_login" />
                                                        </MDButton>) : (
                                                        <MDButton disabled = { appliLoading } variant = "gradient" color = "info" onClick = { handleBack }>
                                                            { <FormattedMessage id="bck_btn" /> }
                                                        </MDButton>)} 

                                                    {((activeStep === 3) && (registrationObj.nidaSuccess)) ? (
                                                        <MDButton type = "button" variant = "gradient" color = "info" onClick = { handleLoginPage }>
                                                         <FormattedMessage id="lgn_lbl" /> 
                                                        </MDButton>
                                                    ) : (
                                                        <MDButton disabled = {(isSubmitting || (registrationObj.nidaErrorOccur) || (registrationObj.nidaSuccess) || ((activeStep === 3) && !termsAndConditionReg))} type = "submit" variant = "gradient" color = "info">
                                                            {isLastStep ? <FormattedMessage id="cfm_btn" /> : <FormattedMessage id="nxt_btn" /> } 
                                                        </MDButton>
                                                    )} 
                                                </MDBox> 
                                            </MDBox>
                                        )}
                                        {(registrationObj.loadRegistration) && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={4} md={4} />
                                                <Grid item xs={4} md={4}>
                                                    <ClockLoader loading={registrationObj.loadRegistration} speedMultiplier={3} size={150}  color="#36d7b7"/>
                                                </Grid>
                                                <MDBox pb={5} />
                                                <Grid item xs={4} md={4} />
                                            </Grid>
                                        )}
                                    </MDBox> 
                                </Card> 
                            </Form>)
                        } 
                    </Formik>
                </Grid> 
            </Grid>
        </MDBox>
    );
}

export default NewApplicationForm;