import LOCALES from "locales";

const messages = {
    [LOCALES.ENGLISH]   : {
        nex             : "Next",
        pre             : "Prev",
        change_password : "Change Password",
        log_out         : "Click on  to  Logout",
        cust_prof       : "Customer Profile",
        btn_pwdchd      : "Submit",
        pwd_oldlabel    : "Old password",
        pwd_nwlabel     : "New password",
        pwd_cnflabel    : "Confirm Password",
        app_status      : "Application Status",
        app_sts_btn     : "Search Application",
        appl_id         : "Application Number",
        ctn_number      : "Control Number",
        bck_btn         : "BACK",
        exts_appl       : "Existing Applications",
        num_itm         : "Number Of Items",
        amt_billd       : "Amount Billed",
        prp_ctgry       : "Property Category",
        prp_sbgry       : "Property Sub Category",
        prp_sbgdt       : "Property Sub Cat. Detail",
        ward            : "Ward",
        strt_vllg       : "Street / Village",
        blc_plt         : "Block/Plot",
        hs_num          : "House Number",
        rgon            : "Region",
        dstrt           : "District", 
        admn_area       : "Administrative Area",
        id_typ          : "Identity Type",
        id_num          : "Identity Number",
        bssn_nm         : "Bussiness Name/Premise Name",
        bssn_phn        : "Bussiness/Premise Phone#",
        cstm_nm         : "Customer Name",          
        cstm_phn        : "Customer Phone#",
        pstl_addr       : "Customer Postal Address",
        tooltp_va       : "View Application",
        tbl_act         : "ACTIONS",
        rnw_appl        : "Renew Application",
        appl_form       : "Application Form",
        ig              : "Information given by me are correct to the best of my knowledge.",
        ia              :  "I acknowledge any false information provided may lead to legal actions against me.",
        agree           :  "I agree the terms and conditions of using this system.",
        nxt_btn         : "Next",
        cfm_btn         : "Confirm",
        phn_numbr       : "Customer Phone Number",
        rvw_btn         : "Review",
        blc_msg         : "Block No.",
        plt_msg         : "Plot No.",
        lct_msg         : "Location Of Premises",
        snd_rqst        : "Sending Application ...",
        appl_infrm      : "Application Information",
        eml_lbl         : "E-mail",
        pssw_lbl        : "Password",
        fgt_pwd         : "Forget Password",
        lgn_lbl         : "Login",
        rgst_lbl        : "Register",
        rst_btn         : "Submit",
        rgst_btn        : "Register",
        ttle_lbl        : "Title",
        appl_typ        : "Application Type",
        verf_form       : "Verification Form",
        cstm_ty         : "Customer Type",
        idty_numbr      : "National Identification Number (NIN - NIDA)",
        idtyp_msg       : "Identity Type",
        verified        : "Verified",
        basic_info      : "Basic Information",
        sex             : "Sex",
        dob             : "Date of Birth",
        b_region        : "Birth Region",
        b_district      : "Birth District",
        b_ward          : "Birth Ward",
        r_region        : "Resident Region",
        r_ward_         : "Resident Ward",
        r_street_       : "Resident Street",
        r_district      : "Resident District",
        qna_form_pg     : "Q&A Verification Form",
        answer_id       : "Answer",
        cust_eml_addr   : "Customer Email",
        bssn_eml_addr   : "Bussiness Email",
        bssn_pst_addr   : "Bussiness Postal Address",
        corrsp_prsnnl   : "Correspondent Info",
        bck_to_login    : "Back To Login",
        applicant_cap   : "Applicant Capacity",
        appli_form      : "Application Form",
        re_appli_form   : "Renew Application Form",
        inspected_rpt   : "Inspected Report",
        new_ca          : "New Customer Application",
        per_y           : "Per Year",
        existing_ca     : "Existing Customer Application",
        total_ca        : "Total Customer Application",
        renew_ca        : "Renew Customer Application",
        frf_y           : "Fire and Rescue Force",
        tca_five        : "Total Customer Application For Five Year",
        pa              : "Pending Applications(PA)",
        ca              : "Complete Applications(CA)",
        ap              : "Applications On Progress",
        adr             : "Application Due For Renewal",
        ezimamoto       : "ezimamoto",
        instruct        : "Click to read instruction",
        Read            : "Read The Instructions On How To Use ezimamoto Application",
        instruction     : "Instructions Of Using This Portal",
        Awd             : "Applicant will be able to view dashboard",
        D               : "Dashboard",
        Air             : "Applicant will be able to download inspection reports",
        Rm              : "National Identity Number (NIDA)",
        mrd             : "Write national identity number in verification form, then click NEXT to view NIDA questions form", 
        it              : "click BACK TO LOGIN, back to login form",
        nida            : "National Identity Number",
        fnida           : "User should write National indentity number into a form to get permission to make registration.",
        nqa             :  "NIDA Questions and Answers",
        unqa            :  "User should answers the Questions from NIDA",
        vud             :  "Verifications for User Details",
        urv             :  "User receives Verifications about his or her own details",
        rud             :  "Registration for User details",
        urs             :  "User start to register into the system which enable him or her to get username and password to login into the system",
        am              :  "APPLICATION MODULE",
        uma             :  "This is the module enabled user to make his or own applications",
        ict             :  "It contains the following:-",
        na              :   "New Application:-",
        d               :   "Downloads",
        Aupe            :   "Applicant should fill his or her own email address to receive username and password within his or her email",
        Rrp             :   "This enable user to resete or remember his or own password",
        Rfm             :   "Reset Password (forget password) ",
        cp              :   "Applicant should fill old password, new password and confirm password for renew",
        cpu             :   "This enable user to change his or her password",
        cpug            :   "Change Password",
        fr              :   "It has contain form for renewable",
        fdr             :   "This used to display all the applications due to renewable",
        Ra              :   "Renew Application",
        aes             :   "Applicant view all the applications which existed within the system",
        ea              :   "Existing Application ",
        ean             :   "Applicant should use either Application number(from Existing Application) or controll number to see the status of the application ",
        as              :   "Application Status ",
        caa             :   "Applicants should confirm the application ",
        soa             :   "Status of the application ",
        pc              :   "Applicant should select property Category of the business to know the surveyed and un surveyed Areas",
        pcm             :   "property Category",
        flbl            :   "Applicants should fill business location where business located ",
        bl              :   " User Business Location",
        fib             :   " Applicants should fill his or her own information about the business, then click NEXT to continue with the application",
        ai              :   " Applicant Information", 
        vaaf            :   " Applicants should select applicant capacity, customer type,identity type, then click NEXT",
        cad             :   " Customer Verification.",
        aacfw           :   " Applicant should register his or her own application by consider the following bellows:-",
        next            :   " Next", 
        adfr2           :   " applicationsDueForRenewal",
         
        aop             :   " Applications On Progress",
        IAG             :    "I agree the terms and conditions of using this system. Information given by me are correct to the best of my knowledge.I acknowledge false information provided may lead to legal actions against me",
        dn              :   " district Name",
        ri              :   " region identity",
        rn              :   " region name",
        m               :     "data.pendingApplications",
        lgpdfn          :     "This is the page which contains three buttons, which are:-",
        fp              :     "i. Forget Password",
        rg              :     "ii. Register",
        l               :     "ii. Login",
        fgp             :     "Forget Password",
        ufp             :     "Select forget password from login form, then write email address into reset password form to click submit button, password will be send into your email address",
        fgp4            :     "Click BACK, back to Login Page",
        lgp             :     "Login Form",
        regfm           :     "Registration Form",
        regd            :     "This form contains foursub forms which are:-",
        regnida         :     "National Identity Number (NIDA)",
        regquest        :     " NIDA questions and answer",
        reguser         :     "Verification for User",
        regregistrat    :     "Registration",
        uv              : "User Verification ",
        uvd             : "User Verify his or her own name registered in NIDA, then click NEXT to view registration form to make registration into the system ",
        r               : "Registration ",
        rd              : "User should fill title,customer phone number,email,password and comfirm the password then click CONFIRM , user will receive username and password into his or her own email address ",  
        rb              : "click BACK, to back to registration form",
        iup             : "Instructions of using zimamoto portal",
        term            :  "tick to accept all the conditions.",
        terms_and_con   : "Accept Terms & Conditions", 
        down            : "Download Pdf",
        instr_login     : "Instructions of using portal",
        bck_login       : "BACK",
        wc              :  "Welcome Our Customer",
        yp              :  "Visit Your Page",
        reset_password  :  "Reset Your Password",
    },
    [LOCALES.SWAHILI]   : {
        change_password : "Badili Nywila",
        log_out         : "Kutoka Nje",
        cust_prof       : "Wasifu wa Mtumiaji",
        btn_pwdchd      : "Kusanya",
        pwd_oldlabel    : "Nywila ya zamani",
        pwd_nwlabel     : "Nywila mpya",
        pwd_cnflabel    : "Thibitisha nywila",
        app_status      : "Hali ya Ombi",
        app_sts_btn     : "Tafuta Ombi",
        appl_id         : "Namba ya Ombi",
        ctn_number      : "Namba ya Malipo",
        bck_btn         : "NYUMA",
        exts_appl       : "Maombi Yaliyopo",
        num_itm         : "Idadi ya vitu",
        amt_billd       : "Kiasi Kilichotozwa",
        prp_ctgry       : "Aina ya Jengo/Eneo",
        prp_sbgry       : "Kipengele kidogo cha Jengo/Eneo",
        prp_sbgdt       : "Fafanuzi ya Aina ya Jengo/Eneo",
        ward            : "Kata",
        strt_vllg       : "Mtaa / Kijiji",
        blc_plt         : "Kipande/Shamba",
        hs_num          : "Nambari ya Nyumba",
        rgon            : "Mkoa",
        dstrt           : "Wilaya", 
        admn_area       : "Eneo La Utawala",
        id_typ          : "Aina ya Kitambulisho",
        id_num          : "Nambari ya Kitambulisho",
        bssn_nm         : "Jina la Biashara/Jengo",
        bssn_phn        : "Namba Simu ya Biashara/Jengo",
        cstm_nm         : "Jina la Mteja",          
        cstm_phn        : "Namba simu ya Mteja",
        pstl_addr       : "Anuani Posta ya Mteja",
        tooltp_va       : "Angalia Ombi",
        tbl_act         : "Vitendo",
        rnw_appl        : "Huisha Ombi",
        appl_form       : "Fomu ya Maombi",
        nxt_btn         : "Nyingine",
        cfm_btn         : "Thibitisha",
        phn_numbr       : "Nambari ya Simu ya Mteja",
        rvw_btn         : "Hakiki",
        blc_msg         : "Namba ya Kitalu",
        plt_msg         : "Namba ya Kiwanja",
        lct_msg         : "Mahali Lilipo Jengo/Eneo",
        snd_rqst        : "Inatuma Ombi Subiri ...",
        appl_infrm      : "Taarifa za Ombi",
        eml_lbl         : "Barua pepe",
        pssw_lbl        : "Nywira",
        fgt_pwd         : "Umesahau Nywira",
        lgn_lbl         : "Ingia",
        rgst_lbl        : "Jisajili",
        rst_btn         : "Kusanya",
        rgst_btn        : "Jisajiri",
        ttle_lbl        : "Cheo",
        appl_typ        : "Aina ya Ombi",
        verf_form       : "Fomu ya Uhakiki",
        cstm_ty         : "Aina ya Ombi",
        idty_numbr      : "Nambari ya Kitambulisho cha Taifa (NIDA)",
        idtyp_msg       : "Aina ya Utambulisho",
        verified        : "Imethibitishwa",
        basic_info      : "Taarifa Muhimu",
        sex             : "Jinsia",
        dob             : "Tarehe ya Kuzaliwa",
        b_region        : "Mkoa wa Kuzaliwa",
        b_district      : "Wilaya ya Kuzaliwa",
        b_ward          : "Kata ya Kuzaliwa",
        r_region        : "Mkoa Makazi",
        r_ward_         : "Kata ya Makazi",
        r_street_       : "Mtaa wa Makazi",
        r_district      : "Wilaya ya Makazi",
        qna_form_pg     : "Fomu ya Uthibitishaji",
        answer_id       : "Jawabu",
        cust_eml_addr   : "Barua pepe ya Mteja",
        bssn_eml_addr   : "Barua pepe ya Biashara",
        bssn_pst_addr   : "Anuani Posta ya Biashara",
        corrsp_prsnnl   : "Mawasiliano Yatumwe Kwa",
        bck_to_login    : "Rudi Kuingia",
        applicant_cap   : "Nafasi ya Muombaji",
        appli_form      : "Fomu ya Maombi",
        re_appli_form   : "Fomu ya Kuhuisha Ombi",
        inspected_rpt   : "Ripoti ya Ukaguzi",
        new_ca          : "Ombi Jipya la Mteja",
        per_y           : "Kwa Mwaka",
        existing_ca     : "Maombi ya Mteja Yaliyopo",
        total_ca        : "Jumla ya Maombi ya Mteja",
        renew_ca        : "Kurudia Ombi la Mteja",
        frf_y           : "Jeshi la Zimamoto na Uokoaji",
        tca_five        : "Jumla ya Maombi ya Mteja Ndani ya Miaka Mitano",
        pa              : "Maombi Yanayosubili kufanyiwa kazi",
        ca              : "Maombi Yaliyofanyiwa Kazi",
        ap              : "Maombi Yanayoendelea",
        adr             : "Maombi Yatakayohuishwa",
        ezimamoto       : "Zimamoto Ya Kidijitali",
        instruct        : "Bonyeza kusoma maelekezo",
        Read            : "Maelekezo Jinsi Ya Kutumia Mfumo Wa Zimamoto (ezimamoto)",
        instruction     : "Namna Ya Kutumia Mfumo",
        Awd             : "Muombaji anauwezo wa kuona Dashibodi(nyumbani)",
        D               : "Dashibodi(nyumbani)",
        Air             : "Mwombaji anaweza kuona na kupakua ripoti ya ukaguzi",
        Rm              : "Nambari ya kitambulisho cha NIDA", 
       
        nida            : "Kitambulisho Cha Taifa", 
        fnida           : "Mwombaji Anatakiwa Kuingiza Namba Ya NIDA.",
        nqa             : "NIDA Maswari Na Majibu.",
        unqa            :  "Mwombaji Anatakiwa Kujibu Maswari",
        vud             :  "Kudhibitisha Maombi Ya Mteja",
        urv             :  "Mwombaji Kupokea Taarifa Zake",
        rud             :  "Kusajiliwa Kwa Mwombaji ",
        urs             :  "Mwombaji Kujisajili Kwenye  Mfumo Kwa Kutumia Jina halisi Na Nywira",
        am              :  "Moduli Inayotumika Kufanya Maombi Ya Biashara",
        uma             :  "Hii Ni Moduli Inayomsaidia Mwombaji Kufanya Maombi Ya Biashara Kwenye Mfumo.",
        ict             :  "Ina Vitu Vifuatavyo:-",
        na              :   "Maombi Mapya:-",
        d               :   "Pakua:-",
        Aupe            :   "Mwombaji Kujaza Email Yake Ili Kuikumbuka Jina halisi na Nywira",
        Rrp             :   "Hii Inamwezesha Mwombaji Kukumbuka Jina Halisi Lake Na Nywira",
        Rfm             :   "Kukumbuka Nywira",
        cp              :   "Mwombaji Anatakiwa Ajaze Nywira Yake Ya Zamani, Mpya Na Kuihakiki",
        cpu             :   "Hii Inamuwezesha Mtumiaji Kubadili Nywira",
        cpug            :   "Kubadili Nywira",
        fr              :   "Fomu Ya Kuhuisha Ombi",
        fdr             :   "Hii Inatumika Kuhuisha Ombi",
        Ra              :   "Kuhuisha Ombi", 
        aes             :   "Maombi Yaliyopo Kwenye Mfumo",
        ea              :   "Maombi Yaliyopo ",
        ean             :   "Mwombaji Anatumia Namba Ya Ombi au Nambari Ya Kumbukumbu Kujua Ombi Lake Lilipofikia ",
        as              :   "Maombi Ya Ombi Lako ",
        caa             :   "Mwombaji Anahakiki Ombi ",
        soa             :   "Hali Ya Ombi  ",
        pc              :   "Mwombaji Anatakiwa Ajue Aina Ya Mali Inayoombewa",
        pcm             :   "Aina Ya Mali Inayoombewa",
        flbl            :   "Mwombaji Anatakiwa Aonyeshe Eneo Husika",
        bl              :   "Biashara Ilipo", 
        fib             :   " Mwombaji Kujaza Taarifa Kuhusu  aliyonayo, baadae bonyeza NYINGINE kuendelea na maombi",  
        ai              :   " Taarifa Za Mwombaji", 
        vaaf            :   " Jaza nafasi ya mwombaji,aina ya ombi, aina ya utambulisho na nambari ya NIDA, baadae bonyeza NYINGINE",
        cad             :   " Uhakiki Wa Mteja.",
        aacfw           :   " Mwombaji Anatakiwa Kusajili Maombi Yake Kama Ifuatavyo:-",
        next            :   " Inayofuata",
        adfr2           :   "Maombi Yanayosubilia Kuhuishwa",
        ca2             :   "Maombi Yaliyofanyiwa Kazi",
        aop             :   "Maombi Yanatakiwa Kuhuishwa",   
        pa2             :   " Maombi Yanayosubilia",
        dn              :   " jina la wilaya",
        ri              :   " id ya mkoa",
        rn              :   " jina la mkoa",
        lgpdfn          :     "Huu ni ukurasa ambao una batani tatu ambazo ni:-",
        fp              :     "i. Kusahau Nywira",
        rg              :     "ii. Usajili",
        l               :     "ii. Kuingia",
        lgp             :     "Fomu ya Kuingia",
        fgp             :     "Kusahau Nywira",
        ufp             :     "Chagua kusahau nywira kutoka kwenye fomu ya kuingia, kisha Ingiza anwani yako kwenye fomu ya kusahau nywira bonyeza kusanya , hivyo nywira itatumwa kwenda kwenye anwani yako (email)",
        fgp4            :     "Bonyeza nyuma, kurudi kwenye ukurasa wa kuingia",
        regfm           :     "Fomu ya Usajili",
        regd            :     "Hii fomu ndani yake kuna fomu zingine nne ambazo ni:-",
        regnida         :     "Nambari ya kitambulisho cha NIDA",
        regquest        :     " NIDA maswari na majibu",
        reguser         :     "Taarifa za mtumiaji",
        regregistrat    :     "Usajili wa taarifa",
        mrd             : "Andika nambari ya NIDA kwenye fomu ya uthibitisho kuweza kupata nafasi ya kuingia kwenye fomu ya NIDA maswari na majibu, kisha bonyeza nyingine kuendelea mbele", 
        it              : "bonyeza RUDI NYUMA, kurudi kwenye ukurasa wa fomu ya kuingia ",
        uv              : "Uthibitisho wa Mtumiaji ",
        uvd             : "Mtumiaji anathibitisha jina halisi lililosajiliwa na NIDA ili limuwezeshe kubonyeza mbele kufanya maombi ya kumuwezesha kuingia kwenye mfumo ",
        r               : "Usajili wa Mtumiaji",
        rb              : "bonyeza KURUDI, kurudi kwenye fomu ya usajili",
        rd              : "Mtumiaji anatakiwa kujaza cheo, nambari ya simu ya mteja, barua pepe, nywira na thibitisha nywira, kisha bonyeza thibitisha kupata nywira kwenye email yako ili ikuwezeshe kuingia kwenye mfumo. ",
        nex             : "Nyingine",
        pre             : "Nyuma",
        iup             : "Namna ya Kutumia Vizuri Mfumo",
        ig              : "Taarifa ninazozitoa ni sahihi kwa akili yangu timamu.",
        ia              :  "Ninakiri taarifa zozote za uongo nikizitoa hatua za kisheria zichukuliwe dhidi yangu.",
        agree           :  "Ninakubaliana na sheria na masharti ya kutumia mfumo huu.",
        term            :  "weka vema ili kukubaliana na masharti yaliyopo.",
        IAG             :  "Ninakubaliana na Sheria na Masharti ya kutumia Mfumo huu. Taarifa ninazozitoa ni sahihi. Ninakili taarifa yeyote ya uongo nitakayoitoa niko tayari kuchukuliwa hatua za kisheria dhidi yangu",
        terms_and_con   :  "Kubali Vigezo na Masharti",
        down            :  "Pakua Pdf",
        instr_login     : "Namna ya kutumia mfumo",
        bck_login       : "NYUMA",
        wc              :  "Karibu Mteja Wetu",
        yp              :  "Tembelea Ukurasa Wako",
        reset_password  : "Weka Upya Nywila Yako",
    },
};

export default messages;
