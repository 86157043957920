import CustomerLogin from "ezimamoto/customer-login";
import CustomerRegistration from "ezimamoto/customer-registration";
import ResetPassword from "ezimamoto/Password/ResetPassword";
import ChangePassword from "ezimamoto/Password/ChangePassword";
import InspectionReports from "ezimamoto/Reports/InspectionReports";
import DashBoardPage from "ezimamoto/DashBoardPage";
import NewApplication from "ezimamoto/application/new-application";
import ApplicationStatus from "ezimamoto/application/application-status";
import ExistingApplication from "ezimamoto/application/existing-application";
import RenewApplication from "ezimamoto/application/renew-application";
import Icon from "@mui/material/Icon";
import Instructions from "ezimamoto/instructions";
import GlobalChangePassword from "ezimamoto/GlobalChangePassword";

const routes = [
  { type: "title", title: "Vipengele", key: "title-pages" },
  {
    type: "collapse",
    name: "Nyumbani",
    key: "dash-board-page",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Nyumbani",
        key: "dash-board-page",
        route: "dash-board-page",
        component: <DashBoardPage/>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Ombi",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Ombi Jipya",
        key: "new-application",
        route: "/applications/new-application",
        component: <NewApplication />,
      },
      {
        name: "Huisha Ombi",
        key: "renew-application",
        route: "/applications/renew-application",
        component: <RenewApplication />,
      },
      {
        name: "Hali ya Ombi Lako",
        key: "application-status",
        route: "/applications/application-status",
        component: <ApplicationStatus />,
      },
      {
        name: "Ombi Lililopo",
        key: "existing-application",
        route: "/applications/existing-application",
        component: <ExistingApplication />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Pakua",
    key: "inspection-reports",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
                   
      {
        name: "Taarifa ya Ukaguzi",
        key: "inspection-reports",
        route: "/inspection-reports",
        component: <InspectionReports/>,
      },
      ],
        },
        
        {
          type: "collapse",
          name: "Maelekezo",
          key: "instructions",
          icon: <Icon fontSize="medium">view_in_ar</Icon>,
          collapse: [
           
      
            {
              name: "Maelekezo",
              key: "instructions",
              route: "/instructions",
              component: <Instructions />,
            },
          ],
        },
        {
          type: "collapse",
          name: "Badili Nywila",
          key: "password",
          icon: <Icon fontSize="medium">image</Icon>,
          collapse: [
            {
              name: "Badili Nywila",
              key: "change-password",
              route: "password/change-password",
              component: <ChangePassword/>,
            },
          ],
        },
        {
          name: "Usajili",
          key: "Registration-Page",
          route: "/ezimamoto/customer-registration",
          component: <CustomerRegistration/>,
        },
        {
          name: "Rekebisha Nywila",
          key: "Reset-Password",
          route: "/ezimamoto/password/reset-password",
          component: <ResetPassword/>,
        },
        {
         // name: "Utumiaji",
         // key: "instructionlogin",
         // route: "/ezimamoto/instructionlogin",
          //component: <Instruct/>,
        },
        {
          name: "Ingia",
          key: "Login-Page",
          route: "/ezimamoto/customer-login",
          component: <CustomerLogin/>,
        },
        {
          name: "Ingia",
          key: "login-page",
          route: "/",
          component: <CustomerLogin/>,
        },
        {
          name: "DashBoard",
          key: "dash-board-page",
          collapse: [
            {
              name: "DashBoard",
              key: "dash-board-page",
              route: "/ezimamoto/dash-board-page",
              component: <DashBoardPage/>,
            },
          ],
        },
        {
          name: "Change Password 1",
          key: "global-change-password",
          route: "/ezimamoto/global-change-password/reset-password",
          component: <GlobalChangePassword/>,
          customKey: null,
        },
        { type: "divider", key: "divider-1" },
];

export default routes;
