import React from 'react';
import ApplicationLayout from "ezimamoto/common/app-layout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import  { Navigate } from 'react-router-dom'
import NewApplicationForm from "ezimamoto/application/new-application/form";

function NewApplication() {

    const getUserSession = () => {
        let session = sessionStorage.getItem("usn");
        if((session === null) || (session === "")){
            return <Navigate to='/'  />
        } else {
            let user = JSON.parse(session);
            if((typeof user === 'object') && (user !== null)) {
                
               return (        
                <>
                    <DashboardNavbar />
                    <NewApplicationForm />
                </>);
            } else {
                return <Navigate to='/'  />
            }
        }
    }
    
    return (<ApplicationLayout>{getUserSession()}</ApplicationLayout>);
    
}

export default NewApplication;