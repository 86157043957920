import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import  { useEffect, useState } from "react";
import MDSelect from "ezimamoto/common/MDSelect";
import { useIntl } from 'react-intl';


function Verification({ formData }) {
    const { formField, values, errors, touched }                                      = formData;
    const { applicantCapacity }                                                       = formField;
    const [ controller ]                                                              = useMaterialUIController();
    const { lang }                                                                    = controller;

    const options3                                                                    = [{ value : "1",   label: "Owner"},
                                                                                         { value : "2",   label: "Supervisor"},
                                                                                         { value : "3",   label: "Manager"},
                                                                                         { value : "4",   label: "Director"},
                                                                                         { value : "5",   label: "Managing Director"},
                                                                                         { value : "6",   label: "General Manager"},
                                                                                         { value : "7",   label: "Director General"},
                                                                                         { value : "8",   label: "Representative"},
                                                                                         { value : "9",   label: "Chief Executive Officer"}
                                                                                        ];

  const options4                                                                      = [{ value : "1",   label: "Mmiliki"},
                                                                                         { value : "2",   label: "Msimamizi"},
                                                                                         { value : "3",   label: "Meneja"},
                                                                                         { value : "4",   label: "Mkurugenzi"},
                                                                                         { value : "5",   label: "Mkurugenzi Mtendaji"},
                                                                                         { value : "6",   label: "Meneja Mkuu"},
                                                                                         { value : "7",   label: "Mkurugenzi Mkuu"},
                                                                                         { value : "8",   label: "Mwakilishi"},
                                                                                         { value : "9",   label: "Afisa Mtendaji Mkuu"}
                                                                                        ];

    const { applicantCapacity: applicantCapacityV }                                   = values;
    const [capty, setCapty]                                                           = useState([]);
    const intl                                                                        = useIntl();
    const applicant_cap                                                               = intl.formatMessage({id: 'applicant_cap'});
    const re_appli_form                                                               = intl.formatMessage({id: 're_appli_form'});

    useEffect(() => {
      let session = sessionStorage.getItem("usn");
      console.log(session);
      if(session !== null){
        let usrObj             = JSON.parse(session);
        values.customerName    = (usrObj.companyName === "") ? "NO INFO" : usrObj.companyName;
      }
    }, []);

    useEffect(() => {
      if(lang === "sw"){
        setCapty(options4);
      } else {
        setCapty(options3);
      }
    }, [lang]);

    return (
      <MDBox>
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">{re_appli_form}</MDTypography>
        </MDBox>
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDSelect
                type={applicantCapacity.type}
                label={applicant_cap}
                name={applicantCapacity.name}
                value={applicantCapacityV}
                options={capty}
                error={errors.applicantCapacity && touched.applicantCapacity}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
          <Grid container spacing={3}><Grid item xs={12} sm={6} >
          </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    );
  }
  
  Verification.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  };
  
  export default Verification;
  