import { createContext, useContext, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
const MaterialUI = createContext();
MaterialUI.displayName = "MaterialUIContext";

function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "SENDING_PASSWORD_RESET": {
      return { ...state, loadingClock: action.value };
    }
    case "REGISTRATION_REQUEST": {
      return { ...state, loadRegistration: action.value };
    }
    case "PASSWORD_CHANGE_REQUEST": {
      return { ...state,loadChangePassword : action.value };
    }
    case "DISPLAY_LOADER_REQUEST": {
      return { ...state,dispayLoader : action.value };
    }
    case "SUBMIT_APPLICATION_REQUEST": {
      return { ...state, appliLoading : action.value };
    }
    case "LOGIN_SUBMIT_REQUEST": {
      return { ...state, appliLoading : action.value };
    }
    case "SELECTED_PROP_CAT_CHANGE": {
      return { ...state, propertyCat : action.value };
    }
    case "ALLOW_MULTIPLY": {
      return { ...state, allMultiple : action.value };
    }
    case "STORE_RESPONSE_OBJECT": {
      return { ...state, newappObj : action.value };
    }
    case "REVIEW_APPLICATION": {
      return { ...state, review : action.value };
    }
    case "SUB_CATEGORY_DETAIL_DATA": {
      return { ...state, subcatdetail : action.value };
    }
    case "CHANGE_LANGUAGE": {
      return { ...state, lang : action.value };
    }
    case "NIDA_ERROR": {
      return { ...state, nidaErrorVerification : action.value };
    }
    case "NIDA_SET_ERROR": {
      return { ...state, nidaErrorMsg : action.value };
    }
    case "UPDATE_REGISTRATION_STATE": {
      return { ...state, registrationObj : action.value };
    }
    case "MOVING_REGISTRATION_STEPPER": {
      return { ...state, newregistration : action.value };
    }
    case "SET_TABLE_LOADING_PROPERTY": {
      return { ...state, tableLoading : action.value };
    }
    case "SET_SHOW_RENEWAL_PAGE": {
      return { ...state, showRenewalPage : action.value };
    }
    case "SET_RENEW_OBJECT": {
      return { ...state, renewobject : action.value };
    }
    case "SET_ALLOW_MULTIPLY": {
      return { ...state, allowMultiply : action.value };
    }
    case "SET_HAS_SUB_CATEGORY_DETAIL": {
      return { ...state, hasSubCategoryDetail : action.value };
    }
    case "SET_SHOW_CHANGE_MENU": {
      return { ...state, showChangeMenu : action.value };
    }
    case "RENEW_REGISTRATION_SAVE_DATA": {
      return { ...state, renewregistration : action.value };
    }
    case "ACCEPT_TERMS_AND_CONDITION": {
      return { ...state, termsAndCondition : action.value };
    }
    case "ACCEPT_TERMS_AND_CONDITION_REG": {
      return { ...state, termsAndConditionReg : action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav          : false,
    transparentSidenav   : false,
    whiteSidenav         : false,
    sidenavColor         : "info",
    transparentNavbar    : false,
    fixedNavbar          : true,
    openConfigurator     : false,
    direction            : "ltr",
    layout               : "dashboard",
    darkMode             : false,
    loadingClock         : false,
    loadRegistration     : false,
    loadChangePassword   : false,
    dispayLoader         : false,
    appliLoading         : false,
    newappObj            : {},
    propertyCat          : "",
    allMultiple          : false,
    review               : false,
    subcatdetail         : false,
    lang                 : "en",
    nidaErrorOccur       : false,
    nidaErrorMsg         : "",
    registrationObj      : {},
    newregistration      : {},
    tableLoading         : false,
    showRenewalPage      : false,
    renewobject          : {},
    allowMultiply        : false,
    hasSubCategoryDetail : false,
    showChangeMenu       : true,
    renewregistration    : {},
    termsAndCondition    : false,
    termsAndConditionReg : false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav               = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav        = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav              = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor              = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar         = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar               = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator          = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection                 = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout                    = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode                  = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setSendPasswordResetRequest  = (dispatch, value) => dispatch({ type: "SENDING_PASSWORD_RESET", value });
const setRegistrationRequest       = (dispatch, value) => dispatch({ type: "REGISTRATION_REQUEST", value });
const setPasswordChangeRequest     = (dispatch, value) => dispatch({ type: "PASSWORD_CHANGE_REQUEST", value });
const setDisplayLoaderRequest      = (dispatch, value) => dispatch({ type: "DISPLAY_LOADER_REQUEST", value });
const submitApplicationRequest     = (dispatch, value) => dispatch({ type: "SUBMIT_APPLICATION_REQUEST", value });
const storeObjectRequest           = (dispatch, value) => dispatch({ type: "STORE_RESPONSE_OBJECT", value });
const selectedPropcatChange        = (dispatch, value) => dispatch({ type: "SELECTED_PROP_CAT_CHANGE", value });
const setAllowMultiple             = (dispatch, value) => dispatch({ type: "ALLOW_MULTIPLY", value });
const reviewApplication            = (dispatch, value) => dispatch({ type: "REVIEW_APPLICATION", value });
const subCategoryDetailData        = (dispatch, value) => dispatch({ type: "SUB_CATEGORY_DETAIL_DATA", value });
const changeUserLanguage           = (dispatch, value) => dispatch({ type: "CHANGE_LANGUAGE", value });
const nidaErrorVerification        = (dispatch, value) => dispatch({ type: "NIDA_ERROR", value });
const setNidaErrorMessage          = (dispatch, value) => dispatch({ type: "NIDA_SET_ERROR", value });
const updateRegistrationObject     = (dispatch, value) => dispatch({ type: "UPDATE_REGISTRATION_STATE", value });
const movingRegistrationStepper    = (dispatch, value) => dispatch({ type: "MOVING_REGISTRATION_STEPPER", value });
const setTableLoadingProperty      = (dispatch, value) => dispatch({ type: "SET_TABLE_LOADING_PROPERTY", value });
const setShowRenewalPage           = (dispatch, value) => dispatch({ type: "SET_SHOW_RENEWAL_PAGE", value });
const setRenewObject               = (dispatch, value) => dispatch({ type: "SET_RENEW_OBJECT", value });
const setAllowMultipleRenew        = (dispatch, value) => dispatch({ type: "SET_ALLOW_MULTIPLY", value });
const setHasSubCategoryDetail      = (dispatch, value) => dispatch({ type: "SET_HAS_SUB_CATEGORY_DETAIL", value });
const setShowChangeMenuAction      = (dispatch, value) => dispatch({ type: "SET_SHOW_CHANGE_MENU", value });
const renewRegistrationSaveData    = (dispatch, value) => dispatch({ type: "RENEW_REGISTRATION_SAVE_DATA", value });
const acceptTermsAndCondition      = (dispatch, value) => dispatch({ type: "ACCEPT_TERMS_AND_CONDITION", value });
const acceptTermsAndConditionReg   = (dispatch, value) => dispatch({ type: "ACCEPT_TERMS_AND_CONDITION_REG", value });


export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setSendPasswordResetRequest,
  setRegistrationRequest,
  setPasswordChangeRequest,
  setDisplayLoaderRequest,
  submitApplicationRequest,
  storeObjectRequest,
  selectedPropcatChange,
  setAllowMultiple,
  reviewApplication,
  subCategoryDetailData,
  changeUserLanguage,
  nidaErrorVerification,
  setNidaErrorMessage,
  updateRegistrationObject,
  movingRegistrationStepper,
  setTableLoadingProperty,
  setShowRenewalPage,
  setRenewObject,
  setAllowMultipleRenew,
  setHasSubCategoryDetail,
  setShowChangeMenuAction,
  renewRegistrationSaveData,
  acceptTermsAndCondition,
  acceptTermsAndConditionReg
};