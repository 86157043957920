const form = {
  formId: "new-user-reg-form",
  formField: {

    identityNumber: {
      name: "identityNumber",
      label: "National Identification Number (NIDA)",
      type: "text",
      errorMsg: "NIN is required.",
      errorMsgSw: "Namba ya Kitambulisho cha Taifa (NIDA) Inahitajika.",
      minMsg: "Your NIN is too short",
      minMsgSw: "Nambari yako ya Taifa ni fupi sana",
      maxMsg: "Your NIN is too long",
      maxMsgSw: "Nambari yako ya Taifa ni ndefu sana",
    },

    qAnswer: {
      name: "qAnswer",
      label: "Verification Question",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },

    fname: {
      name: "fname",
      label: "First Name",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },

    mname: {
      name: "mname",
      label: "Middle Name",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },

    lname: {
      name: "lname",
      label: "Last Name",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },

    sex1: {
      name: "sex",
      label: "Sex",
      type: "text",
      placeholder: "Sex",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },   

    dob: {
      name: "dob",
      label: "Date of Birth",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },     

    bregion: {
      name: "bregion",
      label: "Birth Region",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },   

    bdistrict: {
      name: "bdistrict",
      label: "Birth District",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },        

    bward: {
      name: "bward",
      label: "Birth Ward",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },  

    rregion: {
      name: "rregion",
      label: "Resident Region",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },   

    rdstr: {
      name: "rdstr",
      label: "Resident District",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    }, 

    rward: {
      name: "rward",
      label: "Resident Ward",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },

    rstrt: {
      name: "rstrt",
      label: "Resident Street",
      type: "text",
      errorMsg: "This Field is required.",
      errorMsgSw: "Taarifa Inahitajika.",
    },

    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
      errorMsgSw: "Inahitajika.",
    },

    phonenumber: {
      name: "phonenumber",
      label: "Phone number",
      type: "text",
      errorMsg: "Phone number is required.",
      errorMsgSw: "Nambari ya simu inahitajika.",
      maxMsg: "Only ten digit are required",
      maxMsgSw: "Inahitaji tarakimu kumi tu",
      minMsg: "Only ten digit are required",
      minMsgSw: "Inahitaji tarakimu kumi tu"
    },

    email: {
      name: "email",
      label: "Email",
      type: "email",
      errorMsg: "Email is required.",
      errorMsgSw: "Barua pepe inahitajika.",
      invalidMsg: "E-mail is invalid.",
      invalidMsgSw: "Barua pepe sio sahihi.",
    },

    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      errorMsgSw: "Nywira inahitajika.",
    },

    confirmpassword: {
      name: "confirmpassword",
      label: "Confirm Password",
      type: "password",
      errorMsg: "Password Confirmation Required.",
      errorMsgSw: "Nywira ya uthibitisho Inahitajika.",
      errorWeakMsg: "Weak Password.",
      errorWeakMsgSw: "Nywira yako ni Hafifu.",
    },

  },
};

export default form;
