import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "ezimamoto/application/new-application/form/components/FormField";
import { useIntl } from 'react-intl';
import { FormattedMessage } from "react-intl";

function Verification({ formData }) {
    const { formField, values, errors, touched }                                      = formData;
    const { identityNumber }                                                          = formField;
    const intl                                                                        = useIntl();
    const { identityNumber:     identityNumberV }                                     = values;
    const lbl                                                                         = intl.formatMessage({id: 'idty_numbr'});

    return (
      <MDBox>
        <MDBox lineHeight={0}>
          <MDTypography variant="h5" color="dark">
            {<FormattedMessage id="verf_form" />}
          </MDTypography>
        </MDBox>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {(
                <FormField
                  type={identityNumber.type}
                  label={lbl}
                  name={identityNumber.name}
                  inputProps={{ maxLength :23, minLength :20 }}
                  value={identityNumberV}
                  placeholder={identityNumber.placeholder}
                  error={errors.identityNumber && touched.identityNumber}
                  success={identityNumberV.length > 0 && !errors.identityNumber}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    );
  }
  Verification.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  };
  
  export default Verification;
  