import {
    REGISTRATION_API_POST, 
    RESET_USER_PASSWD, 
    LOGIN_PAGE_SIGNIN_API, 
    FORGOT_PASSWORD_API,
    NIDA_REGIS_API,
    NIDA_QNA,
    RESET_PASSWD_URL_API, } from "apis-url";

import axios from "axios";
import myAxios from "apis/jwt-axios";

export const resetGlobalPasswordRequest = (callBack, postData) => {
    console.log(postData);
    console.log(RESET_PASSWD_URL_API);
    axios.post(RESET_PASSWD_URL_API, postData)
    .then(response => {
        callBack(response.data);
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("CLEARING RESET PASSWORD REQUEST");
    });
} 

export const registrationRequest = (callBack, postData) => {
    console.log(postData);
    axios.post(REGISTRATION_API_POST, postData)
    .then(response => {
        callBack(response.data);
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("CLEARING REGISTRATION REQUEST");
    });
} 

export const resetUserPasswordRequest = (callBack, postData, token) => {
    myAxios(RESET_USER_PASSWD, token).post(RESET_USER_PASSWD, postData)
    .then(response => {
        console.log(response);
        callBack(response.data);
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("CLEARING USER PASSWORD REQUEST");
    });
} 

export const loginPasswordRequest = (callBack, postData) => {  
    axios.post(LOGIN_PAGE_SIGNIN_API, postData)
    .then(response => {
        callBack(response.data);
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("CLEARING USER PASSWORD REQUEST");
    });
} 

export const forgotPassword = (callBack, postData) => {
    axios.post(FORGOT_PASSWORD_API, postData)
    .then(response => {
        console.log(response);
        callBack(response.data);
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("CLEARING USER PASSWORD FORGOT REQUEST");
    });
}

export const nidaRegistrationApi = (callBack, postData) => {
    console.log(NIDA_REGIS_API + "?NIN=" + postData.identityNumber);
    axios.post(NIDA_REGIS_API + "?NIN=" + postData.identityNumber)
    .then(response => {
        console.log(response);
        callBack(response.data);
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("CLEARING USER PASSWORD FORGOT REQUEST");
    });
}

export const nidaQuestionAndAnswerApi = (callBack, postData, formActions) => {
    console.log(NIDA_QNA + "?NIN=" + postData.identityNumber + "&RQCode=" + postData.rqCode +"&QNANSW=" + postData.qnAnsw);
    axios.post(NIDA_QNA + "?NIN=" + postData.identityNumber + "&RQCode=" + postData.rqCode +"&QNANSW=" + postData.qnAnsw)
    .then(response => {
        console.log(response);
        callBack(response.data, formActions);
    })
    .catch(error => {
        console.log(error.message);
    })
    .then(() => {
        console.log("CLEARING USER PASSWORD FORGOT REQUEST");
    });
}