import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import ReactFlagsSelect from "react-flags-select";
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from "react-intl";
import MDTypography from "components/MDTypography";


import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  changeUserLanguage,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {

  const [navbarType, setNavbarType]                                                 = useState();
  const [controller, dispatch]                                                      = useMaterialUIController();
  const { miniSidenav, transparentNavbar, 
    fixedNavbar, darkMode, lang, showChangeMenu, }                                  = controller;
  const [openMenu, setOpenMenu]                                                     = useState(false);
  const route                                                                       = useLocation().pathname.split("/").slice(1);
  const [logout, setSetlogout]                                                      = useState(false);  
  const handleMiniSidenav                                                           = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen                                                   = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu                                                              = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu                                                             = () => setOpenMenu(false);
  const navigate                                                                    = useNavigate();
  // const [selected, setSelected]                                                  = useState("");
  const OPTION_LABEL                                                                = (lang === "en") ? "Language" : "Lugha";
  const intl                                                                        = useIntl();
  const log_out                                                                     = intl.formatMessage({id: 'log_out'});
  const change_password                                                             = intl.formatMessage({id: 'change_password'});

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
  }, [dispatch, fixedNavbar]);

  const languageSelected = languageCode => {
    console.log(languageCode);
    if(languageCode === "GB"){
      changeUserLanguage(dispatch, "en");
    } else {
      changeUserLanguage(dispatch, "sw");
    }
  }
  const renderMenu                                                                  = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
       open={Boolean(openMenu)}
       onClose={handleCloseMenu}
    > 
  
      <NotificationItem 
        icon={<Icon>podcasts</Icon>} 
        title={change_password}
        onClick={() => {
          navigate("/password/change-password");
        }}
      />
       
    <div>
     <NotificationItem 
        icon={<Icon>logout</Icon>}
        title={log_out} 
        onClick={() => {
          sessionStorage.removeItem("usn")
          setSetlogout(true);
          navigate("/");
        }} 
      />
     </div>
    
    </Menu>
    
  );
  
  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={theme => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
         <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="lg" >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        
         {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <ReactFlagsSelect
                selected={(lang === "en") ? "GB" : "TZ"}
                onSelect={languageCode => languageSelected(languageCode)}
                searchPlaceholder={OPTION_LABEL}
                countries={["GB", "TZ"]}
                customLabels={{ GB: "ENGLISH", TZ: "SWAHILI" }}
                placeholder={OPTION_LABEL}
                showSelectedLabel={true}
                showOptionLabel={true}
              />
          
            <MDBox color={light ? "warning" : "inherit"}>
              {showChangeMenu && (
                <IconButton
                  size="lg"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>)  
              }
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};


DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
