import ClockLoader from "react-spinners/ClockLoader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "ezimamoto/common/FormField";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { useIntl } from 'react-intl';

const PasswordResetCover = ({ formData }) => {
  const [controller]                                    = useMaterialUIController();
  const { loadingClock }                                = controller;
  const { formField, values, errors, touched }          = formData;
  const { email }                                       = formField;
  const { email: emailValue, }                          = values;
  const  navigate                                       = useNavigate();
  const intl                                            = useIntl();
  const eml_lbl                                         = intl.formatMessage({id: 'eml_lbl'});
  const bck_btn                                         = intl.formatMessage({id: 'bck_btn'});
  const rst_btn                                         = intl.formatMessage({id: 'rst_btn'});
  

  

  return (
          <>
            {!loadingClock && (
              <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormField
                    type={email.type}
                    label={eml_lbl}
                    name={email.name}
                    value={emailValue}
                    placeholder={email.placeholder}
                    error={errors.email && touched.email}
                    success={emailValue.length > 0 && !errors.email}
                    fullWidth 
                  />
                </Grid>
              </Grid>
              <MDBox pb={5}/>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MDButton variant="gradient" type="button" fullWidth color="info"
                    onClick={evt => navigate("/")}
                    sx={{ textTransform: "none" }}
                  >
                    {bck_btn}
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton variant="gradient" type="submit" color="info" fullWidth>
                    {rst_btn}
                  </MDButton>
                </Grid>
              </Grid>
              </>)}
            
               {loadingClock && (
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={4} />
                    <Grid item xs={4} md={4}>
                      <ClockLoader loading={loadingClock} speedMultiplier={3} size={90}  color="#36d7b7"/>
                    </Grid>
                    <Grid item xs={4} md={4} />
                  </Grid>
                )}
          </>
  );
}

PasswordResetCover.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PasswordResetCover;
