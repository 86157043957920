import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import themeRTL from "assets/theme/theme-rtl";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import routesSw from "routes_sw";
import { useMaterialUIController, setMiniSidenav } from "context";
import brandWhite from "assets/images/nem_fire.png";
import brandDark from "assets/images/nem_fire.png";
import { IntlProvider } from "react-intl";
import  LOCALES  from "locales";
import  messages  from "messages";
import { FormattedMessage } from "react-intl";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode, lang, } = controller;
  const [onMouseEnter, setOnMouseEnter]                                                                     = useState(false);
  const [rtlCache, setRtlCache]                                                                             = useState(null);
  const { pathname }                                                                                        = useLocation();

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };


  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = allRoutes =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && (route.customKey === undefined)) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else if(route.route && (route.customKey !== undefined)) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const getUserSession = () => {
      let session = sessionStorage.getItem("usn");
      if((session === null) || (session === "")){
          return false;
      } else {
          let user = JSON.parse(session);
          if((typeof user === 'object') && (user !== null)){
             return true;
          } else {
              return false;
          }
      }
  }

  const renderSdnav = () => {
    if(getUserSession()){
      return (
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName= {<FormattedMessage id="eZimamoto" />}
              routes={(lang === "en") ? routes : routesSw}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes((lang === "en") ? routes : routesSw)}
          <Route path="*" element={<Navigate to="/applications/new-application" />} />
        </Routes>
      </ThemeProvider>
      );
    } else {
      return (
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {/* 
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="e-zimamoto"
                routes={(lang === "en") ? routes : routesSw}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator /> 
            */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes((lang === "en") ? routes : routesSw)}
          <Route path="*" element={<Navigate to="/applications/new-application" />} />
        </Routes>
      </ThemeProvider>
      );
    }
  }

  return (direction === "rtl" && getUserSession()) ? (
    <IntlProvider messages={(lang === "en") ? messages[LOCALES.ENGLISH] : messages[LOCALES.SWAHILI]} locale='en' defaultLocale='en'>
      <CacheProvider value={rtlCache}>
        {renderSdnav()}
      </CacheProvider>
    </IntlProvider>
  ) : (
    <IntlProvider messages={(lang === "en") ? messages[LOCALES.ENGLISH] : messages[LOCALES.SWAHILI]} locale='en' defaultLocale='en'>
      {renderSdnav()}
    </IntlProvider>
  );
}
