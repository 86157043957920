import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
//import ComplexStatistics from "ezimamoto/common/components/complex-card";
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import  { Navigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { getDashboard_API } from "apis";
import { useMaterialUIController } from "context";
import React, {useEffect,useState} from "react";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

function Analytics() {
  
  const [controller, dispatch]                          = useMaterialUIController();
    // const [previewInfo, setPreviewInfo]                   = useState({});
    const [data, setdata]                               = useState("");
   
   
 
    const { lang }                                        = controller;

    const intl                                            = useIntl();

  useEffect(() => {
    let session = sessionStorage.getItem("usn");
    if((session !== null) || (session !== "")) {
        let user = JSON.parse(session);
        let token = user.sessionToken;
        const postData = { "userId": user.userId };
        getDashboard_API(callBack, postData, token);
    }

    //setShowChangeMenuAction(dispatch, false);
}, [lang]);

const callBack = data => {
        console.log(data);
        setdata(data);
    }
   

    const per_y                                                                                = intl.formatMessage({id: 'per_y'});
    const adr                                                                                  = intl.formatMessage({id: 'adr'});
    const aop                                                                                  = intl.formatMessage({id: 'aop'});
    const pa                                                                                   = intl.formatMessage({id: 'pa'});
    const ca                                                                                   = intl.formatMessage({id: 'ca'});
    //const tca_five                                                                             = intl.formatMessage({id: 'tca_five'});
    const frf_y                                                                                = intl.formatMessage({id: 'frf_y'});

  const getUserSession = () => {
    let session = sessionStorage.getItem("usn");
    console.log(session);
    if((session === null) || (session === "")){
        console.log("no session data");
        return <Navigate to='/'  />
    } else {
        let user = JSON.parse(session);
        if((typeof user === 'object') && (user !== null)){
           return (        
            <>
                <DashboardNavbar />
      <MDBox py={4}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3} mt={-2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard 
                
                  color="warning"
                  title={pa}
                  count=""
                  percentage={{
                    label2:data.pendingApplications,
                    color: "secondary",
                    label: per_y,
                    amount: ""
                  }}
                  icon="weekend"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3} mt={-2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  title={aop}
                  count=""
                  percentage={{
                    label2:data.applicationsOnProgress,
                    color: "secondary",
                    label: per_y,
                    amount: ""
                  }}
                  icon="leaderboard"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3} mt={-2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  title={ca}
                  count=""
                  percentage={{
                    label2:data.completedApplications,
                    color: "secondary",
                    label: per_y,
                    amount: ""
                  }}
                  icon="store"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3} mt={-2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  title={adr}
                  count=""
                  percentage={{
                    color: "secondary", 
                    label2:data.applicationsDueForRenewal,
                    amount:"" ,
                    label: per_y,
                  }}
                  icon="person_add"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pb={1} py={1}/>
            <Grid item xs={12} md={6} lg={4} mb={-9} mt={5} >
              <MDBox mb={-9}>
                <ReportsBarChart
                  color="dark"
                  title=""
                  date={new Date().toISOString()}
                  chart={reportsBarChartData}
                  description=""
                />
              </MDBox>
            </Grid>
      </MDBox>
      <Footer company={{name : frf_y}} />
            </>);
        } else {
            return <Navigate to='/'  />
        }
    }
}


  return (
    <DashboardLayout>
      {getUserSession()}
    </DashboardLayout>
  );
}

export default Analytics;
