import checkout from "ezimamoto/customer-registration/new-registration-rule/form/schemas/form";

const {
  formField: {
    identityNumber,
    qAnswer,
    fname,
    mname,
    lname,
    sex1,                                            
    dob,                                   
    bregion,                                        
    bdistrict,                                          
    bward,                                      
    rregion,                                        
    rdstr,                                      
    rward,                                      
    rstrt,
    title,
    phonenumber,
    email,
    password,
    confirmpassword,
  },
} = checkout;

const initialValues = {
  [identityNumber.name]        :  "",
  [qAnswer.name]               :  "",
  [fname.name]                 :  "",
  [mname.name]                 :  "",
  [lname.name]                 :  "",
  [sex1.name]                  :  "",                                            
  [dob.name]                   :  "",                                   
  [bregion.name]               :  "",                                        
  [bdistrict.name]             :  "",                                          
  [bward.name]                 :  "",                                      
  [rregion.name]               :  "",                                        
  [rdstr.name]                 :  "",                                      
  [rward.name]                 :  "",                                      
  [rstrt.name]                 :  "",
  [title.name]                 :  "",
  [phonenumber.name]           :  "",
  [email.name]                 :  "",
  [password.name]              :  "",
  [confirmpassword.name]       :  "",                              
};

export default initialValues;
