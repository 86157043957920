import axios from "axios";

const myAxios = (url, token) => axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*'  
    }
});

export default myAxios;