import checkout from "ezimamoto/application/renew-application/renew/form/schemas/form";

const {
  formField: {
    customerName,
    postalAddress,
    phoneNumber,
    bussinessName,
    bussinessPhone,
    block,
    plot,
    houseNumber,
    region,
    district,
    admin_area,
    ward,
    street_village,
    propertyCategory1,
    subCategory1,
    numOfItems,
    subCategoryDetail,
    customerEmail,
    bussnEmail,
    bussnAddr,
    feedbackCorrespondent,
    applicantCapacity,
  },
} = checkout;

const initialValues = {
  [customerName.name]            : "",
  [postalAddress.name]           : "",
  [phoneNumber.name]             : "",
  [bussinessName.name]           : "",
  [bussinessPhone.name]          : "",
  [block.name]                   : "",
  [plot.name]                    : "",
  [houseNumber.name]             : "",
  [region.name]                  : "",
  [district.name]                : "",
  [admin_area.name]              : "",
  [ward.name]                    : "",
  [street_village.name]          : "",
  [propertyCategory1.name]       : "",
  [subCategory1.name]            : "",
  [numOfItems.name]              : "",
  [subCategoryDetail.name]       : "",
  [customerEmail.name]           : "",
  [bussnEmail.name]              : "",
  [bussnAddr.name]               : "",
  [feedbackCorrespondent.name]   : "",
  [applicantCapacity.name]       : "",
};

export default initialValues;
