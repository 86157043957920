const form = {
  formId: "appl-status-form",
  formField: {
    applicationId: {
      name: "applicationId",
      label: "Application Id",
      type: "text",
      errorMsg: "Either Control Number / Application Number is required.",
      errorMsgSw: "Inahitaji Namba ya malipo au Namba ya Ombi.",
    },
    controlNumber: {
      name: "controlNumber",
      label: "Control Number",
      type: "text",
      errorMsg: "Either Control Number / Application Number is required.",
      errorMsgSw: "Inahitaji Namba ya malipo au Namba ya Ombi.",
    },
  },
};

export default form;
