const form = {
    formId: "change-password-form",
     formField: {
      oldpassword  : {
        name        : "oldpassword",
        label       : "Old-password",
        type        : "password",
        errorMsg    : "Old Password is required.",
        errorMsgSw  : "Nywila ya zamani inahitajika.",
      },
      newpassword  : {
        name         : "newpassword",
        label        : "New-password",
        type         : "password",
        errorMsg     : "New Password is required.",
        errorMsgSw   : "Nywila Mpya Inahitajika.",
        invalidMsg   : "New Password Must Match With Confirmation Password",
        invalidMsgSw : "Nywila Mpya lazima Ifanane na nywila ya Uthibitisho",
        weakMsg       : "Weak Password",     
        weakMsgSw     : "Nywila Hafifu", 
      },
      confirmpassword  : {
        name          : "confirmpassword",
        label         : "Confirm Password",
        type          : "password",
        errorMsg      : "Confirm-Password is required.",
        errorMsgSw    : "Nywila Uthibitisho Inahitajika.",
        weakMsg       : "Weak Password",     
        weakMsgSw     : "Nywila Hafifu", 
      },
    },
  };
  
  export default form;
  