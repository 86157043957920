import React from 'react';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { retrieveApplicationRenewal } from "apis";
import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import MaterialTable from 'material-table';
import { TablePagination } from '@mui/material';
import  { Navigate } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { useMaterialUIController, setTableLoadingProperty, setShowRenewalPage, setRenewObject, renewRegistrationSaveData } from "context";
import { fetchSpecificExistingApplication } from "apis";
import MDButton from "components/MDButton";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import RenewPage from "ezimamoto/application/renew-application/renew";

const RenewApplication = props => {
    const [columns, setColumns]                           = useState([]);
    const [rows, setRows]                                 = useState([]);
    const [controller, dispatch]                          = useMaterialUIController();
    const { lang, tableLoading, showRenewalPage, renewobject }         = controller;

    const callBack = data => {
        console.log(data);
        if((data.result !== null) || (data.result !== undefined)){
            let columnsDef = null;
            if(lang === "sw"){
                columnsDef = [
                    { title: "UT KAGUZ", field: "selfInspectionApplicationId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10 }},
                    { title: "UT OMBI", field: "applicantId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "Na./OMBI", field: "applicationNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "JIN / MTJ", field: "customerName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "JIN / BSHR", field: "businessName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "ANwN/P", field: "postalAddress", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "NA. MALIPO", field: "controlNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "KIPNG/MILIKI", field: "propertyCategory", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "KIAS/TOZWA", field: "billedAmount", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "TAR/KAGUZ", field: "inspectedDate", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                ];
            } else {
                columnsDef = [
                    { title: "INS ID", field: "selfInspectionApplicationId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10 }},
                    { title: "APP ID", field: "applicantId", hidden: true, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "APPL NUM", field: "applicationNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "CUST NAME", field: "customerName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "BUSS NAME", field: "businessName", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "P. ADDRESS", field: "postalAddress", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "CNTL NUM", field: "controlNumber", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "PROP CATEGORY", field: "propertyCategory", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "BILLD AMT", field: "billedAmount", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                    { title: "INSP DT", field: "inspectedDate", hidden: false, align: 'left', emptyValue: "-", headerStyle: { textTransform: 'none', fontSize: 10  }},
                ];
            }
            setColumns(columnsDef);
            setRows(data.result);
        } else {
            setColumns([]);
            setRows([]);
        }
    }

    const callBackApi = data => {
        if(!data.error && (data.message === "Succesfully Retrieved") && (data.status === "OK")){
            setTableLoadingProperty(dispatch, false);
            setShowRenewalPage(dispatch, true);
            setRenewObject(dispatch, data.result);
            let smallPieceData = {};
            smallPieceData.identityNumber = data.result.identityNumber;
            smallPieceData.identityType   = data.result.identityType;
            smallPieceData.customerType   = data.result.customerType;
            smallPieceData.applicationId  = data.result.selfInspectionApplicationId;
            console.log(smallPieceData);
            renewRegistrationSaveData(dispatch, smallPieceData);
        } else {
            setTableLoadingProperty(dispatch, true);
            setShowRenewalPage(dispatch, false);
        }
    }

    const fetchRenewApplicationApi = (event, data) => {
        let session        = sessionStorage.getItem("usn");
        let user           = JSON.parse(session);
        let token          = user.sessionToken;
        setTableLoadingProperty(dispatch, true);
        const postData = { "applicationId": data.applicationId };
        fetchSpecificExistingApplication(callBackApi, postData, token);
    }

    useEffect(() => {
        let session = sessionStorage.getItem("usn");
        if((session !== null) || (session !== "")) {
            let user = JSON.parse(session);
            let token          = user.sessionToken;
            const postData = {
                "userId": user.userId
            };
           retrieveApplicationRenewal(callBack, postData, token);
        }
        //setShowChangeMenuAction(dispatch, false);
    }, [lang]);

    const getUserSession = () => {
        let session = sessionStorage.getItem("usn");
        if((session === null) || (session === "")){
            return <Navigate to='/'  />
        } else {
            let user = JSON.parse(session);
            if((typeof user === 'object') && (user !== null)){
               return (        
                <>
                    <DashboardNavbar />
                        <MDBox py={3} mb={6}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={12} lg={12}>  
                                    {!showRenewalPage && (
                                        <MaterialTable
                                            title={<FormattedMessage id="rnw_appl" />}
                                            columns={columns}
                                            data={rows}
                                            localization={{ 
                                                header: {
                                                    actions: "-"
                                                }
                                            }}
                                            style={{ 
                                                textTransform: 'none'
                                            }}
                                            options={{ 
                                                actionsColumnIndex: -1,
                                                doubleHorizontalScroll: true,
                                                loadingType: 'overlay',
                                                headerStyle: {
                                                    backgroundColor: 'orange',
                                                    whiteSpace: "nowrap",
                                                    color: 'white',
                                                },
                                                padding: 'dense',
                                                paginationType: 'stepped',
                                                searchFieldVariant: 'outlined',
                                                rowStyle: (data, index, level) => {
                                                    if((index % 2) === 0){
                                                        return { backgroundColor: '#EEE',fontSize: 12};
                                                    } else {
                                                        return { backgroundColor: '#FFF', fontSize: 12};
                                                    }
                                                }
                                            }}
                                            actions={[
                                                {
                                                    icon: 'save',
                                                    tooltip: 'Save User',
                                                    onClick: (event, rowData) => fetchRenewApplicationApi(event, rowData)
                                                }
                                            ]}
                                            components={{
                                                Action: props => (
                                                    <MDButton 
                                                        variant="gradient" 
                                                        type="button" 
                                                        color="info" 
                                                        size="small"
                                                        onClick={event => props.action.onClick(event, props.data)}
                                                    >
                                                        <EventRepeatIcon />
                                                    </MDButton>
                                                ),
                                                Pagination: (props) => {
                                                const {
                                                    ActionsComponent,
                                                    onChangePage,
                                                    onChangeRowsPerPage,
                                                    ...tablePaginationProps
                                                } = props;
                                                return (
                                                    <>
                                                    <TablePagination
                                                        {...tablePaginationProps}
                                                        onPageChange={onChangePage}
                                                        onRowsPerPageChange={onChangeRowsPerPage}
                                                        ActionsComponent={(subprops) => {
                                                        const { onPageChange, ...actionsComponentProps } =
                                                            subprops;
                                                        return (
                                                            <ActionsComponent
                                                            {...actionsComponentProps}
                                                            onChangePage={onPageChange}
                                                            />
                                                        );
                                                        }}
                                                    />
                                                    </>
                                                );
                                                },
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </MDBox>
                    {showRenewalPage && (
                        <RenewPage />
                    )}
                </>);
            } else {
                return <Navigate to='/'  />
            }
        }
    }

    return (
        <DashboardLayout>
            {getUserSession()}
        </DashboardLayout>
    );
}

export default RenewApplication;