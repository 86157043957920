import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useMaterialUIController, setLayout } from "context";

function ApplicationLayout({ children }) {

    const [controller, dispatch]  = useMaterialUIController();
    const { miniSidenav }         = controller;
    const { pathname }            = useLocation();
  
    useEffect(() => {
      setLayout(dispatch, "dashboard");
    }, [pathname]);
  
    return (
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          
          position: "relative",
  
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
    );


}

ApplicationLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ApplicationLayout;